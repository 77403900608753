// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// #region Component Styles
const StyledImage = styled.img`
  max-width: 100%;
`;

const StyledPicture = styled.picture`
  height: 100%;
  width: 100%;
`;
// #endregion

const Image = ({ alt, children, className, sizes, src, srcSet }) =>
  children ? (
    <StyledPicture className={className}>
      {/* expected to be source tags */}
      {children}
      <StyledImage src={src} alt={alt} srcSet={srcSet} sizes={sizes} />
    </StyledPicture>
  ) : (
    <StyledImage
      className={className}
      src={src}
      alt={alt}
      srcSet={srcSet}
      sizes={sizes}
    />
  );

Image.displayName = "Image";

Image.propTypes = {
  /** An **optional** prop used to specify the alt (alternate) text for an image */
  alt: PropTypes.string.isRequired,
  /** An **optional** children prop - expected to be provided zero or more <source> nodes */
  children: PropTypes.node,
  /** An **optional** classname prop for the Image component */
  className: PropTypes.string,
  /** A **required** src prop used to specify the path to the image to render e.g.  `src="images/logo.jpeg"` */
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /** An **optional** srcSet prop used to define the set of images from which the browser can choose from with a correspodonding image size. Image information is comma separated and is defined as follows:
   *
   *  - Image filename
   *  - A space
   *  - An image's intrinsic width in pixels (480w) — note that this uses the w unit, not px. This refers to the image's real size.
   */
  srcSet: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /** An **optional** sizes prop used for responsive selection of images. Defines a set of media conditions (e.g. screen width) and indicates what image size would be best to choose, when certain media conditions are true.
   *  Composed of:
   *
   *  - A media condition ((max-width:600px)).
   *  - A space
   *  - The width of the slot the image will fill when the media condition is true (480px)
   *
   * For more information, see [Responsive Images](https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images)
   *
   */
  sizes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default Image;
