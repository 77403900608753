// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { assignCardConstants } from "./constants";

// #region Component Styles

const Container = styled.div.attrs({
  tabIndex: 0,
})`
  background-color: var(--dui-color-white-500);
  border-radius: var(--dui-size-radius-md);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 12px 0 rgba(0, 0, 0, 0.1),
    0 0 1px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  outline: none;

  &:hover {
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.1),
      0 4px 14px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  }

  &:focus {
    box-shadow: 0px 0px 0px 4px var(--dui-color-red-500);
    box-shadow: ${({ focusVariant }) =>
      focusVariant === "primary"
        ? `0px 0px 0px 4px var(--dui-color-red-500)`
        : `0px 0px 0px 4px var(--dui-color-black-500)`};
  }
`;

const StyledHeader = styled.header`
  padding-left: var(--dui-size-space-10x);
  padding-right: var(--dui-size-space-10x);
  padding-top: var(--dui-size-space-10x);
`;

const StyledImageOverlay = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
`;

const StyledBody = styled.div`
  flex-grow: 1;
  padding: var(--dui-size-space-10x);
  z-index: 1;
`;

const StyledFooter = styled.div`
  align-items: center;
  display: flex;
  padding-bottom: var(--dui-size-space-10x);
  padding-left: var(--dui-size-space-10x);
  padding-right: var(--dui-size-space-10x);

  ${StyledImageOverlay} + & {
    padding-top: var(--dui-size-space-10x);
  }
`;

// #endregion

const Header = ({ children, className }) => (
  <StyledHeader className={className}>{children}</StyledHeader>
);

const Body = ({ children, className }) => (
  <StyledBody className={className}>{children}</StyledBody>
);

const Footer = ({ children, className }) => (
  <StyledFooter className={className}>{children}</StyledFooter>
);

const ImageOverlay = ({ children, className }) => (
  <StyledImageOverlay className={className}>{children}</StyledImageOverlay>
);

const Card = ({
  children,
  className,
  ariaLabel,
  id,
  tag,
  focusVariant,
  ariaDescribedBy,
}) => (
  <Container
    className={className}
    aria-label={ariaLabel}
    id={id}
    as={tag}
    focusVariant={focusVariant}
    aria-describedby={ariaDescribedBy}
  >
    {children}
  </Container>
);

assignCardConstants(Card);

Card.propTypes = {
  /** An **optional** prop that holds the children of the Card */
  children: PropTypes.node.isRequired,
  /** An **optional** classname prop for the Card component */
  className: PropTypes.string,
  /** An **optional** prop defining the text read by the screen reader to represent the Card */
  ariaLabel: PropTypes.string,
  /** An **optional** id prop for the Card component  */
  id: PropTypes.string,
  /** An **optional** tag prop for the Card component - use this prop when grouping cards for accessibility & semantic html  */
  tag: PropTypes.oneOf([Card.DIV, Card.LI]),
  /** An **optional** focusVariant prop for the Card component */
  focusVariant: PropTypes.oneOf([Card.PRIMARY, Card.SECONDARY]),
  /** An **optional** prop defining the list of reference ids (separated by space), recommended when you want to show some error message on your field */
  ariaDescribedBy: PropTypes.string,
};

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
Body.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
ImageOverlay.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Card.Header = Header;
Card.Body = Body;
Card.Footer = Footer;
Card.ImageOverlay = ImageOverlay;

Card.defaultProps = {};

export default Card;
