// 9fbef606107a605d69c0edbcd8029e5d

import styled from "styled-components";
import { Link, IconWrapper } from "../../../atoms";

const NavLink = styled(Link)`
  color: var(--dui-color-black-500);
  cursor: pointer;
  text-decoration: none;
  &:hover {
    & > ${IconWrapper} {
      color: var(--dui-color-red-400);
    }
  }
  &:focus-visible {
    color: var(--dui-color-red-400);
    outline: 2px solid var(--dui-color-red-400);
    outline-offset: 2px;
  }
`;

export default NavLink;
