// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { IconGlobe } from "assets/icons";
import {
  WEIGHTS as TEXT_WEIGHTS,
  SIZES as TEXT_SIZES,
} from "components/atoms/Text/constants";
import { IconWrapper } from "../../atoms";
import { NavLink, ListItem } from "./shared";

const GlobeIcon = styled(IconGlobe)`
  margin-right: var(--dui-size-space-3x);
`;

const StyledIconWrapper = styled(IconWrapper)`
  height: 21px;
  width: 21px;
`;

const CountrySelector = ({ countryData }) => {
  return (
    <ListItem>
      <NavLink
        size={TEXT_SIZES.SM}
        href={countryData.href}
        weight={TEXT_WEIGHTS[400]}
      >
        <StyledIconWrapper>
          <GlobeIcon />
        </StyledIconWrapper>
        {countryData.copy}
      </NavLink>
    </ListItem>
  );
};

export default CountrySelector;

/** Data for the Country */
CountrySelector.propTypes = {
  countryData: PropTypes.shape({
    /** Country Redirection url */
    href: PropTypes.string,
    /** if link should open externally */
    external: PropTypes.bool,
    /** Country Title */
    title: PropTypes.string,
    /** Country Text */
    copy: PropTypes.string,
  }),
};
