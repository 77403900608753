// 9fbef606107a605d69c0edbcd8029e5d

import React, {
  Fragment,
  forwardRef,
  useRef,
  useState,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";
import Text from "components/atoms/Text";
import { concatIfSet } from "utils/arrayUtils";
import Label from "components/atoms/FormField/Label";
import Placeholder from "components/atoms/FormField/Placeholder";
import { SIZES as TEXT_SIZES } from "components/atoms/Text/constants";
import { IconCancelCircle, IconCheckmarkCircle } from "assets/icons";
import { ValidationMessage } from "../../ValidationFeedback/Message/Message";
import {
  LABEL_TYPES,
  VALIDATION_TYPES,
  assignTextAreaFieldConstants,
} from "./constants";

// #region Component Styles
const VALIDATION_ICON_OFFSET = "30px";

const APPER_FROM_SIDE = keyframes`
  from {
    transform: translateX(20px) rotateZ(0.5turn);
    opacity: 0;
  }

  to {
    transform: translateY(0) rotateZ(0);
    opacity: 1;
  }
`;

const Container = styled.div`
  display: ${({ isBlock }) => (isBlock ? "block" : "inline-block")};
  position: relative;
  width: ${({ isBlock }) => (isBlock ? "100%" : "200px")};
`;

const TextArea = styled.textarea.attrs({
  noValidate: true,
})`
  ${({ isShowingValidationIcon, hasLabel, value }) => {
    return css`
      border: solid 1px
        ${({ isInvalid, isValid }) => {
          if (isInvalid) {
            return `var(--dui-color-red-500)`;
          }
          if (isValid) {
            return `var(--dui-color-green-500)`;
          }
          return `var(--dui-color-gray-300)`;
        }};

      border-radius: var(--dui-size-radius-md);

      box-sizing: border-box;
      font-family: var(--dui-font-family);
      font-size: var(--dui-size-font-md);
      outline: 0;

      padding: ${hasLabel
        ? css`25px var(--dui-size-space-7x) 10px var(--dui-size-space-7x)`
        : css`17px var(--dui-size-space-7x) 18px var(--dui-size-space-7x)`};

      padding-right: ${isShowingValidationIcon
        ? VALIDATION_ICON_OFFSET
        : undefined};

      transition: border-color var(--dui-duration-3x);
      width: 100%;

      ${value &&
      css`
        ~ ${Label} {
          font-size: var(--dui-size-font-xs);
          opacity: 1;
          transform: translateY(0);
        }

        ~ ${Placeholder} {
          opacity: 0;
        }
      `};

      &:focus {
        border-color: transparent;
        box-shadow: 0 0 0 2px var(--dui-color-black-400);

        ~ ${Label} {
          font-size: var(--dui-size-font-xs);
          opacity: 1;
          transform: translateY(0);
          font-weight: 700;
        }

        ~ ${Placeholder} {
          font-size: var(--dui-size-font-xs);
          opacity: 0;
          transform: translateY(-7px);
        }
      }
    `;
  }}
`;

const ValidationMessageContainer = styled.div`
  margin-top: var(--dui-size-space-3x);
`;

const IconWrapperStyled = styled.span`
  animation: ${APPER_FROM_SIDE} var(--dui-duration-3x);
  display: inline-block;
  left: ${({ leftOffset }) => `${leftOffset}px`};
  position: absolute;
  top: 20px;
`;

export const IconWrapper = ({ offsetElement, ...props }) => {
  const offset = 15;
  const [leftOffset, setLeftOffset] = useState(0);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(([{ contentRect: { width } }]) =>
      setLeftOffset(width + offset)
    );
    resizeObserver.observe(offsetElement.current);

    return () => resizeObserver.disconnect();
  }, [offsetElement]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <IconWrapperStyled {...props} leftOffset={leftOffset} />;
};

IconWrapper.propTypes = {
  offsetElement: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};
const InvalidIcon = styled(IconCancelCircle).attrs({
  "aria-hidden": true,
  role: "presentation",
})`
  color: var(--dui-color-red-500);
  height: 16px;
  width: 16px;
`;

const ValidIcon = styled(IconCheckmarkCircle).attrs({
  "aria-hidden": true,
  role: "presentation",
})`
  color: var(--dui-color-green-400);
  height: 16px;
  width: 16px;
`;

const getValidationMessageVariant = (type) => {
  switch (type) {
    case VALIDATION_TYPES.NOTE:
      return VALIDATION_TYPES.NOTE;

    case VALIDATION_TYPES.VALID:
      return VALIDATION_TYPES.VALID;

    case VALIDATION_TYPES.INVALID:
    default:
      return VALIDATION_TYPES.INVALID;
  }
};

export const Hint = styled.div`
  color: var(--dui-color-gray-600);
  font-family: var(--dui-font-family);
  font-size: var(--dui-size-font-xs);
  margin-top: var(--dui-size-space-3x);
`;
// #endregion

const getLabelForVariant = (variant) => {
  if (variant.type === LABEL_TYPES.STATIC) {
    return variant.label;
  }

  return variant.label || variant.placeholder;
};

const TextAreaField = forwardRef(
  (
    {
      ariaDescribedBy,
      className,
      id,
      /**
       * Code smell:
       *
       * The default export for `TextAreaField` already supports the `ref` attribute and the `forwardedRef`
       * is just an implementation detail that the consumer doesn't need to know as she can use the
       * standard `ref`. Unfortunately I had to export the `default` and also use a `named` export because
       * by default `storybook` is having problems to display the `propTypes` table of a `React.forwardedRef` component.
       *
       * To make it clear I called the `named` export as `StorybookOnlyTextAreaField` as it's only used by `storybook`.
       * */
      hint,
      isBlock,
      onBlur,
      onChange,
      rows,
      validation,
      value,
      variant,
      ariaLabel,
      required,
      name,
      dataTestid,
      disabled,
    },
    ref
  ) => {
    const textareaRef = useRef();
    const [textAreaRef, setTextAreaRef] = useState(ref || textareaRef);
    useEffect(() => {
      setTextAreaRef(ref || textareaRef);
    }, [ref]);

    const { type: variantType, placeholder } = variant;
    const label = getLabelForVariant(variant);
    const hasLabel = Boolean(label);
    const isAnimated = variantType === LABEL_TYPES.ANIMATED;

    const isInvalid = validation?.type === VALIDATION_TYPES.INVALID;
    const isValid = validation?.type === VALIDATION_TYPES.VALID;

    const validationMessageId = `${id}_validationMessage`;
    const combinedAriaDescribedBy = concatIfSet([
      ariaDescribedBy,
      validation && validationMessageId,
    ]);

    return (
      <Container isBlock={isBlock}>
        <TextArea
          aria-describedby={combinedAriaDescribedBy}
          aria-label={ariaLabel}
          id={id}
          className={className}
          hasLabel={hasLabel}
          isBlock={isBlock}
          isInvalid={isInvalid}
          isShowingValidationIcon={isValid || isInvalid}
          isValid={isValid}
          onBlur={onBlur}
          onChange={onChange}
          rows={rows}
          placeholder={
            /*
             * Only show default's `input` placeholder in the `STATIC` variant. This is basically
             * because in the `ANIMATED` variant we are actually manually adding a `<span />` to
             * the DOM that acts like a `placeholder` so we can animate it.
             */
            variantType === LABEL_TYPES.STATIC ? placeholder : undefined
          }
          ref={textAreaRef}
          value={value}
          required={required}
          disabled={disabled}
          name={name}
          data-testid={dataTestid}
        />

        <Label
          isInvalid={isInvalid}
          isRequired={required}
          shouldHide={isAnimated && Boolean(placeholder)}
          variantType={variantType}
          htmlFor={id}
        >
          {label}
        </Label>

        {isAnimated && placeholder && (
          <Placeholder
            hasRightIcon={isValid || isInvalid}
            isRequired={required}
            aria-hidden
          >
            {placeholder}
          </Placeholder>
        )}

        {hint && (
          <Hint>
            <Text size={TEXT_SIZES.XS}>{hint}</Text>
          </Hint>
        )}
        {validation && (
          <Fragment>
            {validation.message && (
              <ValidationMessageContainer>
                <ValidationMessage
                  id={validationMessageId}
                  message={validation.message}
                  variant={getValidationMessageVariant(validation.type)}
                />
              </ValidationMessageContainer>
            )}
            <IconWrapper
              id={`${id}-validation-icon-${validation.type}`}
              offsetElement={textAreaRef}
            >
              {isInvalid && <InvalidIcon />}
              {isValid && <ValidIcon />}
            </IconWrapper>
          </Fragment>
        )}
      </Container>
    );
  }
);

assignTextAreaFieldConstants(TextAreaField);

TextAreaField.propTypes = {
  /** list of reference ids (separated by space), recommended when you want to show some error message on your field */
  ariaDescribedBy: PropTypes.string,
  /** An **optional** classname prop for the TextAreaField component */
  className: PropTypes.string,
  /** An **optional** hint prop for used to display a hint below the TextAreaField */
  hint: PropTypes.string,
  /** An **optional** prop flag to control if the TextAreaField is rendered as block or inline-block element */
  isBlock: PropTypes.bool,
  /** An **optional** onBlur callback handler */
  onBlur: PropTypes.func,
  /** An **optional** onChange callback handler */
  onChange: PropTypes.func,
  /** An **optional** prop describing the number of text rows to be made available by the TextAreaField */
  rows: PropTypes.number,
  /** An **optional** TextAreaField validation prop
   *
   * **Required Fields** `type`
   */
  validation: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.oneOf(
      Object.values([
        TextAreaField.VALID,
        TextAreaField.INVALID,
        TextAreaField.NOTE,
      ])
    ).isRequired,
  }),
  /** An **optional** prop defining the value of the TextAreaField which is taken when a form is submitted */
  value: PropTypes.string,
  /** An **optional** TextAreaField variant prop - follows the same structure as InputField
   *
   * **Required Fields** `type`
   */
  variant: PropTypes.shape({
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf([TextAreaField.ANIMATED, TextAreaField.STATIC])
      .isRequired,
  }),
  /** An **optional** prop defining the text read by the screen reader to represent the TextAreaField; use this if you need different text to be read from label */
  ariaLabel: PropTypes.string,
  /** A **required** id prop for the TextAreaField component  */
  id: PropTypes.string.isRequired,
  /** An **optional** prop to flag the TextAreaField component as required within a form context */
  required: PropTypes.bool,
  /** An **optional** prop flag to define if the Dropdown is disabled */
  disabled: PropTypes.bool,
  /** An **optional** name prop for the TextAreaField component used to reference form data within a form element */
  name: PropTypes.string,
  /** An **optional** test-id prop used to target the TextAreaField component for testing */
  dataTestid: PropTypes.string,
};

TextAreaField.defaultProps = {
  isBlock: false,
  onBlur: Function.prototype,
  onChange: Function.prototype,
  rows: 5,
};

TextAreaField.displayName = "TextAreaField";

export const StorybookOnlyTextAreaField = TextAreaField;

export default TextAreaField;
