import PropTypes from "prop-types";

export const ResponsiveObjectPropType = (OBJECT) =>
  PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(OBJECT)),
    PropTypes.arrayOf(PropTypes.oneOf(Object.values(OBJECT))),
  ]);

export const ResponsiveArrayOfValuesPropType = (arrayOfAllowedValues) =>
  PropTypes.oneOfType([
    PropTypes.oneOf(arrayOfAllowedValues),
    PropTypes.arrayOf(PropTypes.oneOf(arrayOfAllowedValues)),
  ]);

export const ResponsiveSizePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
]);

export const ResponsiveStringPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
]);
