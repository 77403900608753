// 9fbef606107a605d69c0edbcd8029e5d

export const TRIGGERS = {
  CLICK: "click",
  HOVER: "hover",
};

export const PLACEMENTS = {
  AUTO: "auto",
  AUTO_START: "auto-start",
  AUTO_END: "auto-end",
  TOP: "top",
  TOP_START: "top-start",
  TOP_END: "top-end",
  BOTTOM: "bottom",
  BOTTOM_START: "bottom-start",
  BOTTOM_END: "bottom-end",
  RIGHT: "right",
  RIGHT_START: "right-start",
  RIGHT_END: "right-end",
  LEFT: "left",
  LEFT_START: "left-start",
  LEFT_END: "left-end",
};

/* eslint-disable no-param-reassign */
export function assignControlsConstants(Component) {
  Component.prototype = Object.assign(Component, {
    ...TRIGGERS,
    ...PLACEMENTS,
  });
}
