// 9fbef606107a605d69c0edbcd8029e5d

/**
 * This file contains the shared styles between InputField and InputGroup
 */

import styled, { css } from "styled-components";
import { IconWrapper } from "../../IconWrapper";

export const inputFieldBorderStyles = css`
  border: solid 1px
    ${({ isInvalid, isValid }) => {
      if (isInvalid) {
        return css`var(--dui-color-red-500)`;
      }
      if (isValid) {
        return css`var(--dui-color-green-400)`;
      }
      return css`var(--dui-color-gray-500)`;
    }};
  border-radius: var(--dui-size-radius-md);
  box-sizing: border-box;
  transition: border-color var(--dui-duration-3x);
`;

export const StyledIconWrapper = styled(IconWrapper)`
  height: 1.25rem;
  position: absolute;
  top: var(--dui-size-space-8x);
  width: 1.25rem;
  ${({ position }) =>
    position === "left"
      ? css`
          left: var(--dui-size-space-6x);
        `
      : css`
          right: var(--dui-size-space-6x);
        `}
`;

export const MessageIconWrapper = styled(IconWrapper)`
  margin-right: var(--dui-size-space-2x);
`;

export const ValidationMessageContainer = styled.div`
  ${({ hasValidationMsg }) => css`
    align-items: center;
    display: ${hasValidationMsg ? "flex" : "none"};
    margin-top: var(--dui-size-space-4x);
  `}
`;

export const disabledStyles = {
  container: css`
    cursor: not-allowed;
    pointer-events: none;
  `,
  items: css`
    -webkit-text-fill-color: var(--dui-color-gray-600);
    color: var(--dui-color-gray-600);
  `,
};
