// 9fbef606107a605d69c0edbcd8029e5d

import styled from "styled-components";

const ListItem = styled.li`
  box-sizing: border-box;
  display: flex;
  /* This class is being applied in useCollapse hook to hide the lineItems out of the viewport */
  &.is-nextline {
    opacity: 0;
    visibility: hidden;
  }
  margin-bottom: 7px;
  margin-left: var(--dui-size-space-10x);
  margin-top: var(--dui-size-space-3x);
`;

export default ListItem;
