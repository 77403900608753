// 9fbef606107a605d69c0edbcd8029e5d

import React, { Fragment } from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import styled, { createGlobalStyle } from "styled-components";
import { IconCancel } from "assets/icons";
import { IconWrapper } from "components/atoms";

// Design Tokens
const RED300 = "#F91320"; // no css variable available

// #region Component Styles
const LoadCss = createGlobalStyle`
  .PL-Modal__Content {
    background-color: var(--dui-color-white-500);
    box-shadow: 0 3px 8px -1px rgba(0,0,0,0.1), 0 10px 24px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.1);
    outline: solid var(--dui-size-space-1x) transparent;
    padding: var(--dui-size-space-12x);
    position: relative;
    transform: translateY(-25px);
    transition: outline-color var(--dui-duration-3x), transform var(--dui-duration-3x);

    &:focus {
    outline: solid var(--dui-size-space-1x) black;
    }

    /* Order matters here, the after-open modifier needs to come before before-close, I know, that sucks... */
    &--after-open {
      transform: translateY(0);
    }

    &--before-close {
      transform: translateY(-25px);
    }
  }

  .PL-Modal__Overlay {
    align-items: center;
    background-color: var(--dui-color-black-400);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity var(--dui-duration-3x);
    z-index: 99999;

    /* Order matters here, the after-open modifier needs to come before before-close, I know, that sucks... */
    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  color: var(--dui-color-black-400);
  cursor: pointer;
  height: auto;
  margin: 0;
  outline: 0;
  padding: 0;
  position: absolute;
  right: var(--dui-size-space-6x);
  top: var(--dui-size-space-6x);

  &:hover,
  &:focus {
    ${IconWrapper} {
      box-shadow: 0px 0px 0px 2px #000000;
      transition: box-shadow var(--dui-duration-3x),
        color var(--dui-duration-3x);
    }
  }

  &:hover {
    color: ${RED300};
  }
`;

// #endregion

const Modal = ({
  aria,
  children,
  className,
  closeButtonAriaLabel,
  contentAriaLabel,
  isOpen,
  isStatic,
  onClose,
  overlayColor,
  shouldShowCloseButton,
}) => (
  <Fragment>
    <LoadCss />

    <ReactModal
      aria={aria}
      ariaHideApp={false}
      className={{
        afterOpen: "PL-Modal__Content--after-open",
        base: ["PL-Modal__Content", className].filter(Boolean).join(" "),
        beforeClose: "PL-Modal__Content--before-close",
      }}
      closeTimeoutMS={250}
      contentLabel={contentAriaLabel}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={{
        afterOpen: "PL-Modal__Overlay--after-open",
        base: "PL-Modal__Overlay",
        beforeClose: "PL-Modal__Overlay--before-close",
      }}
      preventScroll
      shouldCloseOnEsc={!isStatic}
      shouldCloseOnOverlayClick={!isStatic}
      style={{ overlay: { backgroundColor: overlayColor } }}
    >
      {shouldShowCloseButton && (
        <CloseButton aria-label={closeButtonAriaLabel} onClick={onClose}>
          <IconWrapper size={IconWrapper.MD}>
            <IconCancel />
          </IconWrapper>
        </CloseButton>
      )}

      {children}
    </ReactModal>
  </Fragment>
);

Modal.propTypes = {
  /**
   * An **optional**  aria tags object that can be added to further enhance the modals description and content, find more info here:
   * https://reactcommunity.org/react-modal/accessibility/
   */
  aria: PropTypes.shape({
    labelledby: PropTypes.string,
    describedby: PropTypes.string,
  }),
  /** A **required** prop that holds the children of the Modal */
  children: PropTypes.node.isRequired,
  /** An **optional** className prop for the Modal component */
  className: PropTypes.string,
  /**
   * An **optional** prop used to supply the aria label for the close button used by the Modal. */
  closeButtonAriaLabel: PropTypes.string,
  /** An **optional** prop used to supply the aria label for the Modal content. */
  contentAriaLabel: PropTypes.string,
  /** An **optional** boolean prop used to toggle the visibility of the Modal */
  isOpen: PropTypes.bool,
  /**
   * An **optional** prop used to prevent Modal closing by the `esc` key or through an `overlay` click. If set, Modal
   * can only be closed via the inner close icon or by a custom implementation.
   */
  isStatic: PropTypes.bool,
  /** A **required** onClose event handler */
  onClose: PropTypes.func.isRequired,
  /** An **optional** prop used to set the css background color of the Modal overlay */
  overlayColor: PropTypes.string,
  /**
   * An **optional** boolean prop used to toggle the visibility of the close button. The Modal retains ability to be closed
   * through `esc` key, or overlay click (unless `isStatic` is set to true)
   */
  shouldShowCloseButton: PropTypes.bool,
};

Modal.defaultProps = {
  isOpen: false,
  isStatic: false,
  shouldShowCloseButton: true,
};

export default Modal;
