// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import SecondaryNavigation from "./SecondaryNavigation";
import PrimaryNavigation from "./PrimaryNavigation";

const Navbar = ({
  primaryMetaItems,
  primaryNavigationItems,
  mobileNavigationItems,
  secondaryNavigationItems,
  countryData,
  secondaryHeadline,
  languageData,
  searchData,
  navActions,
  logo,
  appName,
}) => {
  return (
    <React.Fragment>
      <PrimaryNavigation
        logo={logo}
        appName={appName}
        primaryMetaItems={primaryMetaItems}
        primaryNavigationItems={primaryNavigationItems}
        mobileNavigationItems={mobileNavigationItems}
        countryData={countryData}
        searchData={searchData}
        languageData={languageData}
        navActions={navActions}
      />
      {secondaryNavigationItems && (
        <SecondaryNavigation
          secondaryHeadline={secondaryHeadline}
          secondaryNavigationItems={secondaryNavigationItems}
          navActions={navActions}
        />
      )}
    </React.Fragment>
  );
};

export default Navbar;

Navbar.propTypes = {
  /** If present shows the appname next to the DHL logo.  */
  appName: PropTypes.string,

  /** This is an **optional** prop of the below shape, controls the Copy Text for the More and the Less buttons when navigation items grows beyond the container width. */
  navActions: PropTypes.shape({
    moreText: PropTypes.string,
    lessText: PropTypes.string,
  }),
  /** This is **required** prop of the below shape that controls the behaviour of the DHL Logo i.e. the title, alternate text, href etc.
   *
   * **Required Fields** `href, alt` */
  logo: PropTypes.shape({
    /** where to redirect  */
    href: PropTypes.string.isRequired,
    /** logo title */
    title: PropTypes.string,
    /** redirection external or internal */
    external: PropTypes.bool,
    /** alternative text */
    alt: PropTypes.string.isRequired,
  }).isRequired,
  /** This is an **optional** prop of the below shape, if passed will display and controls the behaviour of the Search functionality.
   *
   * **Required Fields** `action, label` */
  searchData: PropTypes.shape({
    /** where to redirect for search query */
    action: PropTypes.string.isRequired,
    /** placeholder for search input */
    placeholder: PropTypes.string,
    /** search label */
    label: PropTypes.string.isRequired,
    /** This prop controls the configuration of quick link list items */
    quickLinklist: PropTypes.arrayOf(
      PropTypes.shape({
        /** redirect url */
        href: PropTypes.string.isRequired,
        /** if url should open in the same/new window */
        external: PropTypes.bool,
        /** Link title */
        title: PropTypes.string,
        /** copy text */
        copy: PropTypes.string.isRequired,
      })
    ),
  }),
  /** This is an **optional** prop if passed will display and manage the configuration of each primary navigation item regarding the headline, title, redirection link, the popover component etc. It accepts an array of object of navigationItems as the below shape.
   *
   * `popover node` could be an html/react element to be rendered as the popover content
   *
   * **Required Fields** `headline, href` */
  primaryNavigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string.isRequired,
      /** redirection link */
      href: PropTypes.string.isRequired,
      /** if link should open in current window or new window */
      external: PropTypes.bool,
      /** Title of the link */
      title: PropTypes.string,
      /** Link popover content */
      popover: PropTypes.node,
    })
  ),
  /** This is an **optional** prop of the below shape if passed will display and manage the configuration of each primary meta item regarding the copy, title, redirection link, and if it should open in a new window.
   *
   * **Required Fields** `href, copy`
   */
  primaryMetaItems: PropTypes.arrayOf(
    PropTypes.shape({
      /** redirection link */
      href: PropTypes.string.isRequired,
      /** if link should open in current window or new window */
      external: PropTypes.bool,
      /** Title of the link */
      title: PropTypes.string,
      /** link text */
      copy: PropTypes.string.isRequired,
    })
  ),
  /** This is an **optional** prop of the below shape if passed will display and manage the mobile navigation drawer. It accepts an object of menuItems, search redirect url and language redirec
   *  MenuItems is an object that constructs the mobile navigation items, each navigation item can either be of type `backbtn, headline, list and linkList`
   *
   *
   *   `type Definitions: `
   *
   *   `link` renders the item as basic anchor element
   *
   *   `headline` renders an item as a headline
   *
   *   `linkList` renders an item as a list of sublists
   *
   *   `backBtn` renders the link as a backBtn
   *
   *  **Required Fields** `href copy type`
   *
   */
  mobileNavigationItems: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        /** redirection link */
        href: PropTypes.string.isRequired,
        /** if link should open in current window or new window */
        external: PropTypes.bool,
        /** Title of the link */
        title: PropTypes.string,
        /** menu item text */
        copy: PropTypes.string.isRequired,
        /** menu item type i.e. list, backBtn, linklist, headline */
        type: PropTypes.string.isRequired,
        /** menu type linklist further data */
        linkList: PropTypes.arrayOf(
          PropTypes.shape({
            /** redirection link */
            href: PropTypes.string.isRequired,
            /** if link should open in current window or new window */
            external: PropTypes.bool,
            /** Title of the link */
            title: PropTypes.string,
            /** menu item text */
            copy: PropTypes.string.isRequired,
            /** menu item type i.e. list, backBtn, linklist, headline */
            type: PropTypes.string.isRequired,
          })
        ),
      })
    ),
    /** mobile search redirection url */
    searchUrl: PropTypes.string.isRequired,
    /** mobile language redirection url */
    languageUrl: PropTypes.string,
  }),
  /** This is an **optional** prop of the below shape if passed will display and manage configuration of each secondary navigation item regarding the headline, title, redirection link, the popover component etc. It accepts an array object of navigationItems as the below shape.
   *
   * **Required Fields** `href, headline`
   */
  secondaryNavigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      /** redirection link */
      href: PropTypes.string.isRequired,
      /** if link should open in current window or new window */
      external: PropTypes.bool,
      /** Title of the link */
      title: PropTypes.string,
      /** Link text */
      headline: PropTypes.string.isRequired,
    })
  ),
  /** This is an **optional** prop of the below shape if passed will display and manage the configuration of secondary navigation headline.
   *
   * **Required Fields** `href, copy`
   */
  secondaryHeadline: PropTypes.shape({
    /** Headline text */
    copy: PropTypes.string.isRequired,
    /** Headline Redirection Url */
    href: PropTypes.string.isRequired,
    /** Headline Title */
    title: PropTypes.string,
    /** if headline should open in current window or new window */
    external: PropTypes.bool,
  }),
  /** This is an **optional** prop of the below shape if passed will display and manage the configuration of country data regarding the copy, title, redirection link, and if the link should open in the new window.
   *
   * **Required Fields** `href, copy`
   */
  countryData: PropTypes.shape({
    /** Country Redirection url */
    href: PropTypes.string.isRequired,
    /** if link should open externally */
    external: PropTypes.bool,
    /** Country Title */
    title: PropTypes.string,
    /** Country Text */
    copy: PropTypes.string.isRequired,
  }),
  /** This is an **optional** prop of the below shape if passed controls the configuration of the languages on the navbar. It accepts the array of languages object.
   *
   * **Required Fields** `href, copy`
   */
  languageData: PropTypes.arrayOf(
    PropTypes.shape({
      /** selected language bool */
      isSelected: PropTypes.bool,
      /** language redirection link */
      href: PropTypes.string.isRequired,
      /** if the window should open externally or internally */
      external: PropTypes.bool,
      /** language title */
      title: PropTypes.string,
      /** language text */
      copy: PropTypes.string.isRequired,
    })
  ),
};
