// 9fbef606107a605d69c0edbcd8029e5d

/**
 * Runs a loop for given number of times
 * @param {number} no
 * @param {function} mapCallback
 */
export const times = (no, mapCallback) => Array(no).fill().map(mapCallback);

/**
 * Chunks the given array into n items each
 * @param {Array} array
 * @param {number} n
 * @returns {Array<Arrays<number>>}
 */
export const chunk = (array, n) =>
  [...Array(Math.ceil(array.length / n))].map((v, i) =>
    array.slice(i * n, (i + 1) * n)
  );

/**
 * Populate an array with items from start to end
 * @param {*} start
 * @param {*} end
 * @returns {Array<number>}
 */
export function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

/**
 * If parameter is not Array, return it as an Array
 * @param value
 * Eg: transformToArray(3)) // returns "[3]"
 */
export const transformToArray = (value) =>
  Array.isArray(value) ? value : [value];

/**
 * Concatenates and returns values as a String if the respective values are set
 * @param {Array} values
 * Eg: concatIfSet["foo", null, false, "bar"] // returns "foo bar"
 */
export const concatIfSet = (values) =>
  values.filter(Boolean).join(" ") || undefined;
