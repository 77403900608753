// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import {
  SIZES,
  VARIANTS,
  TYPES,
  ICON_ORIENTATION,
  TEXT_VARIANTS,
  assignButtonConstants,
} from "./constants";
import { buttonStyles } from "./styleConstants";
import { IconWrapper } from "../IconWrapper";

// #region Component Styles

const getIconMargin = (iconOrientation) => {
  switch (iconOrientation) {
    case ICON_ORIENTATION.RIGHT:
      return css`
        margin-left: ${buttonStyles.ICON_MARGIN};
        order: 1;
      `;
    case ICON_ORIENTATION.LEFT:
      return css`
        margin-right: ${buttonStyles.ICON_MARGIN};
        order: -1;
      `;
    default:
      return css`
        margin-left: ${buttonStyles.ICON_MARGIN};
        order: 1;
      `;
  }
};

const getPadding = (size, children, icon, isRound) => {
  const iconOnly =
    ((children && children[0] === undefined) ||
      (children && children[0] === "")) &&
    icon;

  if (isRound) {
    return buttonStyles[size]?.PADDING_ROUND;
  }

  return iconOnly
    ? buttonStyles[size]?.PADDING_ICON_ONLY
    : buttonStyles[size]?.PADDING;
};

export const BASE_BUTTON = css`
  align-items: center;
  border: 0;

  color: var(--dui-color-white-500);
  font-family: var(--dui-font-family);
  font-weight: 700;
  justify-content: center;

  min-height: auto;
  position: relative;
  text-align: center;
  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const PRIMARY_BUTTON = css`
  background-color: var(--dui-color-red-500);
  transition: ease-in-out 200ms background-color;

  &:focus-visible {
    outline: 2px solid var(--dui-color-red-500);
    outline-offset: 2px;
  }

  /* Leave this after the focus style to prevent the box-shadow to appear on hover of a focused button */
  &:hover {
    background-color: var(--dui-color-red-400);
    box-shadow: unset;
  }

  &:active {
    background-color: var(--dui-color-red-600);
    box-shadow: unset;
  }

  &:disabled,
  &[aria-disabled="true"] {
    background-color: var(--dui-color-gray-300);
    color: var(--dui-color-white-500);
  }
`;

export const SECONDARY_BUTTON = css`
  background-color: transparent;
  border: 1px solid var(--dui-color-red-500);
  box-sizing: border-box;
  color: var(--dui-color-red-500);
  /* height: 48px; */

  &:focus-visible {
    outline: 2px solid var(--dui-color-red-500);
    outline-offset: 2px;
  }

  /* Leave this after the focus style to prevent the box-shadow to appear on hover of a focused button */
  &:hover {
    border-color: var(--dui-color-red-400);
    box-shadow: unset;
    color: var(--dui-color-red-400);
  }

  &:disabled,
  &[aria-disabled="true"] {
    background-color: var(--dui-color-white-500);
    border-color: var(--dui-color-gray-200);
    color: var(--dui-color-gray-300);
  }
`;

const textDarkColor = css`
  color: black;
  &:focus-visible {
    outline: 2px solid var(--dui-color-black-400);
    outline-offset: 2px;
  }

  &:hover {
    color: var(--dui-color-gray-600);
  }
`;
const textLightColor = css`
  color: var(--dui-color-white-500);
  &:focus-visible {
    outline: 2px solid var(--dui-color-white-500);
    outline-offset: 2px;
  }

  &:hover {
    color: var(--dui-color-gray-600);
  }
`;

const textPrimaryColor = css`
  &:focus-visible {
    outline: 2px solid var(--dui-color-red-500);
    outline-offset: 2px;
  }
`;

const getTextTheme = (textVariant) => {
  switch (textVariant) {
    case TEXT_VARIANTS.DARK:
      return textDarkColor;

    case TEXT_VARIANTS.LIGHT:
      return textLightColor;

    case TEXT_VARIANTS.PRIMARY:
      return textPrimaryColor;

    default:
      return textPrimaryColor;
  }
};

const TEXT_BUTTON = css`
  ${SECONDARY_BUTTON}
  border: 0;
  height: unset;
  ${({ textVariant }) => getTextTheme(textVariant)};

  padding: ${buttonStyles.TEXT_PADDING};
`;

export const getStyleForVariant = (variant) => {
  switch (variant) {
    case VARIANTS.OUTLINE:
      return SECONDARY_BUTTON;

    case VARIANTS.TEXT:
      return TEXT_BUTTON;

    case VARIANTS.PRIMARY:
    default:
      return PRIMARY_BUTTON;
  }
};

const StyledIconWrapper = styled(IconWrapper)`
  ${({ iconOrientation }) => iconOrientation && getIconMargin(iconOrientation)};
`;

const getWidth = (isBlock, isRound, children, icon, size) => {
  const iconOnly =
    ((children && children[0] === undefined) ||
      (children && children[0] === "")) &&
    icon;

  if (isBlock) {
    return "100%";
  }
  if (isRound && iconOnly) {
    return buttonStyles[size]?.ROUND_WIDTH;
  }
  return "fit-content";
};

export const getButtonStyles = ({
  size = SIZES.MD,
  variant,
  isBlock,
  icon,
  children,
  isRound,
}) => css`
  ${BASE_BUTTON}
  border-radius: ${isRound ? "50%" : buttonStyles[size]?.RADIUS};
  display: ${isBlock ? "flex" : "inline-flex"};

  font-size: ${buttonStyles[size]
    ? buttonStyles[size]?.FONT_SIZE
    : buttonStyles[SIZES.MD]?.FONT_SIZE};

  height: ${buttonStyles[size]?.HEIGHT};
  line-height: ${buttonStyles[size]?.LINE_HEIGHT};
  padding: ${getPadding(size, children, icon, isRound)};
  ${getStyleForVariant(variant)};
  width: ${getWidth(isBlock, isRound, children, icon, size)};
`;

export const Container = styled.button`
  ${(props) => getButtonStyles(props)}
`;
// #endregion

// Prop default values assigned here to enable the ButtonGroup.
// This shouldn't be recognised as a pattern within DUIL and will be refactored.
const Button = ({
  ariaControls,
  ariaDisabled,
  ariaLabel,
  ariaExpanded,
  ariaLabelledby,
  autoFocus,
  children,
  className,
  dataTestid,
  dataTracking,
  form,
  id,
  isBlock = false,
  isDisabled = false,
  isStyled,
  name,
  onClick,
  onKeyPress,
  size = "md",
  type = TYPES.BUTTON,
  variant = "primary",
  iconSize,
  icon,
  iconOrientation,
  isRound,
  textVariant,
}) => {
  const ButtonContainer = isStyled ? Container : styled.button``;
  return (
    <ButtonContainer
      aria-disabled={isDisabled || ariaDisabled}
      aria-controls={ariaControls}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
      aria-expanded={ariaExpanded}
      autoFocus={autoFocus}
      className={className}
      data-tracking={dataTracking}
      data-testid={dataTestid}
      disabled={isDisabled}
      form={form}
      id={id}
      icon={icon}
      iconOrientation={iconOrientation}
      isBlock={isBlock}
      name={name}
      onClick={onClick}
      onKeyPress={onKeyPress}
      size={size}
      type={type}
      variant={variant}
      isRound={isRound}
      textVariant={textVariant}
    >
      {children}
      {icon && (
        <StyledIconWrapper
          size={iconSize}
          iconOrientation={
            iconOrientation && children ? iconOrientation : undefined
          }
        >
          {icon}
        </StyledIconWrapper>
      )}
    </ButtonContainer>
  );
};

assignButtonConstants(Button);

Button.propTypes = {
  /** An **optional** ariaControls prop that is used as a 'relationship attribute'
   * which denotes which elements in a page an interactive element or set of elements has control
   *  over and affects. It's commonly used to describe a relationship between a button and the expandable
   *  region revealed by that button.
   *
   * (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#Attributes)
   *  */
  ariaControls: PropTypes.string,
  /** This is an **optional** property used in conjunction with an icon only variant. */
  isRound: PropTypes.bool,
  /** This is an **optional** property used to set the text color. */
  textVariant: PropTypes.oneOf(Object.values(TEXT_VARIANTS)),
  /**
   * An **optional** ariaDisabled prop that conveys the disabled state of the Button to assistive technology such
   * as screen readers.
   */
  ariaDisabled: PropTypes.bool,
  /** An **optional** prop defining the text read by the screen reader to represent the Button; use this if you need different text to be read from label */
  ariaLabel: PropTypes.string,
  /**
   * An **optional** ariaLabelledby prop that establishes the relationships between objects and their label(s), and
   * its value should be one or more element IDs, which refer to elements that have the text needed for labeling.
   * List multiple element IDs in a space delimited fashion.
   *
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-labelledby_attribute
   */
  ariaLabelledby: PropTypes.string,
  /** An **optional** prop that when set, automatically sets focus on mount. */
  autoFocus: PropTypes.bool,
  /** An **optional** prop that holds the children of the Button */
  children: PropTypes.node,
  /** An **optional** classname prop for the Button component */
  className: PropTypes.string,
  /** An **optional** test-id prop used to target the Button component for testing */
  dataTestid: PropTypes.string,
  /** An **optional** tracking value attached to the button as a data-tracking attribute. */
  dataTracking: PropTypes.string,
  /**
   * An **optional prop used to associate the button within a form context.
   * The value of the attribute must be the id attribute of a `<form>` element in the same document.
   * If this attribute is not specified, the <button> element will be associated to an ancestor
   * `<form>` element, if one exists. This attribute enables you to associate `<button>` elements to
   * `<form>` elements anywhere within a document, not just as descendants of `<form>` elements.
   *
   * (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#Attributes)
   */
  form: PropTypes.string,
  /** An **optional** id prop for the Button component  */
  id: PropTypes.string,
  /** An **optional** prop that sets whether the Button should inherit the width of it's container */
  isBlock: PropTypes.bool,
  /** An **optional** prop that sets whether the Button is in a disabled state */
  isDisabled: PropTypes.bool,
  /** An **optional** prop that sets whether the Button is styled */
  isStyled: PropTypes.bool,
  /** An **optional** name prop for the Button component */
  name: PropTypes.string,
  /** An **optional** onClick callback handler */
  onClick: PropTypes.func,
  /** An **optional** onKeyPress callback handler */
  onKeyPress: PropTypes.func,
  /** An **optional** size prop for the Button */
  size: PropTypes.oneOf([Button.MD, Button.SM, Button.XS]),
  /** An **optional** type prop used to set the type of the button e.g. reset, submit or button */
  type: PropTypes.oneOf([Button.BUTTON, Button.RESET, Button.SUBMIT]),
  /** An **optional** variant prop for the Button component */
  variant: PropTypes.oneOf([Button.PRIMARY, Button.OUTLINE, Button.TEXT]),
  /** An **optional** prop used for assistive technology support - used to mark expandable and collapsible regions */
  ariaExpanded: PropTypes.bool,
  /** An **optional** prop used to control the size of icons in the button */
  iconSize: PropTypes.oneOf([Button.SM, Button.MD, Button.LG, Button.XL]),
  /** An **optional** prop used to pass an icon to render by the children of the Button */
  icon: PropTypes.node,
  /**
   * An **optional** prop used to align an icon (if present) on either the left or right side of the Button text. */
  iconOrientation: PropTypes.oneOf([Button.LEFT, Button.RIGHT]),
};

Button.defaultProps = {
  onClick: Function.prototype,
  onKeyPress: Function.prototype,
  type: "button",
  iconOrientation: "right",
  isStyled: true,
};

export default Button;
