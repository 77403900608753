// 9fbef606107a605d69c0edbcd8029e5d

import { SIZES as TEXT_SIZES } from "components/atoms/Text/constants";
import { Text } from "components/atoms";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { TYPES } from "../constants";
import { Nav } from "./Nav";

const TITLE_LINE_HEIGHT = "52px";

const StyledTitle = styled(Text)`
  grid-column-end: 8;
  grid-column-start: 3;
  line-height: ${TITLE_LINE_HEIGHT};
  text-align: center;
`;

export const TitleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
`;

export const Title = ({
  dataTestid,
  ariaLabelForNextMonth,
  ariaLabelForNextYear,
  ariaLabelForPrevMonth,
  ariaLabelForPrevYear,
  changeMonth,
  changeYear,
  calDate,
  minDate,
  maxDate,
  showNext,
  showPrev,
  locale,
}) => {
  const tempCalDate = calDate.clone().date(1);

  const disable = {
    prevMonth:
      minDate?.isValid() &&
      tempCalDate.subtract(1, "month").isBefore(minDate, "month"),
    prevYear:
      minDate?.isValid() &&
      tempCalDate.subtract(1, "year").isBefore(minDate, "year"),
    nextMonth:
      maxDate?.isValid() &&
      tempCalDate.add(1, "month").isAfter(maxDate, "month"),
    nextYear:
      maxDate?.isValid() && tempCalDate.add(1, "year").isAfter(maxDate, "year"),
  };

  const title = calDate.locale(locale).format("MMMM YYYY");

  return (
    <TitleGrid data-testid={dataTestid}>
      <Nav
        id={dataTestid}
        type={TYPES.NAV.PREV}
        onChangeMonth={() => changeMonth(-1)}
        onChangeYear={() => changeYear(-1)}
        show={showPrev}
        disableMonth={disable.prevMonth}
        disableYear={disable.prevYear}
        ariaLabelForMonth={ariaLabelForPrevMonth}
        ariaLabelForYear={ariaLabelForPrevYear}
      />

      <StyledTitle
        id={`${dataTestid}-text`}
        size={TEXT_SIZES.XL}
        weight={700}
        ariaHidden
      >
        {title}
      </StyledTitle>

      <Nav
        id={dataTestid}
        type={TYPES.NAV.NEXT}
        onChangeMonth={() => changeMonth(1)}
        onChangeYear={() => changeYear(1)}
        show={showNext}
        disableMonth={disable.nextMonth}
        disableYear={disable.nextYear}
        ariaLabelForMonth={ariaLabelForNextMonth}
        ariaLabelForYear={ariaLabelForNextYear}
      />
    </TitleGrid>
  );
};

Title.propTypes = {
  dataTestid: PropTypes.string,
  /** Instance of dayjs for cal date */
  calDate: PropTypes.instanceOf(dayjs).isRequired,
  /** use this prop to disable the dates after minDate */
  minDate: PropTypes.instanceOf(dayjs),
  /** use this prop to disable the dates before maxDate */
  maxDate: PropTypes.instanceOf(dayjs),
  /** locale for calendar. This will changes the week and month labels */
  locale: PropTypes.string,
  /** Should show next navigation button? */
  showNext: PropTypes.bool,
  /** Should show prev navigation button? */
  showPrev: PropTypes.bool,
  /** Callback to change the month */
  changeMonth: PropTypes.func,
  /** Callback to change the month */
  changeYear: PropTypes.func,
  ariaLabelForNextMonth: PropTypes.string,
  ariaLabelForNextYear: PropTypes.string,
  ariaLabelForPrevMonth: PropTypes.string,
  ariaLabelForPrevYear: PropTypes.string,
};
