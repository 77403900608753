// 9fbef606107a605d69c0edbcd8029e5d
import styled from "styled-components";
import { getMediaQueries } from "styles/theme";
import {
  ResponsiveArrayOfValuesPropType,
  ResponsiveSizePropType,
} from "_commons/propTypes";

/**
 * React component used to create flexbox layouts.
 *
 * It renders a `div` with `display: flex` and
 * comes with helpful style shorthand.
 *
 */
export const Flex = styled.div`
  display: flex;

  ${({ gap, p, m, w, h, direction, justify, align, wrap }) =>
    getMediaQueries({
      height: h,
      margin: m,
      padding: p,
      width: w,
      gap,
      flexDirection: direction,
      justifyContent: justify,
      alignItems: align,
      flexWrap: wrap,
    })}
`;
Flex.displayName = "Flex";
Flex.DIRECTION = ["row", "row-reverse", "column", "column-reverse"];
Flex.WRAP = ["nowrap", "wrap", "wrap-reverse"];
Flex.ALIGN = ["stretch", "flex-start", "flex-end", "center", "baseline"];
Flex.JUSTIFY = [
  "flex-start",
  "flex-end",
  "center",
  "space-between",
  "space-around",
  "space-evenly",
];

Flex.propTypes = {
  /**
   * `m` is to control the margin of the component.
   * number | string | ResponsiveArray< string | number >
   */
  m: ResponsiveSizePropType,
  /**
   * `p` is to control the padding of the Flex component
   * number | string | ResponsiveArray< string | number >
   */
  p: ResponsiveSizePropType,
  /**
   * `w` is to control the width of Flex component
   * number | string | ResponsiveArray< string | number >
   */
  w: ResponsiveSizePropType,
  /**
   * `h` is to control the width of the Flex component
   * number | string | ResponsiveArray< string | number >
   */
  h: ResponsiveSizePropType,
  /**
   * Gap is to create space between child elements.
   * number | string | ResponsiveArray< string | number >
   */
  gap: ResponsiveSizePropType,
  /**
   * Shorthand for `flexWrap` style prop
   * nowrap | wrap | wrap-reverse |
   * ResponsiveArray< nowrap | wrap | wrap-reverse >
   */
  wrap: ResponsiveArrayOfValuesPropType(Flex.WRAP),
  /**
   * Shorthand for `flexDirection` style prop
   * row | row-reverse | column | column-reverse |
   * ResponsiveArray< row | row-reverse | column | column-reverse >
   */
  direction: ResponsiveArrayOfValuesPropType(Flex.DIRECTION),
  /**
   * Shorthand for `alignItems` style prop
   * stretch | flex-start | flex-end | center | baseline |
   * ResponsiveArray< stretch | flex-start | flex-end | center | baseline >
   */
  align: ResponsiveArrayOfValuesPropType(Flex.ALIGN),
  /**
   * Shorthand for `justifyContent` style prop
   * flex-start | flex-end | center | space-between | space-around | space-evenly |
   * ResponsiveArray< flex-start | flex-end | center | space-between | space-around | space-evenly >
   */
  justify: ResponsiveArrayOfValuesPropType(Flex.JUSTIFY),
};

Flex.defaultProps = {
  h: "auto",
  w: "100%",
  m: 0,
  p: 0,
  gap: 0,
};

export default Flex;
