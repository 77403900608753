// 9fbef606107a605d69c0edbcd8029e5d

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { IconCheckmarkCircle, IconCancelCircle } from "assets/icons";
import { ValidationMessage } from "./Message/Message";
import {
  StyledIconWrapper,
  ValidationMessageContainer,
  MessageIconWrapper,
} from "./styles";
import { VARIANTS, assignValidationFeedbackConstants } from "./constants";

const getValidationMessageVariant = (type) => {
  switch (type) {
    case VARIANTS.NOTE:
      return VARIANTS.NOTE;

    case VARIANTS.VALID:
      return VARIANTS.VALID;

    case VARIANTS.INVALID:
    default:
      return VARIANTS.INVALID;
  }
};

export const getValidationMessageId = (fieldId) =>
  `${fieldId}_validationMessage`;

const ValidationFeedback = ({ fieldId, validation, hideIcon, className }) => {
  const hasValidationMsg = !!validation?.message;
  return (
    <Fragment>
      {!hideIcon &&
        !hasValidationMsg &&
        (validation?.type === VARIANTS.INVALID ||
          validation?.type === VARIANTS.VALID) && (
          <StyledIconWrapper
            id={`${fieldId}-validation-icon-${validation.type}`}
            block
            animate="appearFromSide"
            size={StyledIconWrapper.SM}
            variant={
              validation?.type === VARIANTS.INVALID ? "error" : "success"
            }
          >
            {validation?.type === VARIANTS.INVALID ? (
              <IconCancelCircle />
            ) : (
              <IconCheckmarkCircle />
            )}
          </StyledIconWrapper>
        )}
      <ValidationMessageContainer
        hasValidationMsg={hasValidationMsg}
        role="alert"
        className={className}
      >
        {hasValidationMsg &&
          (validation?.type === VARIANTS.INVALID ||
            validation?.type === VARIANTS.VALID) && (
            <MessageIconWrapper
              id={`${fieldId}-validation-icon-${validation.type}`}
              block
              animate="appearFromSide"
              size={MessageIconWrapper.SM}
              variant={
                validation?.type === VARIANTS.INVALID ? "error" : "success"
              }
            >
              {validation?.type === VARIANTS.INVALID ? (
                <IconCancelCircle />
              ) : (
                <IconCheckmarkCircle />
              )}
            </MessageIconWrapper>
          )}
        {hasValidationMsg && (
          <ValidationMessage
            id={getValidationMessageId(fieldId)}
            message={validation.message}
            variant={getValidationMessageVariant(validation.type)}
          />
        )}
      </ValidationMessageContainer>
    </Fragment>
  );
};

assignValidationFeedbackConstants(ValidationFeedback);

ValidationFeedback.defaultProps = {
  fieldId: "validation-feedback",
};

ValidationFeedback.propTypes = {
  /** An **optional** prop defining the id of the element associated with ValidationFeedback */
  fieldId: PropTypes.string,
  /** A **required** ValidationFeedback validation prop.
   * Use this object shape to pass validation state to ValidationFeedback
   * **Required Fields** `type`
   */
  validation: PropTypes.shape({
    type: PropTypes.oneOf([
      ValidationFeedback.VALID,
      ValidationFeedback.INVALID,
      ValidationFeedback.NOTE,
    ]).isRequired,
    message: PropTypes.string,
  }).isRequired,
  /** An **optional** classname prop for the GlobalValidation component */
  className: PropTypes.string,
  /** An **optional** hideOcn prop used to determine if the icon should be shown */
  hideIcon: PropTypes.bool,
};

export default ValidationFeedback;
