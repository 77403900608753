// 9fbef606107a605d69c0edbcd8029e5d
import {
  SM,
  LG,
  XL,
  RING,
  RING_DUAL,
  RING_DONE,
  PRIMARY,
  SUCCESS,
} from "_commons/constants";

export const SIZES = {
  SM,
  LG,
  XL,
};

export const TYPES = {
  RING,
  RING_DUAL,
  RING_DONE,
};

// Naming as per the available colors
export const VARIANTS = {
  PRIMARY,
  SUCCESS,
};

/* eslint-disable no-param-reassign */
export function assignLoaderConstants(Component) {
  Component.prototype = Object.assign(Component, {
    ...SIZES,
    ...VARIANTS,
    ...TYPES,
  });
}
