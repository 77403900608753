// 9fbef606107a605d69c0edbcd8029e5d

import {
  SM,
  MD,
  LG,
  XL,
  STANDARD as REGULAR,
  ERROR,
  INVERTED,
  PRIMARY,
  SECONDARY,
  SUCCESS,
  WARN,
  SLOWER,
  SLOW,
  FAST,
} from "_commons/constants";

export const SIZES = {
  SM,
  MD,
  LG,
  XL,
};

export const VARIANTS = {
  ERROR,
  INVERTED,
  PRIMARY,
  SECONDARY,
  SUCCESS,
  WARN,
};

export const SPEED = {
  SLOWER,
  SLOW,
  REGULAR,
  FAST,
};

/* eslint-disable no-param-reassign */
export function assignIconWrapperConstants(Component) {
  Component.prototype = Object.assign(Component, {
    ...SIZES,
    ...VARIANTS,
    ...SPEED,
  });
}
