// 9fbef606107a605d69c0edbcd8029e5d

import { ANIMATED, STATIC, VALID, INVALID, NOTE } from "_commons/constants";

export const VARIANTS = {
  VALID,
  INVALID,
  NOTE,
};

export const LABEL_TYPES = {
  ANIMATED,
  STATIC,
};

/* eslint-disable no-param-reassign */
export function assignValidationFeedbackConstants(Component) {
  Component.prototype = Object.assign(Component, {
    ...LABEL_TYPES,
    ...VARIANTS,
  });
}
