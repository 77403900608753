// 9fbef606107a605d69c0edbcd8029e5d
import { VALID, INVALID, NOTE, ANIMATED, STATIC } from "_commons/constants";

export const LABEL_TYPES = {
  ANIMATED,
  STATIC,
};

export const VALIDATION_TYPES = {
  VALID,
  INVALID,
  NOTE,
};

/* eslint-disable no-param-reassign */
export function assignTextAreaFieldConstants(Component) {
  Component.prototype = Object.assign(Component, {
    ...VALIDATION_TYPES,
    ...LABEL_TYPES,
  });
}
