import { SECONDARY, PRIMARY } from "_commons/constants";

export const FOCUS_VARIANT = {
  PRIMARY,
  SECONDARY,
};

export const TAGS = {
  LI: "li",
  DIV: "div",
};

/* eslint-disable no-param-reassign */
export function assignCardConstants(Component) {
  Component.prototype = Object.assign(Component, {
    ...FOCUS_VARIANT,
    ...TAGS,
  });
}
