// 9fbef606107a605d69c0edbcd8029e5d

import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import { getMediaQueries } from "styles/theme";
import { ResponsiveArrayOfValuesPropType } from "_commons/propTypes";
import { COLORS, VARIANTS, assignHeadlineConstants } from "./constants";

const fontSizeStyles = {
  1: [
    "var(--dui-size-font-8xl)",
    "var(--dui-size-font-10xl)",
    "var(--dui-size-font-11xl)",
  ],
  2: [
    "var(--dui-size-font-6xl)",
    "var(--dui-size-font-7xl)",
    "var(--dui-size-font-9xl)",
  ],
  3: [
    "var(--dui-size-font-4xl)",
    "var(--dui-size-font-4xl)",
    "var(--dui-size-font-5xl)",
  ],
  4: ["var(--dui-size-font-3xl)"],
  5: ["var(--dui-size-font-xl)"],
};

const lineHeightStyles = {
  1: ["var(--dui-size-line-height-xl)"],
  2: [
    "var(--dui-size-line-height-xl)",
    "var(--dui-size-line-height-2xl)",
    "var(--dui-size-line-height-2xl)",
  ],
  3: ["var(--dui-size-line-height-xl)"],
  4: ["var(--dui-size-line-height-xl)"],
  5: ["var(--dui-size-line-height-xl)"],
};

const weightStyles = {
  [VARIANTS.LIGHT]: 200, // not in tokens
  [VARIANTS.BLACK]: "var(--dui-font-weight-black)",
};

const colorStyles = {
  [COLORS.RED]: `var(--dui-color-red-500)`,
  [COLORS.WHITE]: `var(--dui-color-white-500)`,
  [COLORS.BLACK]: `var(--dui-color-black-400)`,
  [COLORS.DEFAULT]: "var(--dui-brand-color-headline)",
};

const getColors = (mayBeArrayOrColorKey) =>
  Array.isArray(mayBeArrayOrColorKey)
    ? mayBeArrayOrColorKey.map((key) => colorStyles[key])
    : colorStyles[mayBeArrayOrColorKey];

const Container = styled.div`
  font-family: var(--dui-font-family);
  margin-top: 0;

  ${({
    designLevel,
    fontColor,
    fontStretch,
    inlined,
    variant,
    noMargin,
    textAlign,
    textTransform,
  }) => css`
    display: ${inlined ? "inline-block" : "block"};
    font-stretch: ${fontStretch};

    font-weight: ${weightStyles[variant]};
    margin-bottom: ${noMargin ? 0 : undefined};
    text-align: ${textAlign || undefined};
    text-transform: ${textTransform};
    width: ${inlined ? "fit-content" : "100%"};

    ${getMediaQueries({
      fontSize: fontSizeStyles[designLevel],
      lineHeight: lineHeightStyles[designLevel],
      color: getColors(fontColor),
    })}
  `}
`;
// #endregion

const Headline = ({
  children,
  className,
  color,
  fontStretch,
  dataTestid,
  designLevel,
  id,
  inlined,
  noMargin,
  tag,
  textAlign,
  textTransform,
  variant,
}) => (
  <Container
    as={tag}
    className={className}
    data-testid={dataTestid}
    designLevel={designLevel}
    fontColor={color}
    fontStretch={fontStretch}
    id={id}
    inlined={inlined}
    noMargin={noMargin}
    textAlign={textAlign}
    textTransform={textTransform}
    variant={variant}
  >
    {children}
  </Container>
);

// Assign all the variables
assignHeadlineConstants(Headline);

// prop types
Headline.propTypes = {
  /** This is a **required** prop. Child content to render */
  children: PropTypes.node.isRequired,
  /**
   * This is a **required** prop. Defines which headline tag to use. (h1-h6)
   *
   * For a special case in which the element should not appear semantically as a headline,
   * but should still get rendered in the same layout, it can additionally be rendered as a `<p>` tag.
   */
  tag: PropTypes.oneOf([
    Headline.H1,
    Headline.H2,
    Headline.H3,
    Headline.H4,
    Headline.H5,
    Headline.H6,
    Headline.P,
  ]).isRequired,
  /** This is an **optional** prop. Class to the root element */
  className: PropTypes.string,
  /** This is an **optional** prop. Color of the main headline color. */
  color: ResponsiveArrayOfValuesPropType([
    Headline.BLACK,
    Headline.WHITE,
    Headline.RED,
  ]),
  /** This is an **optional** prop. The test id attached to the headline as a data-testid attribute */
  dataTestid: PropTypes.string,
  /** This is an **optional** prop. Defines which font sizes and line heights are used */
  designLevel: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  /** This is an **optional** prop. Defines the Id of the root element */
  id: PropTypes.string,
  /** This is an **optional** prop. If inlined, it will set the headline to inline-block otherwise it will set to block and 100% width */
  inlined: PropTypes.bool,
  /** This is an **optional** prop. Removes the margin from the bottom of the component */
  noMargin: PropTypes.bool,
  /** This is an **optional** prop. Defines the font stretch attribute, determining the usage of various font variants  */
  fontStretch: PropTypes.oneOf([Headline.CONDENSED, Headline.NORMAL]),
  /** This is an **optional** prop. If set aligns the text to the desired side, if not set, the setting is inherited from the parent */
  textAlign: PropTypes.oneOf([Headline.CENTER, Headline.LEFT, Headline.RIGHT]),
  /** This is an **optional** prop. Defines the text transform, e.g. to capitalize the content */
  textTransform: PropTypes.oneOf([
    Headline.NONE,
    Headline.UPPERCASE,
    Headline.CAPITALIZE,
    Headline.LOWERCASE,
  ]),
  /** This is an **optional** prop. Defines the header's font-weight */
  variant: PropTypes.oneOf([Headline.LIGHT, Headline.BLACK]),
};

// default values
Headline.defaultProps = {
  color: Headline.BLACK,
  designLevel: 1,
  fontStretch: Headline.CONDENSED,
  inlined: false,
  noMargin: false,
  textTransform: Headline.NONE,
  variant: Headline.BLACK,
};

export default Headline;
