// 9fbef606107a605d69c0edbcd8029e5d
import { Flex } from "./Flex";
/* eslint-disable react/forbid-foreign-prop-types */

export const onlyGapRelated = {
  propTypes: {
    gap: Flex.propTypes.gap,
    h: Flex.propTypes.h,
    m: Flex.propTypes.m,
    p: Flex.propTypes.p,
    w: Flex.propTypes.w,
  },
  defaultProps: {
    gap: Flex.defaultProps.gap,
    h: Flex.defaultProps.h,
    m: Flex.defaultProps.m,
    p: Flex.defaultProps.p,
    w: Flex.defaultProps.w,
  },
};

export const onlyGapAndDirectionRelated = {
  propTypes: {
    ...onlyGapRelated.propTypes,
    direction: Flex.propTypes.direction,
  },
  defaultProps: {
    ...onlyGapRelated.defaultProps,
    direction: Flex.defaultProps.direction,
  },
};
