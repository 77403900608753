// 9fbef606107a605d69c0edbcd8029e5d

import React, { useState } from "react";
import styled, { css } from "styled-components";
import { getViewport } from "styles/theme";
import PropTypes from "prop-types";
import { IconGlobe, IconSearch, IconArrowDown } from "assets/icons";
import { FONT_SIZES } from "components/atoms/Link/constants";
import { NavLink } from "./shared";
import {
  WEIGHTS as TEXT_WEIGHTS,
  SIZES as TEXT_SIZES,
} from "../../atoms/Text/constants";
import { IconWrapper } from "../../atoms";
import MENU_ITEM_TYPES from "./constants";

const HamburgerMenuTopStyle = `var(--dui-size-space-7x)`;

const StyledIconWrapper = styled(IconWrapper)`
  position: absolute;
  right: 0px;
  top: 25px;
`;

const IconChevronRight = styled(IconArrowDown)`
  transform: rotate(-90deg);
`;

const IconChevronLeft = styled(IconArrowDown)`
  left: -2.8px;
  position: relative;
  top: 3px;
  transform: rotate(90deg);
`;

const StyledLink = styled(NavLink)`
  display: block;
  margin: 0;
  position: relative;
  transition: background-color 200ms;
  &:focus,
  &:active {
    background-color: var(--dui-color-gray-50);
    color: var(--dui-color-red-400);
    outline: none;
  }

  svg {
    fill: var(--dui-color-red-500);
  }

  &.headline {
    color: var(--dui-color-red-500);
  }
`;

const MobileNavList = styled.ul`
  display: flex;
  flex: 0 1 auto;
  flex-basis: 0;
  flex-flow: row wrap;
  flex-grow: 1;
  height: 100%;
  justify-content: flex-end;
  margin: 0;
  max-width: 100%;
  padding: 0;
  @media screen and (min-width: ${getViewport("md")}) {
    display: none;
  }
`;

const MobileNavListItem = styled.li`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  list-style-type: none;
  max-height: 100%;
  width: calc(7 * var(--dui-size-space-3x));

  ${({ showHamburgerMenu }) =>
    showHamburgerMenu
      ? css`
          background: var(--dui-color-white-500);
        `
      : undefined}

  &:last-child {
    cursor: pointer;
    line-height: calc(4 * var(--dui-size-space-3x));
    padding: var(--dui-size-space-7x);
  }

  & > ${StyledLink} {
    color: var(--dui-color-red-400);
    padding: var(--dui-size-space-7x);
  }
`;

const HamburgerMenu = styled.button`
  background-color: transparent;
  border: none;
  height: 100%;
  position: relative;
  width: 100%;
  ${({ openMenu }) =>
    openMenu
      ? css`
          & span:nth-child(1) {
            left: 50%;
            top: ${HamburgerMenuTopStyle};
            width: 0;
          }
          & span:nth-child(2) {
            height: 2px;
            top: ${HamburgerMenuTopStyle};
            transform: rotate(45deg);
          }
          & span:nth-child(3) {
            height: 2px;
            top: ${HamburgerMenuTopStyle};
            transform: rotate(-45deg);
          }
          & span:nth-child(4) {
            left: 50%;
            top: ${HamburgerMenuTopStyle};
            width: 0%;
          }
        `
      : css`
          & span:nth-child(1) {
            top: 5px;
          }
          & span:nth-child(2) {
            top: 13px;
          }
          & span:nth-child(3) {
            top: 13px;
          }
          & span:nth-child(4) {
            top: 21px;
          }
        `}

  & span {
    background: var(--dui-color-red-500);
    display: block;
    height: 3px;
    left: 0;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: left 250ms ease-in-out, top 250ms ease-in-out,
      transform 250ms ease-in-out, width 250ms ease-in-out;
    width: var(--dui-size-space-11x);
    will-change: left, top, transform, width;
  }
`;

const MobileMenuContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: calc(4 * var(--dui-size-space-7x));
  position: absolute;
  right: -100%;
  top: 0;
  transition: right 400ms;
  width: 100%;

  ${({ openMenu }) =>
    openMenu
      ? css`
          height: auto;
          right: 0;
        `
      : undefined}
`;

const MobileMenuList = styled.ul`
  background: var(--dui-color-white-500);
  box-sizing: border-box;
  display: none;
  margin-bottom: 0;
  max-height: calc(
    100vh - 56px
  ); /** Brought in from logistics for similar styling */
  position: absolute;
  right: 0;
  transition: right 400ms;
  width: 100%;

  ${({ openMenu }) =>
    openMenu
      ? css`
          display: block;
          overflow-y: scroll;
          padding: 0 var(--dui-size-space-10x)
            calc(7 * var(--dui-size-space-7x));
        `
      : undefined}
`;

const StyledListItem = styled.li`
  border-bottom: 1px solid var(--dui-color-gray-300);
  list-style-type: none;

  & ${MobileMenuList} {
    padding: 0 var(--dui-size-space-10x) calc(7 * var(--dui-size-space-7x));
    position: absolute;
    top: 0;
    transition: right 400ms;
  }

  & ${StyledLink} {
    padding: var(--dui-size-space-10x) 0 var(--dui-size-space-10x)
      var(--dui-size-space-3x);
    line-height: 1.75rem;
    box-shadow: unset;
    width: 100%;
    text-align: left;
    border-radius: 0;
  }

  &.is-open {
    & > ${MobileMenuList} {
      display: block;
      right: -100%;
      z-index: 1000;
    }
  }
`;

const MobileIconWrapper = styled(IconWrapper)`
  height: 28px;
  width: 28px;
`;

const handleBackBtnClick = (element) => {
  const clickedEl = element;
  if (clickedEl.closest("ul").classList.contains("level-0")) {
    clickedEl.closest("ul").style.right = "0%";
  } else {
    clickedEl.closest("ul").closest("ul").style.right = "-100%";
  }
  clickedEl.querySelector("ul").style.overflowY = "visible";
  clickedEl.closest("ul").style.overflowY = "scroll";
  element.classList.remove("is-open");
};

const handleForwardBtnClick = (element, isLevelZero) => {
  const clickedEl = element;
  if (isLevelZero) {
    clickedEl.closest("ul").style.overflowY = "visible";
    clickedEl.querySelector("ul").style.overflowY = "scroll";
    clickedEl.classList.add("is-open");
    clickedEl.closest("ul").style.right = "100%";
  } else {
    clickedEl.classList.add("is-open");
    clickedEl.closest("ul").style.overflowY = "inherit";
    clickedEl.querySelector("ul").style.overflowY = "scroll";
    clickedEl.closest("ul").style.right = "0%";
  }
};

const handleClick = (e) => {
  // TODO - Revisit this logic
  e.stopPropagation();
  const { currentTarget } = e;
  const isLevelZero = currentTarget.closest("ul").classList.contains("level-0");
  /* only invoke click event for link that has list within it 
    for normal link redirection would take place */
  if (currentTarget.classList.contains("has-subnav")) {
    if (currentTarget.classList.contains("is-open")) {
      handleBackBtnClick(currentTarget);
    } else {
      handleForwardBtnClick(currentTarget, isLevelZero);
    }
  }
};

const renderMenu = (menuItems) => {
  return menuItems.map((item) => {
    // eslint-disable-next-line no-use-before-define
    return renderMenuItem(item);
  });
};

const renderMenuItem = (item) => {
  const isBackBtn = item.type === MENU_ITEM_TYPES.BACK_BTN;
  const { id } = item;
  const hasSubNav = item.linkList && item.linkList.length > 0;
  const isHeadline = item.type === MENU_ITEM_TYPES.HEADLINE;
  const isList = item.type === MENU_ITEM_TYPES.LIST;
  const { copy } = item;
  if (isBackBtn) {
    return (
      <StyledListItem key={id} className={MENU_ITEM_TYPES.BACK_BTN}>
        <StyledLink
          href={item.href}
          size={TEXT_SIZES.MD}
          weight={TEXT_WEIGHTS[400]}
        >
          <IconWrapper size={IconWrapper.SM}>
            <IconChevronLeft />
          </IconWrapper>
          {copy}
        </StyledLink>
      </StyledListItem>
    );
  }
  if (isHeadline) {
    return (
      <StyledListItem key={id}>
        <StyledLink
          className={MENU_ITEM_TYPES.HEADLINE}
          weight={TEXT_WEIGHTS[800]}
          href={item.href}
          size={TEXT_SIZES.LG}
        >
          {copy}
        </StyledLink>
      </StyledListItem>
    );
  }
  if (isList) {
    return (
      <StyledListItem
        key={id}
        onClick={handleClick}
        className={hasSubNav ? "has-subnav" : ""}
      >
        <StyledLink
          href={item.href}
          size={TEXT_SIZES.MD}
          weight={TEXT_WEIGHTS[400]}
        >
          {copy}
          {hasSubNav && (
            <StyledIconWrapper variant="primary" size={StyledIconWrapper.SM}>
              <IconChevronRight />
            </StyledIconWrapper>
          )}
        </StyledLink>
        {hasSubNav && (
          <MobileMenuList>{renderMenu(item.linkList)}</MobileMenuList>
        )}
      </StyledListItem>
    );
  }
  return false;
};

const MobileNavigation = ({ mobileNavigationItems }) => {
  const { items, searchUrl, languageUrl } = mobileNavigationItems;
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <MobileNavList>
      {languageUrl && (
        <MobileNavListItem>
          <StyledLink href={languageUrl}>
            <MobileIconWrapper block variant="primary" size={FONT_SIZES.LG}>
              <IconGlobe />
            </MobileIconWrapper>
          </StyledLink>
        </MobileNavListItem>
      )}
      {searchUrl && (
        <MobileNavListItem>
          <StyledLink href={searchUrl}>
            <MobileIconWrapper block variant="primary" size={FONT_SIZES.LG}>
              <IconSearch />
            </MobileIconWrapper>
          </StyledLink>
        </MobileNavListItem>
      )}

      <MobileNavListItem showHamburgerMenu={openMenu}>
        <HamburgerMenu
          className="hamburger-menu"
          openMenu={openMenu}
          onClick={() => setOpenMenu(!openMenu)}
        >
          <span />
          <span />
          <span />
          <span />
        </HamburgerMenu>
        <MobileMenuContainer openMenu={openMenu}>
          {openMenu && (
            <MobileMenuList className="level-0" openMenu={openMenu}>
              {renderMenu(items)}
            </MobileMenuList>
          )}
        </MobileMenuContainer>
      </MobileNavListItem>
    </MobileNavList>
  );
};

export default MobileNavigation;

MobileNavigation.propTypes = {
  mobileNavigationItems: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        /** redirection link */
        href: PropTypes.string,
        /** if link should open in current window or new window */
        external: PropTypes.bool,
        /** Title of the link */
        title: PropTypes.string,
        /** menu item text */
        copy: PropTypes.string,
        /** menu item type i.e. list, back, linklist */
        type: PropTypes.string,
        /** menu type linklist further data */
        linkList: PropTypes.arrayOf(
          PropTypes.shape({
            /** redirection link */
            href: PropTypes.string,
            /** if link should open in current window or new window */
            external: PropTypes.bool,
            /** Title of the link */
            title: PropTypes.string,
            /** menu item text */
            copy: PropTypes.string,
            /** menu item type i.e. list, back, linklist */
            type: PropTypes.string,
          })
        ),
      })
    ),
    /** mobile search redirection url */
    searchUrl: PropTypes.string,
    /** mobile language redirection url */
    languageUrl: PropTypes.string,
  }).isRequired,
};
