// 9fbef606107a605d69c0edbcd8029e5d

/**
 * In all the components we have lot of duplicate constants are defined.
 * It is better to have all the constants at one place to avoid any anomalies
 */

const CONSTANTS = Object.freeze({
  // Validation Label Types
  ANIMATED: "animated",
  STATIC: "static",

  // Validation Validity Types
  VALID: "valid",
  INVALID: "invalid",
  NOTE: "note",

  // Input Types
  EMAIL: "email",
  NUMBER: "number",
  NUMERIC: "numeric",
  PASSWORD: "password",
  TELEPHONE: "tel",
  TEXT: "text",
  DATE: "date",
  // uncertain
  DEFAULT: "default",
  NONE: "none",
  BUTTON: "button",
  RESET: "reset",
  SUBMIT: "submit",

  // tags
  H1: "h1",
  H2: "h2",
  H3: "h3",
  H4: "h4",
  H5: "h5",
  H6: "h6",
  P: "p",

  CONDENSED: "condensed",
  NORMAL: "normal",

  // text transform
  UPPERCASE: "uppercase",
  LOWERCASE: "lowercase",
  CAPITALIZE: "capitalize",
  ITALIC: "italic",

  // colors
  WHITE: "white",
  RED: "red",
  BLACK: "black",
  YELLOW: "yellow",
  LIGHT: "light",
  GREEN: "green",
  DARK: "dark",

  // alignment
  CENTER: "center",
  LEFT: "left",
  RIGHT: "right",

  // vertical alignment
  TOP: "top",
  MIDDLE: "middle",
  BOTTOM: "bottom",

  // sizes
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl",

  // variants
  PRIMARY: "primary",
  OUTLINE: "outline",
  SECONDARY: "secondary",
  ERROR: "error",
  INVERTED: "inverted",
  SUCCESS: "success",
  WARN: "warning",
  BASIC: "basic",
  ILLUSTRATED: "illustrated",
  REGULAR: "regular",

  // orientations
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",

  // list types
  CHECK: "check",
  BULLET: "bullet",

  // speed
  SLOWER: "5x",
  SLOW: "4x",
  STANDARD: "3x",
  FAST: "2x",

  // loader types
  RING: "ring",
  RING_DUAL: "ring-dual",
  RING_DONE: "ring-done",
});

module.exports = CONSTANTS;
