import { useRef, useCallback, useState } from "react";

export function useGetLatest(val) {
  const ref = useRef(val);
  ref.current = val;
  return useCallback(() => ref.current, []);
}

const noop = () => {
  // do nothing
};

export function useControlledState({ initial, value, onChange = noop }) {
  if (initial === undefined && value === undefined) {
    throw new TypeError(
      'Either "value" or "initial" variable must be set. Now both are undefined'
    );
  }

  const [state, setState] = useState(initial);

  const getLatest = useGetLatest(state);

  const set = useCallback(
    (updater) => {
      const latestState = getLatest();

      const updatedState =
        typeof updater === "function" ? updater(latestState) : updater;

      if (typeof updatedState.persist === "function") updatedState.persist();

      setState(updatedState);
      if (typeof onChange === "function") onChange(updatedState);
    },
    [getLatest, onChange]
  );

  const isControlled = value !== undefined;

  return [isControlled ? value : state, isControlled ? onChange : set];
}
