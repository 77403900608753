// 9fbef606107a605d69c0edbcd8029e5d
import {
  PRIMARY,
  OUTLINE,
  LIGHT,
  DARK,
  TEXT,
  BUTTON,
  RESET,
  SUBMIT,
  LEFT,
  RIGHT,
  XS,
  SM,
  MD,
  LG,
  XL,
} from "_commons/constants";

export const SIZES = {
  MD,
  SM,
  XS,
};

export const VARIANTS = {
  PRIMARY,
  OUTLINE,
  TEXT,
};

export const TEXT_VARIANTS = {
  PRIMARY,
  LIGHT,
  DARK,
};

export const TYPES = {
  BUTTON,
  RESET,
  SUBMIT,
};

export const ICON_ORIENTATION = {
  LEFT,
  RIGHT,
};

export const ICON_SIZE = {
  SM,
  MD,
  LG,
  XL,
};

/* eslint-disable no-param-reassign */
export function assignButtonConstants(Component) {
  Component.prototype = Object.assign(Component, {
    ...SIZES,
    ...VARIANTS,
    ...TYPES,
    ...ICON_ORIENTATION,
    ...ICON_SIZE,
  });
}
