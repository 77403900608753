// 9fbef606107a605d69c0edbcd8029e5d

import { IconWrapper } from "components/atoms";
import { TextSrOnly } from "components/atoms/Text/TextSROnly";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { DAY_MARGIN, DAY_WIDTH } from "./Styles";

const StyledIconWrapper = styled(IconWrapper)`
  height: var(--dui-size-space-10x);
  width: var(--dui-size-space-10x);
`;

const StyledNav = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0;

  box-sizing: border-box;
  color: var(--dui-color-black-500);
  display: flex;
  font-weight: normal;
  height: ${DAY_WIDTH};

  justify-content: center;
  margin: ${DAY_MARGIN};
  outline: 0;
  padding: 0;

  ${StyledIconWrapper} {
    color: var(--dui-color-black-500);
  }

  &:focus,
  &:hover,
  &:active {
    ${StyledIconWrapper} {
      color: var(--dui-color-red-500);
      fill: var(--dui-color-red-500);
    }

    cursor: pointer;
  }

  &:disabled {
    ${StyledIconWrapper} {
      color: var(--dui-color-gray-400);
      fill: var(--dui-color-gray-400);
    }
    cursor: not-allowed;
  }
`;

export const NavIconButton = ({
  iconComponent,
  onClick,
  ariaLabel,
  id,
  rotate,
  disabled,
}) => (
  <StyledNav
    id={id}
    onClick={onClick}
    type="button"
    aria-labelledby={`${id}-label`}
    data-testid={id}
    disabled={disabled}
  >
    <StyledIconWrapper rotate={rotate}>{iconComponent}</StyledIconWrapper>
    {ariaLabel && <TextSrOnly id={`${id}-label`}>{ariaLabel}</TextSrOnly>}
  </StyledNav>
);

NavIconButton.propTypes = {
  id: PropTypes.string,
  /** Which direction the chevron should show */
  rotate: PropTypes.string,
  /** Callback or click handler for this nav item */
  onClick: PropTypes.func,
  /** Should disable this icon button? */
  disabled: PropTypes.bool,
  /** Aria label for this icon button */
  ariaLabel: PropTypes.string,
  /** React icon Component */
  iconComponent: PropTypes.element,
};
