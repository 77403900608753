import { TextSrOnly } from "components/atoms/Text/TextSROnly";
import React from "react";
import PropTypes from "prop-types";

export const FullDateSrOnly = ({ msg }) => (
  <TextSrOnly role="alert" aria-live="polite" aria-atomic="true" title={msg} />
);

FullDateSrOnly.propTypes = {
  msg: PropTypes.string,
};
