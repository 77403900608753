// 9fbef606107a605d69c0edbcd8029e5d

import styled from "styled-components";
import { getViewport } from "styles/theme";

const NavigationListContainer = styled.div`
  display: none;
  @media screen and (min-width: ${getViewport("md")}) {
    display: flex;
    flex-flow: row wrap;
    flex: 1 1 auto;
  }
`;

export default NavigationListContainer;
