import React, { useEffect, useState, useMemo } from "react";
import { usePopper } from "react-popper";
import { keyCodes } from "utils/keyCodes";
import { useControlledState, useGetLatest } from "./utils";
import { TRIGGERS } from "./constants";

export function usePopover(
  triggerInteracted = false,
  config,
  popperOptions = {}
) {
  const defaultModifiers = useMemo(
    () => [{ name: "offset", options: { offset: config.offset } }],
    Array.isArray(config.offset) ? config.offset : []
  );

  const finalPopperOptions = {
    ...popperOptions,
    placement: popperOptions.placement || config.placement,
    modifiers: popperOptions.modifiers || defaultModifiers,
  };

  const [triggerRef, setTriggerRef] = useState(null);
  const [tooltipRef, setTooltipRef] = useState(null);
  const [visible, setVisible] = useControlledState({
    value: config.visible,
    onChange: config.onVisibleChange,
  });

  const { styles, attributes, ...popperProps } = usePopper(
    triggerRef,
    tooltipRef,
    finalPopperOptions
  );

  const getLatest = useGetLatest({
    visible,
    triggerRef,
    tooltipRef,
    config,
  });

  const hideTooltip = React.useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  // handle escape click
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === keyCodes.ESC) {
        hideTooltip();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  });

  // Handle click outside
  useEffect(() => {
    if (!getLatest().config.closeOnOutsideClick) return;

    const handleClickOutside = (event) => {
      // eslint-disable-next-line no-shadow
      const { tooltipRef, triggerRef } = getLatest();
      const { target } = event;
      if (target instanceof Node) {
        if (
          tooltipRef != null &&
          triggerRef != null &&
          !tooltipRef.contains(target) &&
          !triggerRef.contains(target)
        ) {
          hideTooltip();
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    // eslint-disable-next-line consistent-return
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [getLatest, hideTooltip, getLatest().config.closeOnOutsideClick]);

  // brings back focus on the intended element
  useEffect(() => {
    if (config?.trigger === TRIGGERS.CLICK && triggerInteracted) {
      if (visible) {
        // Focus it after it has been positioned by react-popper to prevent scrolling to the top.
        setTimeout(() => {
          tooltipRef?.focus();
        });
      } else {
        triggerRef?.focus();
      }
    }
  }, [visible, tooltipRef, triggerRef]);

  // Tooltip props getter
  const getTooltipProps = (args = {}) => {
    return {
      ...args,
      style: {
        ...args.style,
        ...styles.popper,
      },
      ...attributes.popper,
    };
  };

  // Arrow props getter
  const getArrowProps = (args = {}) => {
    return {
      ...args,
      ...attributes.arrow,
      style: {
        ...args.style,
        ...styles.arrow,
      },
      "data-popper-arrow": true,
    };
  };

  return {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    tooltipRef,
    triggerRef,
    visible,
    ...popperProps,
  };
}
