// 9fbef606107a605d69c0edbcd8029e5d

import { PRIMARY, OUTLINE, TEXT, XS, SM, MD } from "_commons/constants";
import {
  SIZES as FONT_SIZES,
  WEIGHTS as FONT_WEIGHTS,
} from "../Text/constants";

export const VARIANTS = {
  PRIMARY,
  OUTLINE,
  TEXT,
};

export const SIZES = {
  MD,
  SM,
  XS,
};

export { FONT_SIZES, FONT_WEIGHTS };

/* eslint-disable no-param-reassign */
export function assignLinkConstants(Component) {
  Component.prototype = Object.assign(Component, {
    ...VARIANTS,
    ...FONT_SIZES,
    ...FONT_WEIGHTS,
  });
}
