// 9fbef606107a605d69c0edbcd8029e5d

import Text from "components/atoms/Text";
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { getViewport, getMediaQuery } from "styles/theme";
import styled from "styled-components";
import { IconSearch, IconCancel } from "assets/icons";
import useOutsideClick from "hooks/useOutsideClick";
import { IconWrapper } from "../../atoms";
import { NavLink } from "./shared";
import { SIZES as FONT_SIZES } from "../../atoms/Text/constants";

const SearchIcon = styled(IconSearch)`
  position: relative;
  top: 3px;
`;

const CancelIcon = styled(IconCancel)`
  position: relative;
  top: var(--dui-size-space-1x);
`;

const StyledText = styled(Text)`
  cursor: pointer;
  display: inline-flex;
  max-width: 80px;
  transition: max-width 400ms ease, opacity 400ms ease;
`;

const StyledIconWrapper = styled(IconWrapper)`
  position: absolute;
  top: var(--dui-size-space-2x);
`;

export const QuickLinksContainer = styled.div.attrs({
  className: "quicklinks",
})`
  opacity: ${(props) => (props.open ? 1 : 0)};
  padding-right: 3px;
  position: absolute;
  text-align: left;
  transition: opacity 200ms ease;
  transition-delay: 200ms;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  width: 100%;
`;

const QuickLinksList = styled.ul`
  box-shadow: 0 1px 4px 0 rgb(50 50 50 / 50%);
  list-style-type: none;
  margin-top: 0;
  padding: 0;
`;

const QuickLinksItem = styled.li`
  background-color: var(--dui-color-white-500);
  margin-bottom: 0;
  padding: 0;
  & ${NavLink} {
    font-size: var(--dui-size-font-md);
    font-weight: normal;
    line-height: var(--dui-size-space-10x);
    box-sizing: border-box;
    display: inline-block;
    padding: var(--dui-size-space-3x) 0 var(--dui-size-space-3x)
      var(--dui-size-space-7x);
    width: 100%;
  }
  &:first-child {
    background-color: var(--dui-color-gray-100);
    & span {
      color: var(--dui-color-gray-500);
      font-size: var(--dui-size-space-7x);
      line-height: calc(5 * var(--dui-size-space-3x));
      padding: var(--dui-size-space-3x) 0 var(--dui-size-space-3x)
        var(--dui-size-space-7x);
      width: 100%;
    }
  }
`;

const StyledInput = styled.input`
  @media screen and (min-width: ${getViewport("md")}) {
    display: inline-block;
    opacity: ${(props) => (props.open ? 1 : 0)};
    width: ${(props) => (props.open ? "287px" : 0)};
    transition: width 400ms ease, opacity 400ms ease;
    background: none;
    box-sizing: border-box;
    padding-left: var(--dui-size-space-10x);
    border: none;
    font-size: var(--dui-size-font-sm);
    border-bottom: 2px solid var(--dui-color-black-400);
    &::placeholder {
      font-size: var(--dui-size-font-xs);
    }
    & ~ ${StyledText} {
      display: ${(props) => (props.open ? "none" : "inline-block")};
    }
  }
`;

export const CancelIconWrapper = styled.div`
  display: inline-block;
  position: absolute;
  right: 0;
`;

const Container = styled.div`
  display: none;
  ${getMediaQuery("md")`
    position: relative;
    display: block;
    &:hover {
    color: var(--dui-color-red-400);
    cursor: pointer;
    & ${IconWrapper} {
      color: var(--dui-color-red-400);
    }
  }
  `}
`;

export const Search = ({ open, onSearchClick, searchData }) => {
  const wrapperRef = useRef(null);
  const { action, placeholder, label, quickLinklist } = searchData;
  const [searchContent, setSearchContent] = useState("");
  useOutsideClick(wrapperRef, (e) => onSearchClick(e, false));

  const handleChange = (e) => {
    setSearchContent(e.target.value);
  };

  const keyDownHandler = (e, searchParams) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      window.location.href = `${
        searchParams.action +
        (searchParams.action.indexOf("?") !== -1 ? "&" : "?")
      }q=${encodeURIComponent(searchContent)}`;
    }
  };

  return (
    <Container onClick={(e) => onSearchClick(e, true)}>
      <form ref={wrapperRef} action={action} method="get">
        <StyledIconWrapper>
          <SearchIcon />
        </StyledIconWrapper>
        <StyledInput
          open={open}
          placeholder={placeholder}
          type="text"
          onKeyDown={(e) => keyDownHandler(e, searchData)}
          value={searchContent}
          onChange={handleChange}
        />
        {open && (
          <CancelIconWrapper
            onClick={(e) => {
              e.stopPropagation();
              onSearchClick(e, false);
            }}
          >
            <IconWrapper>
              <CancelIcon />
            </IconWrapper>
          </CancelIconWrapper>
        )}
        <StyledText size={FONT_SIZES.SM}>{label}</StyledText>
        {quickLinklist?.length > 0 && (
          <QuickLinksContainer open={open}>
            <QuickLinksList>
              <QuickLinksItem>
                <Text>Quick Links</Text>
              </QuickLinksItem>
              {quickLinklist.map((item) => {
                return (
                  <QuickLinksItem key={item.copy}>
                    <NavLink
                      href={item.href}
                      target={item.external ? "_blank" : "_self"}
                      title={item.title}
                    >
                      {item.copy}
                    </NavLink>
                  </QuickLinksItem>
                );
              })}
            </QuickLinksList>
          </QuickLinksContainer>
        )}
      </form>
    </Container>
  );
};

export default Search;

Search.propTypes = {
  /** onSearch callback to update state in the parent component to hide the primary meta items */
  onSearchClick: () => {},
  /** if search input is open */
  open: PropTypes.bool,
  searchData: PropTypes.shape({
    /** search text */
    label: PropTypes.string,
    /** search input placeholder */
    placeholder: PropTypes.string,
    /** search form action */
    action: PropTypes.string,
    /** This prop controls the configuration of quick link list items */
    quickLinklist: PropTypes.arrayOf(
      PropTypes.shape({
        /** redirect url */
        href: PropTypes.string.isRequired,
        /** if url should open in the same/new window */
        external: PropTypes.bool,
        /** Link title */
        title: PropTypes.string,
        /** copy text */
        copy: PropTypes.string.isRequired,
      })
    ),
  }),
};
