// 9fbef606107a605d69c0edbcd8029e5d

import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { VARIANTS } from "./constants";
import {
  Tab,
  TabList,
  TabPanel,
  TabLink,
  TextContainer,
  Container,
} from "./styles";

const ARROW_LEFT = "ArrowLeft";
const ARROW_RIGHT = "ArrowRight";

const getActiveIndex = (activeTab, pressedKey, tabDisabledStatus) => {
  const tabCount = tabDisabledStatus.length - 1;
  let activeIndex = 0;
  if (pressedKey === ARROW_RIGHT) {
    if (activeTab + 1 > tabCount) {
      activeIndex = 0;
    } else {
      activeIndex = activeTab + 1;
    }
  }
  if (pressedKey === ARROW_LEFT) {
    if (activeTab - 1 < 0) {
      activeIndex = tabCount;
    } else {
      activeIndex = activeTab - 1;
    }
  }

  if (tabDisabledStatus[activeIndex])
    activeIndex = getActiveIndex(activeIndex, pressedKey, tabDisabledStatus);

  return activeIndex;
};

const Tabs = ({
  id,
  className,
  dataTestid,
  variant,
  children,
  isBlock,
  ariaLabel,
  initialActiveIndex,
  onChange,
}) => {
  const [activeTab, setActiveTab] = useState(initialActiveIndex);
  const tabRefs = useRef([]);

  useEffect(() => {
    setActiveTab(initialActiveIndex);
  }, [initialActiveIndex]);

  const addToRefs = (tabElement) => {
    if (tabElement && !tabRefs.current.includes(tabElement)) {
      tabRefs.current.push(tabElement);
    }
  };

  const handleActiveTabChange = (activeTabIndex) => {
    if (activeTabIndex === activeTab) return;

    onChange?.(activeTabIndex);
    setActiveTab(activeTabIndex);
  };

  const handleKeyPress = ({ key: pressedKey }) => {
    if (![ARROW_RIGHT, ARROW_LEFT].includes(pressedKey)) return;

    const tabDisabledStatus = children.map(
      ({ props: { disabled } }) => disabled
    );
    const anyEnabled = tabDisabledStatus.some((disabled) => !disabled);
    if (!anyEnabled) return;

    const activeIndex = getActiveIndex(
      activeTab,
      pressedKey,
      tabDisabledStatus
    );
    handleActiveTabChange(activeIndex);
    tabRefs.current[activeIndex].focus();
  };

  const handleTabSwitch = (e, newActiveTab) => {
    e.preventDefault();

    handleActiveTabChange(newActiveTab);
  };

  const getKey = ({ tabKey, label }) => tabKey || label;

  return (
    <Container className={className} id={`${id}-container`}>
      <TabList
        ariaLabel={ariaLabel}
        variant={variant}
        data-testid={dataTestid}
        role="tablist"
        onKeyDown={(e) => handleKeyPress(e)}
        id={id}
        tabIndex={0}
      >
        {children.map((tab, index) => {
          const { label, disabled } = tab.props;
          return (
            <Tab
              key={getKey(tab.props)}
              isActive={index === activeTab}
              variant={variant}
              role="presentation"
              isBlock={isBlock}
            >
              <TabLink
                role="tab"
                ref={addToRefs}
                aria-disabled={disabled}
                aria-selected={index === activeTab ? "true" : "false"}
                tabIndex={index === activeTab ? 0 : -1}
                onClick={disabled ? null : (e) => handleTabSwitch(e, index)}
              >
                {typeof label === "string" && (
                  <TextContainer>{label}</TextContainer>
                )}
                {typeof label !== "string" && label}
              </TabLink>
            </Tab>
          );
        })}
      </TabList>
      {children.map((tabPanel, index) => {
        const panelContent = tabPanel.props.children;

        if (index === activeTab) {
          return (
            <TabPanel
              key={getKey(tabPanel.props)}
              role="tabpanel"
              hidden={false}
              tabIndex={0}
            >
              {panelContent}
            </TabPanel>
          );
        }
        return null;
      })}
    </Container>
  );
};

Tabs.propTypes = {
  /** Id to the root element */
  id: PropTypes.string,

  /** Optional aria-label string - in the context of TabList, describes the purpose of the set of tabs */
  ariaLabel: PropTypes.string,

  /** Class to the root element */
  className: PropTypes.string,

  /** Content of the Tab Panel */
  children: PropTypes.node,

  /** The test id attached to the component as a data-testid attribute. */
  dataTestid: PropTypes.string,

  /** What's the variant of the component based on `VARIANTS` constant */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),

  /** Boolean for Tab to take up width of its container */
  isBlock: PropTypes.bool,

  /** The current active tab index */
  initialActiveIndex: PropTypes.number,

  /** The onChange event, triggered when the active tab is changed, activeTabIndex is passed as parameter */
  onChange: PropTypes.func,
};

Tabs.defaultProps = {
  variant: VARIANTS.FILLED,
  isBlock: false,
  initialActiveIndex: 0,
};

export default Tabs;
export { Tab };
