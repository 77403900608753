// 9fbef606107a605d69c0edbcd8029e5d
import {
  HORIZONTAL,
  VERTICAL,
  REGULAR,
  RED,
  BLACK,
  GREY,
} from "_commons/constants";

export const VARIANTS = {
  REGULAR,
  STRIPED: "striped",
};

export const MOBILE_VARIANTS = {
  VERTICAL,
  HORIZONTAL,
};

export const BORDER_COLORS = {
  RED,
  BLACK,
  GREY,
};
