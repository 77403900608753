// 9fbef606107a605d69c0edbcd8029e5d

import { useEffect, useState, useCallback } from "react";
import scrollIntoView from "dom-scroll-into-view";

export const useKeysWithMenu = (
  onItemSelected,
  onClose,
  activeClassName,
  resetAfterSelection,
  menuOpen
) => {
  const [index, setIndex] = useState(-1);
  const [items, setItems] = useState([]);
  const [menuElem, setMenuElem] = useState(null);

  useEffect(() => {
    if (!menuOpen) {
      setItems([]);
      setIndex(-1);
    }
  }, [menuOpen]);

  useEffect(() => {
    if (!items.length) {
      setIndex(-1);
      return;
    }

    const [item] = items;
    if (item.id?.includes("group") && items.length > 1) {
      setIndex(1);
    } else {
      setIndex(0);
    }
  }, [items]);

  useEffect(() => {
    if (!items.length) return;

    items.forEach((item, i) => {
      if (i === index) {
        item.classList.add(activeClassName);
      } else {
        item.classList.remove(activeClassName);
      }
    });

    const listItem = items[index];
    if (listItem && menuElem) {
      scrollIntoView(listItem, menuElem, {
        onlyScrollIfNeeded: true,
      });
    }
  }, [items, index]);

  useEffect(() => {
    if (!menuElem) return;

    const elemArr = Array.from(menuElem.children);
    if (elemArr.length !== items.length) {
      setItems(elemArr);
    }
  });

  const setMenuRef = useCallback((ref) => {
    setMenuElem(ref);
  }, []);

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "ArrowDown": {
        if (items.length > 0) {
          setIndex((index + 1) % items.length);
        }
        e.preventDefault();
        break;
      }

      case "ArrowUp": {
        if (items.length) {
          setIndex(index === 0 ? items.length - 1 : index - 1);
        }

        e.preventDefault();
        break;
      }

      case "Enter": {
        if (items[index]?.dataset?.value) {
          onItemSelected(items[index].dataset.value);
          if (resetAfterSelection) {
            setIndex(-1);
          }
          e.preventDefault();
        }
        break;
      }

      case "Escape":
      case "Tab": {
        onClose();
        break;
      }

      default:
        break;
    }
  };

  return {
    setMenuRef,
    activeIndex: index,
    setActiveIndex: setIndex,
    handleKeyDown,
  };
};
