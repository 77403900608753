// 9fbef606107a605d69c0edbcd8029e5d

import dayjs from "dayjs";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useRef, useState } from "react";
import { parseToDayJs } from "utils/dateUtils";
import { shouldUseNativeDatePicker } from "utils/deviceUtils";
import { DATE_FORMAT } from "../constants";

/**
 * Returns an object by which you can set date and its relative text
 * This is mainly used for input date where you need to maintain date and its text value
 * along with ref and focused states
 * @param {dayjs | string} initialValue
 * @param {string} locale
 * @param {string} displayFormat
 */
export const useDate = (initialValue = dayjs(""), locale, displayFormat) => {
  const dateFormat = shouldUseNativeDatePicker ? DATE_FORMAT : displayFormat;
  const [date, _setDate] = useState(dayjs(""));
  const [showFullDate, _setShowFullDate] = useState(false);

  // DO NOT set any default value for "value" since debounce is attached to the value
  const [value, _setValue] = useState();
  const debouncedValue = useDebounce(value, 600);

  const ref = useRef(null);

  const parseAndSetDate = (str, resetDate) => {
    if (str) {
      const parsedDt = parseToDayJs(str, [DATE_FORMAT, displayFormat], locale);
      if (parsedDt.isValid()) {
        if (!parsedDt.isSame(date, "D")) {
          _setDate(parsedDt);
        }
      } else if (resetDate) {
        _setDate(dayjs(""));
      }
    } else if (str === "") {
      // When there is empty text reset the date object
      // We need this check since debounce handler will call
      // this function with initial value of "value" as undefined
      _setDate(dayjs(""));
    }
  };

  /**
   * Whenever there is a change in initialValue prop
   * set the value for input text
   */
  useEffect(() => {
    parseAndSetDate(initialValue, false);
  }, [initialValue]);

  /**
   * Whenever there is a change in date state
   * update the value for input text
   */
  useEffect(() => {
    if (date.isValid()) {
      const formattedDt = date.locale(locale).format(dateFormat);
      if (formattedDt !== value) {
        _setValue(formattedDt);
      }
    }
  }, [date]);

  /**
   * Whenever there is a change in debounced value
   * which means user is entering text in input
   * set the date object
   */
  useEffect(() => {
    parseAndSetDate(debouncedValue, true);
  }, [debouncedValue]);

  const reset = () => {
    _setValue("");
    _setDate(dayjs(""));
  };

  // Set Date will be called only when user selects
  // a date from the calendar component
  const setDate = (dt) => {
    _setDate(dt);
    _setShowFullDate(true);
    setTimeout(() => {
      // remove the text after 10 sec
      // screen readers will read by that time
      _setShowFullDate(false);
    }, 10 * 1000);
  };

  // Set Value will be called when user enter the date
  // manually
  const setValue = (txt) => {
    _setValue(txt);
  };

  const focus = () => {
    ref?.current?.focus();
  };

  const getFullDate = () => {
    return date.isValid()
      ? date.locale(locale).format("dddd, MMMM D, YYYY")
      : "";
  };

  const isSame = (text) => {
    return (
      date.locale(locale).format(displayFormat) === text ||
      date.format(DATE_FORMAT) === text
    );
  };

  return {
    value,
    date,
    ref,
    focus,
    reset,
    setDate,
    setValue,
    getFullDate,
    showFullDate,
    isSame,
  };
};
