// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { ORIENTATION, assignButtonGroupConstants } from "./constants";

// # region Component Styles

const getHorizontalStyles = () => css`
  button:first-child {
    border-bottom-left-radius: var(--dui-button-border-radius);
    border-top-left-radius: var(--dui-button-border-radius);
  }

  button:last-child {
    border-bottom-right-radius: var(--dui-button-border-radius);
    border-top-right-radius: var(--dui-button-border-radius);
  }
`;

const getVerticalStyles = () => css`
  flex-direction: column;

  button:first-child {
    border-top-left-radius: var(--dui-button-border-radius);
    border-top-right-radius: var(--dui-button-border-radius);
  }

  button:last-child {
    border-bottom-left-radius: var(--dui-button-border-radius);
    border-bottom-right-radius: var(--dui-button-border-radius);
  }
`;

const Container = styled.div.attrs({ role: "group" })`
  display: ${({ isBlock }) => (isBlock ? "flex" : "inline-flex")};

  & > button {
    border-radius: 0;

    ${({ orientation }) =>
      orientation === ORIENTATION.HORIZONTAL
        ? "margin-left: -1px"
        : "margin-top: -1px"};

    ${({ orientation }) =>
      orientation === ORIENTATION.VERTICAL && "width: 100%"};

    &:hover {
      z-index: 1;
    }

    &:focus {
      z-index: 2;
    }
  }

  ${({ orientation }) =>
    orientation === ORIENTATION.HORIZONTAL
      ? getHorizontalStyles()
      : getVerticalStyles()}
`;

// # endregion

const ButtonGroup = React.forwardRef(
  (
    {
      id,
      className,
      dataTestid,
      variant,
      ariaLabel,
      size,
      isDisabled,
      orientation,
      children,
      isBlock,
    },
    forwardRef
  ) => {
    const childrenWithProps = React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        size,
        isDisabled,
        isBlock,
        variant,
        ...child.props,
      });
    });

    return (
      <Container
        id={id}
        className={className}
        data-testid={dataTestid}
        variant={variant}
        orientation={orientation}
        isBlock={isBlock}
        size={size}
        isDisabled={isDisabled}
        ariaLabel={ariaLabel}
        ref={forwardRef}
      >
        {childrenWithProps}
      </Container>
    );
  }
);

assignButtonGroupConstants(ButtonGroup);

ButtonGroup.propTypes = {
  /** An **optional** id prop for the ButtonGroup component  */
  id: PropTypes.string,

  /** An **optional** classname prop for the ButtonGroup component */
  className: PropTypes.string,

  /** An **optional** test-id prop used to target the ButtonGroup component for testing */
  dataTestid: PropTypes.string,

  /** An **optional** orientation prop for the ButtonGroup component. */
  orientation: PropTypes.oneOf([ButtonGroup.HORIZONTAL, ButtonGroup.VERTICAL]),

  /**
   * An **optional** variant prop which sets the variant type for all Buttons within the ButtonGroup.
   *
   * Any Button which directly specifies their variant prop will override the
   * variant set by ButtonGroup
   */
  variant: PropTypes.oneOf([
    ButtonGroup.PRIMARY,
    ButtonGroup.OUTLINE,
    ButtonGroup.TEXT,
  ]),
  /** An **optional** prop defining the text read by the screen reader to represent the ButtonGroup; use this if you need different text to be read from label */
  ariaLabel: PropTypes.string,

  /** An **optional** size prop for the ButtonGroup
   *
   * Any Button which directly specifies their size prop will override the
   * size set by ButtonGroup
   */
  size: PropTypes.oneOf([ButtonGroup.MD, ButtonGroup.SM, ButtonGroup.XS]),

  /**
   *  An **optional** isDisabled boolean prop which sets the disabled state for all Buttons within the ButtonGroup.
   *
   * Any Button which directly specifies their isDisabled prop will override the
   * disabled state set by ButtonGroup
   */
  isDisabled: PropTypes.bool,

  /** An **optional** prop that holds the children of the ButtonGroup */
  children: PropTypes.node,

  /** An **optional** prop that sets whether the ButtonGroup should inherit the width of it's container */
  isBlock: PropTypes.bool,
};

ButtonGroup.defaultProps = {
  orientation: ButtonGroup.HORIZONTAL,
};

ButtonGroup.displayName = "ButtonGroup";

export default ButtonGroup;
