import styled, { css } from "styled-components";
import PropTypes from "prop-types";

const SELECT_PADDING_WITH_LABEL = css`
  var(--dui-size-space-10x) var(--dui-size-space-7x) var(--dui-size-space-4x) var(--dui-size-space-6x)
`;
const SELECT_PADDING_WITHOUT_LABEL = css`
  var(--dui-size-space-7x) var(--dui-size-space-6x)
`;

const Placeholder = styled.span`
  ${({ hasRightIcon, hasLeftIcon, hasLabel, isRequired }) => css`
    ${isRequired &&
    css`
      &:after {
        color: var(--dui-color-red-500);
        content: " *";
      }
    `};
    box-sizing: border-box;
    color: var(--dui-color-gray-400);
    display: block;
    font-family: var(--dui-font-family);
    font-size: var(--dui-size-font-md);
    left: 0;
    line-height: 1.15;
    overflow: hidden;
    padding: ${hasLabel
      ? css`
          ${SELECT_PADDING_WITH_LABEL}
        `
      : css`
          ${SELECT_PADDING_WITHOUT_LABEL}
        `};
    padding-left: ${hasLeftIcon ? css`var(--dui-size-space-16x)` : undefined};
    padding-right: ${hasRightIcon ? css`var(--dui-size-space-16x)` : undefined};
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: 0;

    transition: transform var(--dui-duration-3x),
      font-size var(--dui-duration-3x), opacity var(--dui-duration-3x);

    white-space: nowrap;
    width: 100%;
  `}
`;

export const Placeholders = styled.span`
  box-sizing: border-box;
  color: var(--dui-color-gray-400);
  display: block;
  font-family: var(--dui-font-family);
  font-size: var(--dui-size-font-md);
  left: 0px;
  overflow: hidden;
  padding: ${() =>
    css`
      var(--dui-size-space-7x) var(--dui-size-space-6x)
    `};
  padding-left: ${({ searchIcon }) =>
    searchIcon
      ? css`
          var(--dui-size-space-16x)
        `
      : undefined};
  padding-right: ${({ isShowingAnyIcon }) =>
    isShowingAnyIcon ? css`var(--dui-size-space-16x)` : undefined};
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 0px;

  transition: transform var(--dui-duration-3x), font-size var(--dui-duration-3x),
    opacity var(--dui-duration-3x);

  white-space: nowrap;
  width: 100%;
`;

Placeholder.defaultProps = {
  hasRightIcon: false,
  hasLabel: false,
  isRequired: false,
};

Placeholder.propTypes = {
  /** An **optional** prop to flag the Placeholder component to set spacing when right icon is shown */
  hasRightIcon: PropTypes.bool,
  /** An **optional** prop to flag the Placeholder component to set spacing when left icon is shown */
  hasLeftIcon: PropTypes.bool,
  /** An **optional** prop to flag the Placeholder component to set spacing when Label is also being shown */
  hasLabel: PropTypes.bool,
  /** An **optional** prop to flag the Placeholder component to append * after the label text */
  isRequired: PropTypes.bool,
};

export default Placeholder;
