// 9fbef606107a605d69c0edbcd8029e5d
import {
  CENTER,
  LEFT,
  RIGHT,
  DEFAULT,
  RED,
  WHITE,
  CONDENSED,
  NORMAL,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P,
  NONE,
  UPPERCASE,
  LOWERCASE,
  CAPITALIZE,
  LIGHT,
  BLACK,
} from "_commons/constants";

export const ALIGNMENTS = {
  CENTER,
  LEFT,
  RIGHT,
};

export const COLORS = {
  BLACK,
  DEFAULT,
  RED,
  WHITE,
};

export const FONT_STRETCHES = {
  CONDENSED,
  NORMAL,
};

export const TAGS = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P,
};

export const TEXT_TRANSFORMS = {
  NONE,
  UPPERCASE,
  LOWERCASE,
  CAPITALIZE,
};

export const VARIANTS = {
  LIGHT,
  BLACK,
};

/**
 * TODO: Remove this JSON object and update the code accordingly.
 * Since it is a breaking change we need to plan this for v13.0.0-beta
 */
export const LEVELS = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
};

export function assignHeadlineConstants(Component) {
  // eslint-disable-next-line no-param-reassign
  Component.prototype = Object.assign(Component, {
    ...ALIGNMENTS,
    ...COLORS,
    ...FONT_STRETCHES,
    ...TAGS,
    ...TEXT_TRANSFORMS,
    ...VARIANTS,
  });
}
