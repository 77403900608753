// 9fbef606107a605d69c0edbcd8029e5d

/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { IconArrowDown } from "assets/icons";
import { WEIGHTS, SIZES } from "components/atoms/Text/constants";
import { IconWrapper } from "../../../atoms";
import NavLink from "./NavLink";
import ListItem from "./ListItem";

const NavigationDropdownStyledLink = styled(NavLink)`
  ${({ visible }) => css`
    color: ${visible
      ? `var(--dui-color-red-400)`
      : `var(--dui-color-black-500)`};
    &:focus {
      color: var(--dui-color-red-400);
      text-decoration: ${visible ? "underline" : "none"};
      svg {
        fill: var(--dui-color-red-400);
      }
    }
    &:hover {
      svg {
        fill: var(--dui-color-red-400);
      }
    }
  `}
`;

const IconChevronDown = styled(IconArrowDown)`
  margin-left: var(--dui-size-space-3x);
  position: relative;
  top: 1px;
`;

const IconChevronUp = styled(IconChevronDown)`
  fill: var(--dui-color-red-400);
  transform: rotate(180deg);
`;

const StyledIconWrapper = styled(IconWrapper)`
  height: 21px;
  width: 21px;
`;

const NavigationDropdown = React.forwardRef(
  ({ label, weight, visible, href }, ref) => {
    return (
      <ListItem ref={ref} aria-haspopup="true" aria-expanded={!!visible}>
        <NavigationDropdownStyledLink
          size={SIZES.SM}
          weight={weight}
          visible={visible}
          href={href}
          onClick={(e) => e.preventDefault()}
        >
          {label}
          <StyledIconWrapper>
            {visible ? <IconChevronUp /> : <IconChevronDown />}
          </StyledIconWrapper>
        </NavigationDropdownStyledLink>
      </ListItem>
    );
  }
);

NavigationDropdown.displayName = "NavigationDropdown";

export default NavigationDropdown;

NavigationDropdown.propTypes = {
  /** navigation Link text */
  label: PropTypes.string.isRequired,
  /** navigation Link font */
  weight: PropTypes.oneOf(Object.values(WEIGHTS)),
  /** navigation Link redirect url */
  href: PropTypes.string.isRequired,
  /** if redirection is in the same window or the new window */
  visible: PropTypes.bool,
};
