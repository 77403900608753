// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { getMediaQuery } from "styles/theme";
import { transformToArray } from "utils/arrayUtils";

/* Design token values */
const VIEWPORT_XL = "1365px";
const VIEWPORT_LG = "1024px";
const VIEWPORT_2XL = "1920px";

const StyledGridContainer = styled.div`
  box-sizing: border-box;
  column-gap: var(--dui-size-space-10x);
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns[0]}, 1fr);
  justify-items: center;
  margin: 0 auto;
  padding: ${({ isFullWidth }) =>
    isFullWidth ? 0 : css`0 var(--dui-size-space-10x)`};
  row-gap: ${({ rowGap }) => rowGap || `var(--dui-size-space-10x)`};
  width: 100%;

  ${getMediaQuery("md")`
    grid-template-columns: repeat(${({ columns }) =>
      columns[1] || columns[0]}, 1fr);
    padding: ${({ isFullWidth }) =>
      isFullWidth ? 0 : css`0 calc(2 * var(--dui-size-space-10x))`};
  `}

  ${getMediaQuery("lg")`
    grid-template-columns: repeat(${({ columns }) =>
      columns[2] || columns[1] || columns[0]}, 1fr);
    max-width: ${({ isFullWidth }) => (isFullWidth ? undefined : VIEWPORT_LG)};
  `}

  ${getMediaQuery("2xl")`
    grid-template-columns: repeat(${({ columns }) =>
      columns[3] || columns[2] || columns[1] || columns[0]}, 1fr);
    max-width: ${({ isFullWidth }) => (isFullWidth ? undefined : VIEWPORT_XL)};
    padding: ${({ isFullWidth }) =>
      isFullWidth ? 0 : css`0 calc(4 * var(--dui-size-space-10x))`};
  `}

  ${getMediaQuery("3xl")`
    max-width: ${({ isFullWidth }) =>
      isFullWidth ? VIEWPORT_2XL : VIEWPORT_XL};
  `}
`;

const GridContainer = ({
  children,
  className,
  columns,
  dataTestid,
  id,
  isFullWidth,
  rowGap,
}) => {
  const columnsArray = transformToArray(columns);

  return (
    <StyledGridContainer
      className={className}
      columns={columnsArray}
      data-testid={dataTestid}
      id={id}
      isFullWidth={isFullWidth}
      rowGap={rowGap}
    >
      {children}
    </StyledGridContainer>
  );
};

GridContainer.propTypes = {
  /** A **required** prop that holds the children of the GridContainer component */
  children: PropTypes.node.isRequired,
  /** An **optional** classname prop for the GridContainer component */
  className: PropTypes.string,
  /** An **optional** test-id prop used to target the GridContainer component for testing */
  dataTestid: PropTypes.string,
  /** An **optional** id prop for the GridContainer component */
  id: PropTypes.string,
  /** An **optional** prop that sets the number of grid columns
   * Please note: Value can be of type number or array
   * If specified as number, the value is used for all viewports
   * If specified as array, the values are used for the viewports in the following order:
   * 0 = small. 1 = medium, 2 = large viewport, 3 = xlarge viewport
   * Use "unset" in larger viewports to ignore settings from smaller viewports */
  columns: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
    PropTypes.number,
    PropTypes.string,
  ]),
  /** An **optional** prop that specifies if the GridContainer should take the whole width of the parent container */
  isFullWidth: PropTypes.bool,
  /** An **optional** prop that defines the gap between rows */
  rowGap: PropTypes.string,
};

GridContainer.defaultProps = {
  columns: 12,
  isFullWidth: false,
};

export default GridContainer;
