// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";
import { VARIANTS } from "../constants";

// #region Component Styles
const APPER_FROM_TOP = keyframes`
  from {
    transform: translateY(-10px) scale(0.9);
    opacity: 0;
  }

  to {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
`;

export const Container = styled.div`
  align-items: center;

  animation: ${({ shouldAnimate }) =>
    shouldAnimate
      ? css`
          ${APPER_FROM_TOP} var(--dui-duration-3x)
        `
      : undefined};
  color: ${({ variant }) => {
    if (variant === VARIANTS.INVALID) {
      return "var(--dui-color-red-500)";
    }
    if (variant === VARIANTS.VALID) {
      return "var(--dui-color-green-400)";
    }
    return undefined;
  }};
  display: flex;
  font-size: var(--dui-size-font-sm);
`;

// #endregion

export const ValidationMessage = ({
  className,
  dataTestid,
  id,
  message,
  shouldAnimate,
  variant,
}) => (
  <Container
    data-testid={dataTestid}
    className={className}
    id={id}
    shouldAnimate={shouldAnimate}
    variant={variant}
  >
    {message}
  </Container>
);

ValidationMessage.propTypes = {
  /** Class to the root element */
  className: PropTypes.string,
  /** The test id attached to the button as a data-testid attribute. */
  dataTestid: PropTypes.string,
  id: PropTypes.string,
  message: PropTypes.string.isRequired,
  shouldAnimate: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(VARIANTS)).isRequired,
};

ValidationMessage.defaultProps = {
  shouldAnimate: true,
};
