import styled from "styled-components";

/**
 * @description
 *
 * Text Span component for the screen reader only
 *
 */
export const TextSrOnly = styled.span`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
