// 9fbef606107a605d69c0edbcd8029e5d

import { ITALIC, NORMAL, CONDENSED } from "_commons/constants";

export const SIZES = {
  "2XS": "0.625rem",
  XS: "0.75rem",
  SM: "0.875rem",
  MD: "1rem",
  LG: "1.125rem",
  XL: "1.25rem",
  "2XL": "1.375rem",
  "3XL": "1.5rem",
  "4XL": "1.75rem",
  "5XL": "1.875rem",
  "6XL": "2rem",
  "7XL": "2.125rem",
  "8XL": "2.25rem",
  "9XL": "2.5rem",
  "10XL": "2.625rem",
  "11XL": "3rem",
};

/**
 * TODO: Remove this JSON object and update the code accordingly.
 * Breaking change
 */
export const WEIGHTS = {
  200: 200,
  400: 400,
  700: 700,
  800: 800,
  900: 900,
};

export const STYLES = {
  NORMAL,
  ITALIC,
};

export const STRETCH = {
  NORMAL,
  CONDENSED,
};

export function assignTextConstants(Component) {
  // eslint-disable-next-line no-param-reassign
  Component.prototype = Object.assign(Component, {
    ...SIZES,
    ...STYLES,
    ...STRETCH,
    displayName: "Text",
  });
}
