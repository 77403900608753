import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { BRANDS } from "../styles/brands";
import "./css/duil.css";
import "./css/base.css";
import "./css/dhl.css";
import "./css/dpdhl.css";

const classNames = {
  [BRANDS.DHL]: "dui-dhl",
  [BRANDS.DPDHL]: "dui-dpdhl",
};

export const DuiThemeProvider = ({ theme, children }) => {
  const { name = BRANDS.DHL } = theme;

  useEffect(() => {
    if (document) {
      document.body.setAttribute("data-theme", classNames[name]);
    }
  }, [name]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

DuiThemeProvider.propTypes = {
  theme: PropTypes.shape({ name: PropTypes.oneOf([BRANDS.DHL, BRANDS.DPDHL]) }),
  children: PropTypes.node,
};
