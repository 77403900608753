// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import { Container as ButtonContainer } from "components/atoms/Button";
import styled from "styled-components";
import {
  FONT_SIZES,
  FONT_WEIGHTS,
  SIZES,
  assignLinkConstants,
  VARIANTS,
} from "./constants";

// #region Component Styles

const Container = styled(ButtonContainer).attrs({ as: "a" })`
  box-sizing: border-box;
  text-decoration: none;
  font-weight: ${({ weight }) => FONT_WEIGHTS[weight]};
  font-size: ${({ fontSize }) => fontSize};
`;

const UnstyledContainer = styled.a`
  font-size: ${({ size }) => size};
`;

const defaultStyle = { isBlock: false, size: "sm", variant: "text" };

// #endregion
const Link = ({
  ariaLabel,
  children,
  className,
  dataTestid,
  dataTracking,
  href,
  onClick,
  isStyled,
  rel,
  size,
  target,
  weight,
  withButtonStyle,
}) => {
  return isStyled ? (
    <Container
      fontSize={size}
      aria-label={ariaLabel}
      className={className}
      data-testid={dataTestid}
      data-tracking={dataTracking}
      href={href}
      isBlock={withButtonStyle.isBlock}
      onClick={onClick}
      rel={rel}
      size={withButtonStyle.size}
      target={target}
      variant={withButtonStyle.variant}
      weight={weight}
    >
      {children}
    </Container>
  ) : (
    <UnstyledContainer
      aria-label={ariaLabel}
      className={className}
      data-testid={dataTestid}
      data-tracking={dataTracking}
      href={href}
      onClick={onClick}
      rel={rel}
      size={size}
      target={target}
      weight={weight}
    >
      {children}
    </UnstyledContainer>
  );
};

assignLinkConstants(Link);

Link.propTypes = {
  /** An **optional** prop defining the text read by the screen reader to represent the Link; use this if you need different text to be read from label */
  ariaLabel: PropTypes.string,
  /** An **optional** children prop used for providing the string value of the Link */
  children: PropTypes.node.isRequired,
  /** An **optional** classname prop for the Link component */
  className: PropTypes.string,
  /** An **optional** test-id prop used to target the Link component for testing */
  dataTestid: PropTypes.string,
  /** An **optional** prop used for the tracking value attached to the link as a data-tracking attribute. */
  dataTracking: PropTypes.string,
  /** A **required** href prop that specifies the links destination */
  href: PropTypes.string.isRequired,
  /** An **optional** onClick callback handler */
  onClick: PropTypes.func,
  /** An **optional** prop that sets whether the Link is styled */
  isStyled: PropTypes.bool,
  /** An **optional** prop that specifies the relationship between the current document and the linked document */
  rel: PropTypes.string,
  /**
   * An **optional** prop that defines the size of the text-variant link
   */
  size: PropTypes.oneOf(Object.values(FONT_SIZES)),
  /** An **optional** prop that specifies where to open the linked document */
  target: PropTypes.string,
  /**
   * An **optional** prop that defines the weight of the text-variant link, using a range of options
   */
  weight: PropTypes.oneOf([200, 400, 700, 800]),
  /** An **optional** prop for Link used to set Button styling */
  withButtonStyle: PropTypes.shape({
    /** Should the component expand to the full width of its parent? */
    isBlock: PropTypes.bool,
    size: PropTypes.oneOf(Object.values(SIZES)),
    /** What's the button look and feel? Based on the `VARIANTS` constant. */
    variant: PropTypes.oneOf(Object.values(VARIANTS)).isRequired,
  }),
};

Link.defaultProps = {
  onClick: Function.prototype,
  isStyled: true,
  withButtonStyle: defaultStyle,
};

Link.displayName = "Link";

export default Link;
