// 9fbef606107a605d69c0edbcd8029e5d

import { IconCheckmark } from "assets/icons";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import animations from "styles/animations";
import { getAnimationSpeed } from "styles/theme";
import { SIZES, TYPES, VARIANTS, assignLoaderConstants } from "./constants";

const dimensions = {
  [SIZES.SM]: {
    width: "var(--dui-size-space-24x)",
    borderWidth: "var(--dui-size-space-2x)",
    padding: "var(--dui-size-space-6x)",
  },
  [SIZES.LG]: {
    width: "var(--dui-size-space-48x)",
    borderWidth: "calc(2 * var(--dui-size-width-border-md))",
    padding: "var(--dui-size-space-12x)",
  },
  [SIZES.XL]: {
    width: "240px",
    borderWidth: "calc(2 * var(--dui-size-width-border-md))",
    padding: "80px",
  },
};

const variantStyles = {
  [VARIANTS.PRIMARY]: css`
    color: var(--dui-color-red-500);
    &::before {
      border-color: var(--dui-color-red-500);
    }
    &::after {
      border-color: var(--dui-color-red-600);
    }
  `,
  [VARIANTS.SUCCESS]: css`
    color: var(--dui-color-green-400);
    &::before {
      outline-color: var(--dui-color-green-500);
    }
    &::after {
      border-color: var(--dui-color-green-500);
    }
    & > svg {
      color: var(--dui-color-green-500);
    }
  `,
};
const typeStyles = {
  [TYPES.RING]: css`
    &::before,
    &::after {
      border-left-color: transparent;
    }
  `,
  [TYPES.RING_DUAL]: css`
    &::before,
    &::after {
      border-left-color: transparent;
      border-right-color: transparent;
    }
  `,
  [TYPES.RING_DONE]: css`
    &::before,
    &::after {
      animation: none;
    }
    padding: ${({ size }) => dimensions[size].padding};
  `,
};

// # region Component Styles
const StyledLoader = styled.div`
  border-color: var(--dui-color-gray-200);
  display: flex;
  /* size styles */
  height: ${({ size }) => dimensions[size].width};
  justify-content: center;
  outline: 1px solid var(--dui-color-gray-500);
  outline-offset: calc(-1 * ${({ size }) => dimensions[size].borderWidth});
  position: relative;
  width: ${({ size }) => dimensions[size].width};

  /* borders */
  &,
  &::before,
  &::after {
    border-radius: 50%;
    border-style: solid;
    border-width: ${({ size }) => dimensions[size].borderWidth};
    box-sizing: border-box;
  }

  &::before,
  &::after {
    animation: ${animations.spin}
      calc(${({ $speed }) => getAnimationSpeed($speed)} / 3.2) linear infinite;
    content: "";
    position: absolute;
  }

  &::before {
    height: inherit;
    left: calc(-1 * ${({ size }) => dimensions[size].borderWidth});
    top: calc(-1 * ${({ size }) => dimensions[size].borderWidth});
    width: inherit;
  }

  &::after {
    border-style: solid;
    border-width: 2px;
    height: calc(100% + 1px);
    left: 0;
    top: 0;
    width: calc(100% + 1px);
  }

  /* Variant (Color styles) */
  ${({ variant }) => variantStyles[variant]}

  /* Type (Ring) styles */
  ${({ $type }) => typeStyles[$type]}
`;

const StyledIcon = styled(IconCheckmark)`
  width: 100%;
`;

const Loader = ({ id, className, dataTestid, size, type, variant }) => (
  <StyledLoader
    id={id}
    className={className}
    dataTestid={dataTestid}
    size={size}
    $type={type}
    variant={variant}
    $speed="5x"
    role="status"
  >
    {type === TYPES.RING_DONE && <StyledIcon />}
  </StyledLoader>
);

assignLoaderConstants(Loader);

Loader.propTypes = {
  /** An **optional** id prop for the Loader component  */
  id: PropTypes.string,

  /** An **optional** classname prop for the Loader component */
  className: PropTypes.string,

  /** An **optional** test-id prop used to target the Loader component for testing */
  dataTestid: PropTypes.string,

  /** An **optional** size prop for the Loader component */
  size: PropTypes.oneOf([Loader.SM, Loader.LG, Loader.XL]),

  /** An **optional** type prop used to set the type of the Loader used */
  type: PropTypes.oneOf([Loader.RING, Loader.RING_DUAL, Loader.RING_DONE]),

  /** An **optional** variant prop for the Loader component */
  variant: PropTypes.oneOf([Loader.PRIMARY, Loader.SUCCESS]),
};

Loader.defaultProps = {
  size: Loader.SM,
  type: Loader.RING,
  variant: Loader.PRIMARY,
};

export default Loader;
