// 9fbef606107a605d69c0edbcd8029e5d
/* eslint-disable react/no-array-index-key */

import React, { useRef } from "react";
import styled from "styled-components";
import { WEIGHTS as TEXT_WEIGHTS } from "components/atoms/Text/constants";
import { getViewport } from "styles/theme";
import PropTypes from "prop-types";
import useCollapse from "./shared/useCollapse";
import { Link, Popper } from "../../atoms";
import {
  NavigationList,
  NavigationLink,
  NavigationDropdown,
  NavActions,
} from "./shared";
import { FONT_WEIGHTS, FONT_SIZES } from "../../atoms/Link/constants";
import NavigationListContainer from "./shared/NavigationListContainer";

const SecondaryNavContainer = styled.nav`
  display: none;
  margin-top: 0;
  @media screen and (min-width: 768px) {
    background: linear-gradient(
      90deg,
      var(--dui-color-yellow-500),
      var(--dui-color-yellow-500) 48%,
      var(--dui-color-yellow-400) 70%,
      var(--dui-color-yellow-300)
    );
    position: relative;
    display: flex;
    flex-flow: row wrap;
  }
`;

const SecondaryNav = styled.div`
  display: flex;
  width: 100%;
  @media screen and (min-width: ${getViewport("md")}) {
    margin-top: var(--dui-size-space-3x);
    background: var(--dui-color-white-500);
  }
`;

const SecondaryNavigationList = styled(NavigationList)`
  @media screen and (min-width: ${getViewport("md")}) {
    margin-bottom: 0;
    min-height: calc(2 * var(--dui-size-space-10x));
    background-color: var(--dui-color-gray-50);
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    max-width: 100%;
  }
`;

const SecondaryNavigationListContainer = styled(NavigationListContainer)`
  background-color: var(--dui-color-gray-50);
  padding-right: calc(2 * var(--dui-size-space-10x));
`;

const SecondaryNavHeadlineContainer = styled.div`
  @media screen and (min-width: ${getViewport("md")}) {
    transform: translateY(calc(-1 * var(--dui-size-space-3x)));
    flex-shrink: 0;
    border-top-right-radius: 2px;
    background-color: var(--dui-color-white-500);
  }
`;

const SecondaryHeaderLink = styled(Link)`
  display: block;
  line-height: var(--dui-size-space-10x);
  margin: var(--dui-size-space-7x) var(--dui-size-space-10x) 0
    calc(2 * var(--dui-size-space-10x));
  text-decoration: none;
`;

const SecondaryNavigation = ({
  secondaryNavigationItems,
  secondaryHeadline,
  navActions,
}) => {
  const navListRef = useRef(null);
  const [showMore, showLess, setShowMore, setShowLess] = useCollapse(
    navListRef
  );

  const moreBtnHandler = () => {
    setShowMore(false);
    setShowLess(true);
  };

  const lessBtnHandler = () => {
    setShowMore(true);
    setShowLess(false);
  };

  return (
    <SecondaryNavContainer>
      <SecondaryNav>
        <SecondaryNavHeadlineContainer>
          <SecondaryHeaderLink
            size={FONT_SIZES.LG}
            href={secondaryHeadline.href}
            weight={TEXT_WEIGHTS[300]}
          >
            {secondaryHeadline.copy}
          </SecondaryHeaderLink>
        </SecondaryNavHeadlineContainer>
        <SecondaryNavigationListContainer>
          <SecondaryNavigationList ref={navListRef}>
            {secondaryNavigationItems.map((menuItem, index) => {
              if (menuItem.popover) {
                return (
                  <Popper
                    key={`${menuItem.headline}-${index}`}
                    content={menuItem.popover}
                  >
                    <NavigationDropdown
                      label={menuItem.headline}
                      href={menuItem.href}
                      size={FONT_SIZES.SM}
                      weight={FONT_WEIGHTS[400]}
                    />
                  </Popper>
                );
              }
              return (
                <NavigationLink
                  href={menuItem.href}
                  key={`${menuItem.headline}-${index}`}
                  external={menuItem.external}
                  label={menuItem.headline}
                  weight={FONT_WEIGHTS[400]}
                  size={FONT_SIZES.SM}
                />
              );
            })}
            <NavActions
              show={showLess}
              text={navActions.lessText}
              onClick={lessBtnHandler}
            />
          </SecondaryNavigationList>
          <NavActions
            show={showMore}
            text={navActions.moreText}
            onClick={moreBtnHandler}
          />
        </SecondaryNavigationListContainer>
      </SecondaryNav>
    </SecondaryNavContainer>
  );
};

export default SecondaryNavigation;

SecondaryNavigation.propTypes = {
  /** This prop controls the Copy Text for the More and the Less buttons when navigation items grows beyond the container width. */
  navActions: PropTypes.shape({
    moreText: PropTypes.string,
    lessText: PropTypes.string,
  }),
  /** This prop if passed will display and manage the configuration of secondary navigation headline */
  secondaryHeadline: PropTypes.shape({
    /** Headline text */
    copy: PropTypes.string,
    /** Headline Redirection Url */
    href: PropTypes.string,
    /** Headline Title */
    title: PropTypes.string,
    /** if headline should open in current window or new window */
    external: PropTypes.bool,
  }),
  /** This prop if passed will display and manage configuration of each secondary navigation item regarding the headline, title, redirection link, the popover component etc. It accepts an object of navigationItems */
  secondaryNavigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      /** redirection link */
      href: PropTypes.string,
      /** if link should open in current window or new window */
      external: PropTypes.bool,
      /** Title of the link */
      title: PropTypes.string,
      /** Link text */
      headline: PropTypes.string,
    })
  ),
};

SecondaryNavigation.defaultProps = {
  navActions: {
    moreText: "More",
    lessText: "Less",
  },
};
