// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";

import { WEIGHTS, SIZES } from "components/atoms/Text/constants";
import NavLink from "./NavLink";
import ListItem from "./ListItem";

const NavigationLink = ({ label, weight, href, external, size }) => {
  return (
    <ListItem>
      <NavLink
        size={size}
        href={href}
        weight={weight}
        target={external ? "_blank" : "_self"}
      >
        {label}
      </NavLink>
    </ListItem>
  );
};

export default NavigationLink;

NavigationLink.propTypes = {
  /** navigation Link size */
  size: PropTypes.oneOf(Object.values(SIZES)),
  /** navigation Link text */
  label: PropTypes.string.isRequired,
  /** navigation Link font */
  weight: PropTypes.oneOf(Object.values(WEIGHTS)),
  /** navigation Link redirect url */
  href: PropTypes.string,
  /** if redirection is in the same window or the new window */
  external: PropTypes.bool,
};
