// 9fbef606107a605d69c0edbcd8029e5d

import { keyframes } from "styled-components";
// TODO: check after Feb 2021 how many places we have used this
// if we only used in loaders, then move it to Loader Component
export default {
  spin: keyframes`
    100% {
      transform: rotate(360deg);
    }
  `,
  appearFromSide: keyframes`
    from {
      transform: translateX(20px) rotateZ(0.5turn);
      opacity: 0;
    }
    
    to {
      transform: translateY(0) rotateZ(0);
      opacity: 1;
    }
  `,
  appearFromTop: keyframes`
    from {
      transform: scale(0.5) translateY(-20px);
      opacity: 0;
    }
    
    to {
      width: auto;
      transform: scale(1) translateY(0);
      opacity: 1;
    }
`,
  disappearFromBottom: keyframes`
    to {
      transform: scale(0.5) translateY(-20px);
      opacity: 0;
    }

    from {
      width: auto;
      transform: scale(1) translateY(0);
      opacity: 1;
    }
`,
};
