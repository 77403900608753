import styled, { css } from "styled-components";
import { LABEL_TYPES } from "components/atoms/FormField/Input/constants";
import PropTypes from "prop-types";

const Label = styled.label`
  ${({ shouldHide, variantType, isRequired }) => {
    const isStatic = variantType === LABEL_TYPES.STATIC;

    return css`
      ${isRequired &&
      css`
        &:after {
          color: var(--dui-color-red-500);
          content: " *";
        }
      `};
      box-sizing: border-box;
      color: var(--dui-color-gray-600);
      font-family: var(--dui-font-family);
      font-size: var(--dui-size-font-2xs);
      left: 0;
      line-height: 1.15;
      opacity: ${shouldHide ? 0 : 1};
      overflow: hidden;
      padding-left: var(--dui-size-space-7x);
      padding-right: var(--dui-size-space-16x);
      padding-top: var(--dui-size-space-4x);
      pointer-events: none;
      position: absolute;
      text-overflow: ellipsis;
      top: 0;

      /* There is an offset in the animated label becase we animate it up when the user focus on the input field */
      transform: translateY(${isStatic ? 0 : css`var(--dui-size-space-2x)`});

      transition: color var(--dui-duration-3x), transform var(--dui-duration-3x),
        font-size var(--dui-duration-3x), opacity var(--dui-duration-3x);
      white-space: nowrap;
      width: 100%;
    `;
  }}
`;

Label.defaultProps = {
  isInvalid: false,
  shouldHide: false,
  variantType: LABEL_TYPES.STATIC,
  isRequired: false,
};

Label.propTypes = {
  /** An **optional** prop to flag the Label component as invalid state when validation fails */
  isInvalid: PropTypes.bool,
  /** An **optional** prop to flag the Label component to set its variant behaviour */
  variantType: PropTypes.oneOf([LABEL_TYPES.ANIMATED, LABEL_TYPES.STATIC]),
  /** An **optional** prop to flag the Label component to append * after the label text */
  isRequired: PropTypes.bool,
  /** An **optional** prop to flag the Label component to specify if its visible by default */
  shouldHide: PropTypes.bool,
};

export default Label;
