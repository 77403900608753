/* eslint-disable react/jsx-props-no-spreading */
// 9fbef606107a605d69c0edbcd8029e5d

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { IconCancel } from "assets/icons";
import { keyCodes } from "utils/keyCodes";
import { Transition } from "react-transition-group";
import { usePopover } from "./usePopover";
import { IconWrapper } from "../../atoms";
import { TRIGGERS, assignControlsConstants } from "./constants";

const Container = styled.div``;

const CloseButton = styled.button`
  background-color: var(--dui-color-white-500);
  border: none;
  cursor: pointer;
  display: inline-flex;
  padding: 0;
`;

const POPOVER_TRANSITIONS = {
  exited: css`
    opacity: 0;
  `,
  entering: css`
    opacity: 0;
  `,
  entered: css`
    opacity: 1;
  `,
  exiting: css`
    opacity: 0;
  `,
};

const PopoverContainer = styled.div.attrs({ tabIndex: -1 })`
  background-color: var(--dui-color-white-500);
  border: 1px solid var(--dui-color-gray-300);
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.1), 0 8px 18px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.1);
  color: var(--dui-color-black-500);
  display: flex;
  flex-direction: column;
  max-width: 250px;
  z-index: 9999;
  transition: opacity var(--dui-duration-3x) ease-in-out;
  font-size: 14px;
  padding: 14px;

  ${({ transitionState }) => POPOVER_TRANSITIONS[transitionState]}

  &[data-popper-placement*="bottom"] .tooltip-arrow {
    left: 0;
    margin-top: -6px;
    top: 0;
  }

  &[data-popper-placement*="bottom"] .tooltip-arrow::before {
    border-color: transparent transparent var(--dui-color-gray-300) transparent;
    border-width: 0 7px 7px 7px;
    position: absolute;
    top: -1px;
    left: -2px;
  }

  &[data-popper-placement*="bottom"] .tooltip-arrow::after {
    border-color: transparent transparent var(--dui-color-white-500) transparent;
    border-width: 0 7px 7px 7px;
    left: -2px;
  }

  &[data-popper-placement*="top"] .tooltip-arrow {
    bottom: 0;
    left: 0;
    margin-bottom: -10px;
  }

  &[data-popper-placement*="top"] .tooltip-arrow::before {
    border-color: var(--dui-color-gray-300) transparent transparent transparent;
    border-width: 7px 7px 0 7px;
    position: absolute;
    left: -2px;
  }

  &[data-popper-placement*="top"] .tooltip-arrow::after {
    border-color: var(--dui-color-white-500) transparent transparent transparent;
    border-width: 7px 7px 0 7px;
    position: absolute;
    left: -2px;
    margin-top: -1px;
  }

  &[data-popper-placement*="right"] .tooltip-arrow {
    left: 0;
    margin-left: -10px;
  }

  &[data-popper-placement*="right"] .tooltip-arrow::before {
    border-color: transparent var(--dui-color-gray-300) transparent transparent;
    border-width: 7px 7px 7px 3px;
    position: absolute;
    top: -2px;
  }

  &[data-popper-placement*="right"] .tooltip-arrow::after {
    border-color: transparent var(--dui-color-white-500) transparent transparent;
    border-width: 7px 7px 7px 0;
    left: 4px;
    top: -2px;
  }

  &[data-popper-placement*="left"] .tooltip-arrow {
    margin-right: -9px;
    right: 0;
  }

  &[data-popper-placement*="left"] .tooltip-arrow::before {
    border-color: transparent transparent transparent var(--dui-color-gray-300);
    border-width: 7px 0 7px 7px;
    position: absolute;
    top: -2px;
    left: 1px;
  }

  &[data-popper-placement*="left"] .tooltip-arrow::after {
    border-color: transparent transparent transparent var(--dui-color-white-500);
    border-width: 7px 0 7px 7px;
    position: absolute;
    top: -2px;
    left: 0px;
  }
`;

const PopoverArrow = styled.div`
  height: 10px;
  pointer-events: none;
  position: absolute;
  width: 10px;
  &::before {
    border-style: solid;
    content: "";
    display: block;
    height: 0;
    margin: auto;
    width: 0;
  }
  &::after {
    border-style: solid;
    content: "";
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
  }
`;

const TriggerContainer = styled.div.attrs({ tabIndex: 0, role: "button" })`
  cursor: pointer;
  display: inline-flex;
  &:focus {
    outline: solid 1px var(--dui-color-black-500);
  }
`;

const PopoverContent = styled.div``;

const PopoverHeading = styled.div`
  font-weight: 700;
  margin-right: 28px;
`;

const PopoverHeader = styled.div`
  display: flex;
  justify-content: ${({ heading }) => (heading ? "space-between" : "flex-end")};
  padding-bottom: 7px;
`;

const Popover = ({
  id,
  className,
  dataTestid,
  trigger,
  placement,
  closeOnOutsideClick,
  offset,
  popperOptions,
  triggerEl,
  children,
  heading,
  visible: visibleProp,
  showCloseButton,
  popoverTriggerAriaLabel,
  closeButtonAriaLabel,
}) => {
  const nodeRef = useRef(null);
  const [shown, setShown] = useState(visibleProp);
  const [triggerInteracted, setTriggerInteracted] = useState(false);
  const {
    visible,
    setTriggerRef,
    setTooltipRef,
    getArrowProps,
    getTooltipProps,
  } = usePopover(
    triggerInteracted,
    {
      trigger,
      placement,
      closeOnOutsideClick,
      offset,
      popperOptions,
      triggerEl,
      children,
      onVisibleChange: setShown,
      visible: shown,
    },
    popperOptions
  );

  const handleClick = () => {
    setTriggerInteracted(true);
    setShown(!visible);
  };
  const handleMouseOver = () => {
    setShown(true);
  };
  const handleMouseOut = () => {
    setShown(false);
  };
  const handleFocus = () => {
    setShown(true);
  };
  const handleBlur = () => {
    setShown(false);
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === keyCodes.RETURN || e.keyCode === keyCodes.SPACE) {
      setShown(!visible);
      setTriggerInteracted(true);
    }
  };

  const role = { click: "dialog", hover: "tooltip" };
  const hasHeaderContent = heading || showCloseButton;

  return (
    <Container id={id} className={className} data-testid={dataTestid}>
      <TriggerContainer
        aria-label={popoverTriggerAriaLabel}
        aria-expanded={visible}
        aria-controls={`${id}-container`}
        ref={setTriggerRef}
        id={`${id}-trigger`}
        data-testid={dataTestid && `${dataTestid}-trigger`}
        onKeyDown={handleKeyDown}
        onClick={trigger === TRIGGERS.CLICK ? handleClick : undefined}
        onMouseOver={trigger === TRIGGERS.HOVER ? handleMouseOver : undefined}
        onMouseOut={trigger === TRIGGERS.HOVER ? handleMouseOut : undefined}
        onFocus={trigger === TRIGGERS.HOVER ? handleFocus : undefined}
        onBlur={trigger === TRIGGERS.HOVER ? handleBlur : undefined}
      >
        {triggerEl}
      </TriggerContainer>
      <Transition in={shown} timeout={250} unmountOnExit nodeRef={nodeRef}>
        {(state) => (
          <PopoverContainer
            role={role[trigger]}
            id={`${id}-container`}
            data-testid={dataTestid && `${dataTestid}-container`}
            transitionState={state}
            ref={setTooltipRef}
            aria-live="assertive"
            aria-describedby={`${id}-content`}
            {...getTooltipProps({ className: "tooltip-container" })}
          >
            {hasHeaderContent && (
              <PopoverHeader heading={heading}>
                {heading && <PopoverHeading>{heading}</PopoverHeading>}
                {showCloseButton && (
                  <CloseButton
                    className="close-btn"
                    data-testid={dataTestid && `${dataTestid}-close-btn`}
                    aria-label={closeButtonAriaLabel}
                    onClick={() => setShown(false)}
                  >
                    <IconWrapper>
                      <IconCancel aria-hidden role="presentation" />
                    </IconWrapper>
                  </CloseButton>
                )}
              </PopoverHeader>
            )}
            <PopoverArrow {...getArrowProps({ className: "tooltip-arrow" })} />
            <PopoverContent
              id={`${id}-content`}
              data-testid={dataTestid && `${dataTestid}-content`}
            >
              {children}
            </PopoverContent>
          </PopoverContainer>
        )}
      </Transition>
    </Container>
  );
};
assignControlsConstants(Popover);

export default Popover;

Popover.defaultProps = {
  id: "popover",
  dataTestid: "popover-cy",
  trigger: Popover.CLICK,
  placement: Popover.BOTTOM,
  offset: [0, 10],
  visible: false,
  showCloseButton: true,
  closeOnOutsideClick: true,
};

Popover.propTypes = {
  /** An **optional** prop that helps configure the Id property to the root element **defaults to 'popover'** */
  id: PropTypes.string,
  /** An **optional** prop that controls the class to the root element, this could be used to add specific css tyles */
  className: PropTypes.string,
  /** An **optional** prop test id attached to the component as a data-testid attribute. */
  dataTestid: PropTypes.string,
  /** An **optional** prop that controls if the tooltip could be closed when clicked outside. If true, closes the tooltip when user clicks outside the trigger element. */
  closeOnOutsideClick: PropTypes.bool,
  /** An **optional** prop that controls how the popover is triggered i.e. via a 'click' or a 'hover', defaults to 'click'. Use null if you want to disable all events. It's useful in cases when you control the state of the tooltip. */
  trigger: PropTypes.oneOf([Popover.CLICK, Popover.HOVER]),
  /** An **optional** prop controls where the tooltip is placed reltive to the trigger element, defaults to 'auto' */
  placement: PropTypes.oneOf([
    Popover.AUTO,
    Popover.AUTO_START,
    Popover.AUTO_END,
    Popover.TOP,
    Popover.TOP_START,
    Popover.TOP_END,
    Popover.BOTTOM,
    Popover.BOTTOM_START,
    Popover.BOTTOM_END,
    Popover.RIGHT,
    Popover.RIGHT_START,
    Popover.RIGHT_END,
    Popover.LEFT,
    Popover.LEFT_START,
    Popover.LEFT_END,
  ]),
  /** An **optional** prop is a shorthand for popperOptions.modifiers offset modifier option. Defaults to a value of [0, 10] means the tooltip will be placed 10px away from the trigger element (to reserve enough space for the arrow element). */
  offset: PropTypes.arrayOf(PropTypes.number),
  /** An **optional** prop options passed directly to the underlying usePopper hook. See https://popper.js.org/docs/v2/constructors/#options.
  Keep in mind, if you set placement or any modifiers here, it replaces offset and placement options above. They won't be merged into the final object. You have to add offset modifier along with others here to make it work. */
  // eslint-disable-next-line react/forbid-prop-types
  popperOptions: PropTypes.object,
  /** element that triggers the popover */
  /** A **required** prop defining the triggger Element for the popover it could be a simple button or any html/react element */
  triggerEl: PropTypes.element.isRequired,
  /** controls the visibility of the popover */
  /** An **optional** prop that controls the visibility of the popover */
  visible: PropTypes.bool,
  /** A **required** prop where you could define the contents of the popover */
  children: PropTypes.element.isRequired,
  /** An **optional** prop that controls visiblity of the close button on the popover body */
  showCloseButton: PropTypes.bool,
  /** An **optional** prop that controls the aria label for the popover trigger element */
  popoverTriggerAriaLabel: PropTypes.string,
  /** An **optional** prop that controls the aria label for the close button */
  closeButtonAriaLabel: PropTypes.string,
  /** An **optional** prop that controls the Popover heading */
  heading: PropTypes.string,
};
