// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  WEIGHTS as TEXT_WEIGHTS,
  SIZES as TEXT_SIZES,
} from "components/atoms/Text/constants";
import Text from "../../atoms/Text";
import { NavLink, ListItem } from "./shared";

const LanguageLink = styled(NavLink)`
  margin-left: var(--dui-size-space-3x);
`;

const LanguageSelector = ({ languages }) => {
  return (
    <ListItem>
      {languages.map(({ copy, href, external, isSelected }) => {
        return isSelected ? (
          <Text key={copy} size={TEXT_SIZES.XS} weight={TEXT_WEIGHTS[700]}>
            {copy}
          </Text>
        ) : (
          <LanguageLink
            key={copy}
            size={TEXT_SIZES.XS}
            href={href}
            target={external ? "_blank" : "_self"}
          >
            {copy}
          </LanguageLink>
        );
      })}
    </ListItem>
  );
};

export default LanguageSelector;

LanguageSelector.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      /** selected language bool */
      isSelected: PropTypes.bool,
      /** language redirection link */
      href: PropTypes.string,
      /** if the window should open externally or internally */
      external: PropTypes.bool,
      /** language title */
      title: PropTypes.string,
      /** language text */
      copy: PropTypes.string,
    })
  ),
};
