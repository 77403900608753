// 9fbef606107a605d69c0edbcd8029e5d
/* eslint-disable no-param-reassign */
import { css } from "styled-components";

// Keep old mappings too
const FONTS = {
  tiny: "xs",
  small: "sm",
  smallplus: "sm",
  regular: "md",
  regularplus: "lg",
  large: "xl",
  big: "2xl",
  xbig: "5xl",
  xxbig: "8xl",
  huge: "11xl",
};
const VIEWPORTS = {
  small: "sm",
  medium: "md",
  large: "lg",
  xlarge: "xl",
  xxlarge: "2xl",
  xxxlarge: "3xl",
};
const VIEWPORT_VALUES = {
  xs: "0",
  sm: "20rem",
  md: "48rem",
  lg: "64rem",
  xl: "85.3125rem",
  "2xl": "120rem",
  "3xl": "160rem",
};
const ANIMATIONS = {
  fast: "2x",
  regular: "3x",
  slow: "4x",
  slower: "5x",
};
const UNITS = {
  small: "3x",
  regular: "7x",
  large: "10x",
};

export const getFontFamily = () => `var(--dui-font-family)`;

export const getRadius = () => `var(--dui-size-radius-md)`;

export const getErrorColor = () => `var(--dui-color-red-500)`;

export const getWebColor = (variant) => `var(--dui-color-${variant}-500)`;

export const getPrimaryColor = (variant) => {
  variant = String(variant) === "300" ? "400" : variant;
  return `var(--dui-color-red-${variant})`;
};

export const getSecondaryColor = (variant) => {
  variant = String(variant) === "200" ? "300" : variant;
  return `var(--dui-color-yellow-${variant})`;
};

export const getGrayColor = (variant) => {
  variant = String(variant) === "800" ? "700" : variant;
  return `var(--dui-color-gray-${variant})`;
};

export const getWarningColor = (variant) => getSecondaryColor(variant);

export const getSuccessColor = (variant) => {
  variant = String(variant) === "300" ? "400" : variant;
  return `var(--dui-color-green-${variant})`;
};

export const getFontColor = (variant = "default") =>
  variant === "default"
    ? `var(--dui-color-black-400)`
    : `var(--dui-color-white-500)`;

export const getFontSize = (variant = "md") => {
  if (Object.keys(FONTS).includes(variant)) {
    variant = FONTS[variant];
  }
  return `var(--dui-size-font-${variant})`;
};

export const getUnit = (variant) => {
  if (Object.keys(UNITS).includes(variant)) {
    variant = UNITS[variant];
  }
  return `var(--dui-size-space-${variant})`;
};

export const getAnimationSpeed = (variant) => {
  if (Object.keys(ANIMATIONS).includes(variant)) {
    variant = ANIMATIONS[variant];
  }
  return `var(--dui-duration-${variant})`;
};

export const getViewport = (variant) => {
  if (Object.keys(VIEWPORTS).includes(variant)) {
    variant = VIEWPORTS[variant];
  }
  // return `var(--dui-size-breakpoint-${variant})`;
  return VIEWPORT_VALUES[variant];
};

export const getMediaQuery = (viewport) => {
  return (...style) => css`
    @media (min-width: ${getViewport(viewport)}) {
      ${css(...style)}
    }
  `;
};

/**
 * returns a single value from a given (value or an array)
 * - when value is provided it just returns the same value
 * - when array, index is provided
 *   - and index is greater than max length it returns the last item
 *   - and index is lesser than max length it returns the item at index
 * @param {Array<number|string> || <number|string>} valueOrArray
 * @returns {Array<number|string>}
 */
export const getResponsiveValue = (valueOrArray, index = 0) => {
  // when valueOrArray is not an array
  let responsiveValue = valueOrArray;

  if (Array.isArray(valueOrArray)) {
    const len = valueOrArray.length;
    // when index is greater than length
    // use the last value in the array
    if (index >= len) responsiveValue = valueOrArray[len - 1];
    // else use the given index value
    else responsiveValue = valueOrArray[index];
  }

  return responsiveValue;
};

/**
 * Returns media queries for given viewport
 * @param {Array<string>} viewports
 * @param {Object<any>} styles
 * @returns InterpolationTemplateString
 *
 * Usage:
 * getMediaQueries({
 *  color: 'black',
 *  fontSize: ['28px', '30px', '34px'],
 *  lineHeight: ['30px', '32px']
 * })
 *
 * Results in css {
 *   color: 'black';
 *   font-size : 28px;
 *   line-height: 30px;
 *   @media (min-width: 768px){
 *     font-size: 30px;
 *     line-height: 32px;
 *   }
 *   @media (min-width: 1024px){
 *     font-size: 34px;
 *   }
 * }
 *
 */
export const getMediaQueries = (
  styles,
  viewports = ["sm", "md", "lg", "xl", "2xl", "3xl"]
) => {
  viewports = viewports.map((viewport) => {
    if (Object.keys(VIEWPORTS).includes(viewport)) {
      return VIEWPORTS[viewport];
    }
    return viewport;
  });
  const FIRST_VIEWPORT = "sm";

  const styleEntries = Object.entries(styles);
  // for each viewport - generate styles
  const generatedStyles = viewports
    .map((aViewport, vpIndex) => {
      // and for each style, get responsive value
      const vpStyles = styleEntries.reduce(
        (acc, [cssProperty, valueOrArray]) => {
          /**
           * This if condition make sure
           * to avoid duplicate property values for higher viewports
           *
           * Eg: when we get styles object as {color: ['black', 'red']}
           * means we should define color only for small and medium viewports
           * and shouldn't have `color:red` for higher viewports
           */
          if (
            aViewport === FIRST_VIEWPORT ||
            (Array.isArray(valueOrArray) &&
              valueOrArray.length > 1 &&
              valueOrArray.length > vpIndex)
          ) {
            acc[cssProperty] = getResponsiveValue(valueOrArray, vpIndex);
          }
          return acc;
        },
        {}
      );

      const hasStyles = !!Object.keys(vpStyles).length;

      if (hasStyles) {
        return aViewport === FIRST_VIEWPORT
          ? // for small viewports keep them as default styles
            css(vpStyles)
          : // for larger than small viewports, set media queries
            css`
              @media (min-width: ${getViewport(aViewport)}) {
                ${css(vpStyles)}
              }
            `;
      }
      return undefined;
    })
    .filter((item) => !!item);

  return generatedStyles;
};

export const getPrintMediaQuery = () => {
  return (...style) => css`
    @media print {
      ${css(...style)}
    }
  `;
};
