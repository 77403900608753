// 9fbef606107a605d69c0edbcd8029e5d

import { VALID, INVALID, NOTE, SM, MD } from "../../../../_commons/constants";

export const VALIDATION_TYPES = {
  VALID,
  INVALID,
  NOTE,
};

export const SIZES = {
  SM,
  MD,
};
