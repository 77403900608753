// 9fbef606107a605d69c0edbcd8029e5d

import { IconCalendar } from "assets/icons";
import styled from "styled-components";
import { isBrowser } from "utils/deviceUtils";

// NOT token values - distinguished by SPACE instead of SPACING
const SPACE18X = "36px";

export const displaySmallDay = isBrowser
  ? window.matchMedia("(max-width: 400px)").matches
  : false;
export const DAY_WIDTH = `${
  displaySmallDay ? SPACE18X : "var(--dui-size-space-24x)"
}`;
export const DAY_MARGIN = "3px";

/**
 * This will be used to layout anything in 7 columns
 * Eg: Calendar's Title, Weeks and Days use this layout
 */
export const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: calc(7 * ${DAY_WIDTH});
`;

export const Container = styled.div`
  display: ${({ isBlock }) => (isBlock ? "block" : "inline-block")};
  position: relative;
`;

export const Abbr = styled.abbr`
  border: none;
  font-weight: 200;
  text-decoration: none;
`;

export const Icon = styled(IconCalendar)`
  cursor: pointer;
`;
export const CalendarTable = styled.table`
  border-spacing: var(--dui-size-space-0);
  padding: var(--dui-size-space-0);
`;
export const CalendarRow = styled.tr`
  font-size: var(--dui-size-font-xs);
  line-height: var(--dui-size-space-10x);
  text-align: center;
  text-transform: uppercase;
`;

export const CalendarHead = styled.thead`
  color: var(--dui-color-gray-600);
`;

export const CalendarBody = styled.tbody`
  color: var(--dui-color-black-500);
`;

export const CalendarCell = styled.td`
  height: var(--dui-size-space-24x);
  padding: var(--dui-size-space-0);
  width: ${DAY_WIDTH};
`;
