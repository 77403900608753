export const keyCodes = {
  RETURN: 13,
  SPACE: 32,
  ESC: 27,
  TAB: 9,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  PLUS: 107,
  MINUS: 109,
};

export const keys = {
  RETURN: "Enter",
  SPACE: "Space",
  ESC: "Escape",
  TAB: "Tab",
  END: "End",
  HOME: "Home",
  LEFT: "ArrowLeft",
  UP: "ArrowUp",
  RIGHT: "ArrowRight",
  DOWN: "ArrowDown",
};
