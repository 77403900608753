// 9fbef606107a605d69c0edbcd8029e5d

import PropTypes from "prop-types";
import React, { memo } from "react";
import styled, { css } from "styled-components";
import { DAY_VARIANTS } from "./constants";
import { DAY_MARGIN, DAY_WIDTH } from "./Styles";

const FONT_FAMILY = "Delivery, Verdana, sans-serif";

const edgeStyles = css`
  background-color: var(--dui-color-gray-200);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
`;

const hoverStyles = css`
  &:hover {
    border-radius: var(--dui-size-radius-md);
    &::before,
    &::after {
      background-color: var(--dui-color-gray-200);
    }
  }
`;

const selectedStyles = css`
  background-color: var(--dui-color-red-500);
  color: var(--dui-color-white-500);

  &:focus,
  &:hover,
  &:active {
    box-shadow: 0 0 0 2px white, 0 0 0 3px var(--dui-color-black-500);
  }
`;

const variants = {
  [DAY_VARIANTS.TODAY]: css`
    background-color: var(--dui-color-black-500);
    color: var(--dui-color-white-500);
  `,
  [DAY_VARIANTS.SELECTED]: selectedStyles,
  [DAY_VARIANTS.START]: css`
    ${selectedStyles}
    ${hoverStyles}
  `,
  [DAY_VARIANTS.END]: css`
    ${selectedStyles}
    ${hoverStyles}
  `,
  [DAY_VARIANTS.BETWEEN]: css`
    background-color: var(--dui-color-gray-200);
    border-radius: 0;
    &::before {
      ${edgeStyles}
      left: -${DAY_MARGIN};
      width: calc(100% + (2 * ${DAY_MARGIN}));
    }
    ${hoverStyles}
    &:focus,
    &:hover,
    &:active {
      border-radius: var(--dui-size-radius-md);
    }
  `,
};

const StyledDay = styled.button`
  background-color: white;
  border: 0;
  border-radius: var(--dui-size-radius-md);
  box-sizing: border-box;
  color: var(--dui-color-black-500);
  cursor: pointer;

  font-family: ${FONT_FAMILY};
  font-size: var(--dui-size-font-md);
  font-weight: normal;
  height: calc(${DAY_WIDTH} - (2 * ${DAY_MARGIN}));
  margin: ${DAY_MARGIN};
  outline: none;
  padding: 0;
  position: relative;
  width: calc(${DAY_WIDTH} - (2 * ${DAY_MARGIN}));

  &:focus,
  &:hover {
    box-shadow: 0 0 0 2px white, 0 0 0 3px var(--dui-color-black-500);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;

    &:hover {
      box-shadow: none;
    }
  }

  ${({ variant }) => variants[variant]}
`;

export const Day = memo(
  ({
    day,
    disabled,
    variant,
    onClick,
    onMouseEnter,
    onFocus,
    dataTestid,
    ariaLabel,
  }) => (
    <StyledDay
      data-testid={dataTestid}
      disabled={disabled}
      variant={variant}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onFocus={onFocus}
      type="button"
      role="button"
      aria-label={ariaLabel}
      aria-pressed={variant === DAY_VARIANTS.SELECTED ? "true" : undefined}
      aria-current={variant === DAY_VARIANTS.TODAY ? "date" : undefined}
      aria-selected={variant === DAY_VARIANTS.SELECTED ? "true" : undefined}
    >
      {day}
    </StyledDay>
  )
);

Day.displayName = "Day";

Day.propTypes = {
  dataTestid: PropTypes.string,
  ariaLabel: PropTypes.string,
  /** Day text that will be displayed */
  day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** What is the variant (today, ) */
  variant: PropTypes.oneOf(Object.keys(variants)),
  disabled: PropTypes.bool,
  /** Onclick handler */
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onFocus: PropTypes.func,
};

Day.defaultProps = {};
