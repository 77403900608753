// 9fbef606107a605d69c0edbcd8029e5d

import { IconDoubleChevronForward, IconDoubleChevronBack } from "assets/icons";
import PropTypes from "prop-types";
import React from "react";
import { TYPES } from "../constants";
import { NavIconButton } from "./NavIconButton";

export const Nav = ({
  id,
  type,
  onChangeMonth,
  onChangeYear,
  show,
  disableMonth,
  disableYear,
  ariaLabelForMonth,
  ariaLabelForYear,
}) => {
  const monthId = `${id}-${type}-month`;
  const yearId = `${id}-${type}-year`;

  return show ? (
    <React.Fragment>
      {type === TYPES.NAV.PREV ? (
        <React.Fragment>
          <NavIconButton
            onClick={onChangeYear}
            iconComponent={<IconDoubleChevronForward />}
            ariaLabel={ariaLabelForYear}
            rotate="180deg"
            id={yearId}
            disabled={disableYear}
          />
          <NavIconButton
            onClick={onChangeMonth}
            iconComponent={<IconDoubleChevronBack />}
            ariaLabel={ariaLabelForMonth}
            id={monthId}
            disabled={disableMonth}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <NavIconButton
            onClick={onChangeMonth}
            iconComponent={<IconDoubleChevronForward />}
            ariaLabel={ariaLabelForMonth}
            id={monthId}
            disabled={disableMonth}
          />
          <NavIconButton
            onClick={onChangeYear}
            iconComponent={<IconDoubleChevronForward />}
            ariaLabel={ariaLabelForYear}
            rotate="360deg"
            id={yearId}
            disabled={disableYear}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  ) : (
    ""
  );
};

Nav.propTypes = {
  id: PropTypes.string,
  /** Which direction the chevron should show */
  type: PropTypes.oneOf(Object.values(TYPES.NAV)),
  /** Callback or click handler for this nav item */
  onChangeMonth: PropTypes.func,
  onChangeYear: PropTypes.func,
  /** Should we show or hide this icon button? */
  show: PropTypes.bool,
  disableMonth: PropTypes.bool,
  disableYear: PropTypes.bool,
  ariaLabelForMonth: PropTypes.string,
  ariaLabelForYear: PropTypes.string,
};
