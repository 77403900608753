// 9fbef606107a605d69c0edbcd8029e5d

/**
 * Since window is not available in SSR we should use it carefully
 * like having an if condition around the block in which we use window
 *
 * No need to check this condition if we are using window in React Hooks like useEffect
 */

// Check if server or client is rendering
export const isBrowser = typeof window !== "undefined";

export const isMobileOrTablet = () =>
  isBrowser && window.matchMedia("only screen and (max-width: 768px)").matches;

export const hasInputDateFeature = () => {
  const input = isBrowser && document.createElement("input");
  const value = "a";
  input.setAttribute("type", "date");
  input.setAttribute("value", value);
  return input.value !== value;
};

export const shouldUseNativeDatePicker =
  isMobileOrTablet() && hasInputDateFeature();
