// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import CONSTANTS from "_commons/constants";
import { VARIANTS, BORDER_COLORS, MOBILE_VARIANTS } from "./constants";

// region Component Token?
const TABLE_BREAKPOINT = "600px";

const HEADER_FOOTER_BORDER_COLOR = {
  red: "var(--dui-color-red-500)",
  black: "var(--dui-color-black-500)",
  grey: "var(--dui-color-gray-400)",
};

const getHeaderFooterBorderColor = ({ borderColor }) =>
  (borderColor && HEADER_FOOTER_BORDER_COLOR[borderColor]) ||
  HEADER_FOOTER_BORDER_COLOR.grey;

export const TableCaption = styled.caption`
  border-bottom: solid 4px ${(props) => getHeaderFooterBorderColor(props)};
  color: var(--dui-color-black-500);
  font-size: var(--dui-size-font-3xl);
  font-weight: var(--dui-font-weight-bold);
  margin-bottom: var(--dui-size-space-4x);
  padding: var(--dui-size-space-12x) var(--dui-size-space-4x)
    var(--dui-size-space-4x);
  text-align: left;
`;

TableCaption.displayName = "TableCaption";

export const TableHeadCell = styled.th`
  border-bottom: solid 1px var(--dui-color-gray-300);
  display: block;
  font-size: var(--dui-size-font-md);
  font-weight: var(--dui-font-weight-bold);
  padding: var(--dui-size-space-7x) var(--dui-size-space-4x);
  text-align: ${({ align }) => align};
  @media screen and (min-width: ${TABLE_BREAKPOINT}) {
    display: table-cell;
    ${({ verticalAlign }) =>
      verticalAlign &&
      css`
        vertical-align: ${verticalAlign};
      `}
  }
`;

TableHeadCell.displayName = "TableHeadCell";

export const TableHead = styled.thead`
  display: none;
  @media screen and (min-width: ${TABLE_BREAKPOINT}) {
    width: 100%;
    display: table-header-group;
  }
`;

TableHead.displayName = "TableHead";

export const TableBody = styled.tbody`
  & tr:last-child {
    border: none;
  }
`;

TableBody.displayName = "TableBody";

export const TableCell = styled.td`
  &:before {
    content: attr(data-label);
    font-weight: var(--dui-font-weight-bold);
    min-width: 120px;
    width: 120px;
    @media screen and (min-width: ${TABLE_BREAKPOINT}) {
      display: none;
      content: "";
    }
  }
  border-bottom: solid 1px var(--dui-color-gray-300);
  display: flex;
  justify-content: space-between;

  ${({ mobileVariant }) =>
    mobileVariant === MOBILE_VARIANTS.VERTICAL &&
    css`
      flex-direction: column;
    `}

  padding: var(--dui-size-space-7x) var(--dui-size-space-4x);

  text-align: ${({ align }) => align};

  &:last-child {
    border: none;
  }

  @media screen and (min-width: ${TABLE_BREAKPOINT}) {
    border-bottom: none;
    display: table-cell;
    ${({ verticalAlign }) =>
      verticalAlign &&
      css`
        vertical-align: ${verticalAlign};
      `}
  }
`;

TableCell.displayName = "TableCell";

export const TableRow = styled.tr`
  border-bottom: none;
  display: block;
  font-size: var(--dui-size-font-md);
  padding: var(--dui-size-space-7x) var(--dui-size-space-4x);

  ${({ variant }) =>
    variant &&
    variant === VARIANTS.STRIPED &&
    css`
      &:nth-child(even) {
        background-color: var(--dui-color-gray-50);
      }
    `}

  @media screen and (min-width: ${TABLE_BREAKPOINT}) {
    display: table-row;

    ${({ variant }) =>
      (!variant || variant !== VARIANTS.STRIPED) &&
      css`
        border-bottom: solid 1px var(--dui-color-gray-300);
      `}
  }
`;

TableRow.displayName = "TableRow";

export const StyledTable = styled.table`
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  table-layout: fixed;
  width: 100%;
`;

StyledTable.displayName = "Table";

export const TableFooter = styled.tfoot`
  border-top: solid 4px ${(props) => getHeaderFooterBorderColor(props)};
  color: var(--dui-color-gray-600);
  display: table-footer-group;
  font-size: var(--dui-size-font-sm);
  margin-bottom: var(--dui-size-space-12x);
  & td {
    border: none;
    padding-left: 0;

    & :last-child {
      font-weight: var(--dui-font-weight-normal);
    }
  }

  & tr:last-child {
    border: none;
  }
`;

TableFooter.displayName = "TableFooter";

const SmartTable = React.forwardRef(
  (
    {
      id,
      className,
      dataTestid,
      variant,
      footerText,
      caption,
      columns,
      rowData,
      borderColor,
      mobileVariant,
      footerTextAlign,
    },
    forwardedRef
  ) => {
    return (
      <StyledTable
        id={id}
        variant={variant}
        className={className}
        data-testid={dataTestid}
        ref={forwardedRef}
      >
        <TableCaption borderColor={borderColor}>{caption}</TableCaption>
        <TableHead>
          <TableRow>
            <TableHeadCell />
            {columns
              .filter((column) => column.selector !== "title")
              .map((column) => (
                <TableHeadCell
                  align={column.align}
                  scope="col"
                  key={column.name}
                >
                  {column.name}
                </TableHeadCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.map((item) => (
            <TableRow variant={variant} key={item.id}>
              {columns
                .filter((col) => col.selector === "title")
                .map((column) => (
                  <TableHeadCell
                    align={item.align}
                    verticalAlign={item.verticalAlign}
                    scope="row"
                    key={column.selector}
                  >
                    {item[column.selector]}
                  </TableHeadCell>
                ))}
              {columns
                .filter((col) => col.selector !== "title")
                .map((column) => (
                  <TableCell
                    align={item.align}
                    verticalAlign={item.verticalAlign}
                    key={column.selector}
                    data-label={column.name}
                    mobileVariant={mobileVariant}
                  >
                    {item[column.selector]}
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
        {footerText && (
          <TableFooter borderColor={borderColor}>
            <TableRow>
              <TableCell colSpan={columns.length} align={footerTextAlign}>
                {footerText}
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </StyledTable>
    );
  }
);

SmartTable.displayName = "SmartTable";

SmartTable.propTypes = {
  /** Id to the root element */
  id: PropTypes.string,

  /** Border color for the table caption and footer */
  borderColor: PropTypes.oneOf(Object.values(BORDER_COLORS)),

  /** Class to the root element */
  className: PropTypes.string,

  /** Text for the table caption */
  caption: PropTypes.string.isRequired,

  /** Text for the footer */
  footerText: PropTypes.string,

  /** Text for the footer */
  footerTextAlign: PropTypes.oneOf(["left", "center", "right"]),

  /** The test id attached to the component as a data-testid attribute. */
  dataTestid: PropTypes.string,

  /** What's the variant of the component based on `VARIANTS` constant */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),

  /** What's the variant of the component based on `MOBILE_VARIANTS` constant */
  mobileVariant: PropTypes.oneOf(Object.values(MOBILE_VARIANTS)),

  /** columns data shown on the table in the given format
   * align property of the column is optional and if not specified defaults to left
   * verticalAlign property of the column is optional and if not specified defaults to middle
   * Example
  [
  {
    name: "Title",
    selector: "title",
  },
  { name: "Director", selector: "director", align: "left", verticalAlign: "top" },
  { name: "Year", selector: "year", align: "left", verticalAlign: "top" },
  { name: "Runtime", selector: "runtime", align: "left", verticalAlign: "top" },
  ] */

  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      selector: PropTypes.string.isRequired,
      align: PropTypes.oneOf([
        CONSTANTS.LEFT,
        CONSTANTS.CENTER,
        CONSTANTS.RIGHT,
      ]),
      verticalAlign: PropTypes.oneOf([
        CONSTANTS.TOP,
        CONSTANTS.MIDDLE,
        CONSTANTS.LEFT,
      ]),
    })
  ).isRequired,

  /** rows data shown on the table 
   * row data must be an object with column selector as keys
   * Example
  [{
    id: 1,
    title: "Beetlejuice",
    align: "left",
    verticalAlign: "top",
    year: "1988",
    runtime: "92",
    genres: ["Comedy", "Fantasy"],
    director: "Tim Burton",
    actors: "Alec Baldwin, Geena Davis, Annie McEnroe, Maurice Page"
  }], */
  rowData: PropTypes.arrayOf(PropTypes.shape(PropTypes.object.isRequired))
    .isRequired,
};

SmartTable.defaultProps = {
  variant: VARIANTS.REGULAR,
};

export default SmartTable;
