// 9fbef606107a605d69c0edbcd8029e5d
import PropTypes from "prop-types";
import React from "react";
import { Abbr, CalendarCell, CalendarHead, CalendarRow } from "./Styles";

export const Week = ({ labels, namespace }) => (
  <CalendarHead>
    <CalendarRow>
      {labels.map((aWeek) => (
        <CalendarCell
          as="th"
          scope="col"
          title={aWeek.full}
          key={`${namespace}-${aWeek.abbr}`}
        >
          <Abbr title={aWeek.full}>{aWeek.abbr}</Abbr>
        </CalendarCell>
      ))}
    </CalendarRow>
  </CalendarHead>
);

Week.propTypes = {
  /** Array of labels for week */
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      abbr: PropTypes.string,
      full: PropTypes.string,
    })
  ),
  /** Name space to have a unique key for these labels */
  namespace: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
