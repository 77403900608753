// 9fbef606107a605d69c0edbcd8029e5d

import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import { IconSearch } from "assets/icons";
import { concatIfSet } from "utils/arrayUtils";
import Label from "components/atoms/FormField/Label";
import Placeholder from "components/atoms/FormField/Placeholder";
import {
  assignInputConstants,
  LABEL_TYPES,
  VALIDATION_TYPES,
} from "./constants";
import {
  inputFieldBorderStyles,
  StyledIconWrapper,
  disabledStyles,
} from "./styles";

import ValidationFeedback, {
  getValidationMessageId,
} from "../../ValidationFeedback";

const BORDER_BOX_SHADOW = "0 0 0 1px #000000";
// #region Component Styles

const Container = styled.div`
  display: ${({ isBlock }) => (isBlock ? "block" : "inline-block")};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  position: relative;
  width: ${({ isBlock }) => (isBlock ? "100%" : "278px")};
`;

const Input = styled.input.attrs({
  noValidate: true,
})`
  ${({
    isShowingAnyIcon,
    hasLabel,
    value,
    shouldHidePlaceholder,
    searchIcon,
    disabled,
  }) => {
    return css`
      ${inputFieldBorderStyles};

      font-family: var(--dui-font-family);
      font-size: var(--dui-size-font-md);
      height: var(--dui-size-space-24x);
      outline: 0;

      padding: ${hasLabel && !searchIcon
        ? css`
            var(--dui-size-space-10x) var(--dui-size-space-6x) var(--dui-size-space-4x) var(--dui-size-space-6x)
          `
        : css`
            var(--dui-size-space-7x) var(--dui-size-space-6x)
          `};
      padding-left: ${searchIcon ? css`var(--dui-size-space-16x)` : undefined};
      padding-right: ${isShowingAnyIcon
        ? css`var(--dui-size-space-16x)`
        : undefined};
      width: 100%;

      ::placeholder {
        opacity: ${shouldHidePlaceholder ? 0 : 1};
        color: var(--dui-color-gray-400);
        font-size: var(--dui-size-font-md);
      }

      ${value &&
      css`
        ~ ${Label} {
          opacity: 1;
          transform: translateY(0);
        }

        ~ ${Placeholder} {
          opacity: 0;
        }
      `};

      &:hover {
        border-color: var(--dui-color-black-500);
      }

      &:focus {
        border-color: var(--dui-color-black-500);
        box-shadow: ${BORDER_BOX_SHADOW};

        ~ ${Label} {
          opacity: 1;
          transform: translateY(0);
        }

        ~ ${Placeholder} {
          opacity: 0;
          transform: translateY(-8px);
        }
      }

      ${disabled &&
      css`
        ${disabledStyles.container}

        ~ ${Label},
          ~ ${Placeholder},
          ~ i,
          ::placeholder {
          ${disabledStyles.items}
        }
      `};
      // TODO - To be changed when calendar component is redesigned
      &[type="date"] {
        background: none;
        height: 56px;
        padding-right: var(--dui-size-space-4x);
        /* here we need to have in pixels */
        position: relative;
        &::-webkit-calendar-picker-indicator {
          border: solid 1px transparent;
          padding: var(--dui-size-space-4x);
          position: absolute;
          right: var(--dui-size-space-4x);
          top: 20%;
          &:focus {
            border-color: var(--dui-color-black-400);
            border-radius: var(--dui-size-radius-md);
          }
        }
      }
    `;
  }}
`;

// #endregion

export const shouldShowIcon = (icon, isValid, isInvalid) =>
  // when the right icon exists AND there is no competing validation icon to display
  !!icon && !isValid && !isInvalid;

const InputField = forwardRef(
  (
    {
      className,
      id,
      dataTestid,
      /**
       * Code smell:
       *
       * The default export for `InputField` already supports the `ref` attribute and the `forwardedRef`
       * is just an implementation detail that the consumer doesn't need to know as she can use the
       * standard `ref`. Unfortunately I had to export the `default` and also use a `named` export because
       * by default `storybook` is having problems to display the `propTypes` table of a `React.forwardedRef` component.
       *
       * To make it clear I called the `named` export as `StorybookOnlyInputField` as it's only used by `storybook`.
       * */
      // eslint-disable-next-line react/prop-types
      isBlock,
      onBlur,
      onChange,
      type,
      validation,
      value,
      variant,
      rightIcon,
      ariaLabel,
      ariaDescribedBy,
      ariaHaspopup,
      ariaExpanded,
      required,
      name,
      disabled,
      ...autoCompleteProps
    },
    ref
  ) => {
    const { type: variantType, placeholder, label } = variant;
    const hasLabel = Boolean(label);
    const isAnimated = variantType === LABEL_TYPES.ANIMATED;
    const isInvalid = validation?.type === VALIDATION_TYPES.INVALID;
    const isValid = validation?.type === VALIDATION_TYPES.VALID;

    const showRightIcon = shouldShowIcon(rightIcon, isValid, isInvalid);
    const { searchIcon } = autoCompleteProps;

    const isShowingAnyIcon = showRightIcon || isValid || isInvalid;

    const combinedAriaDescribedBy = concatIfSet([
      ariaDescribedBy,
      validation?.message && getValidationMessageId(id),
    ]);

    return (
      <Container className={className} isBlock={isBlock} disabled={disabled}>
        <Input
          aria-describedby={combinedAriaDescribedBy}
          aria-expanded={ariaExpanded}
          aria-haspopup={ariaHaspopup}
          aria-label={ariaLabel}
          data-testid={dataTestid}
          hasLabel={hasLabel}
          searchIcon={searchIcon}
          id={id}
          isBlock={isBlock}
          isInvalid={isInvalid}
          isShowingAnyIcon={isShowingAnyIcon}
          isValid={isValid}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          ref={ref}
          required={required}
          type={type}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          // We hide (opacity: 0) the DOM placeholder for animated placeholder as we rely
          // on a `span` element to act as a placeholder for the animation. The reason
          // we still want to placeholder attribute here is because screen readers will announce it
          // properly.
          shouldHidePlaceholder={variantType === InputField.ANIMATED}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...autoCompleteProps}
        />

        {/* Label should not be visible if input is a search field */}
        {!autoCompleteProps.searchIcon && label && (
          <Label
            id={`${id}-label`}
            isInvalid={isInvalid}
            isRequired={required}
            shouldHide={isAnimated && Boolean(placeholder)}
            variantType={variantType}
            htmlFor={id}
          >
            {label}
          </Label>
        )}

        {showRightIcon && (
          <StyledIconWrapper block>{rightIcon}</StyledIconWrapper>
        )}

        {isAnimated && placeholder && (
          <Placeholder
            /* This placeholder is aria-hidden because the "real" input field's placeholder is what
             * we want the screen readers to read. This `span` element is here only for styling purposes as
             * it floats around when the users focuses on the input field.
             *
             * E.g.:
             * 1. Users set an `animated` or `static` placeholder as "My cool placeholder"
             * 2. Underlying rendered DOM will be <input type="text" placeholder="My cool placeholder" />
             * 3. For animated placeholder, we will also render this `span` element here that looks visually as
             * a placeholder but it animates when the user focuses on the `input` field. Therefore we set it to
             * `aria-hidden` as the real announced placeholder will be already the `placeholder` attribute from `input`.
             * 4. For static placeholder we rely only on the input's placeholder attribute so it is all fine.
             */
            aria-hidden
            hasRightIcon={isShowingAnyIcon}
            hasLeftIcon={searchIcon}
            isRequired={required}
          >
            {placeholder}
          </Placeholder>
        )}

        {searchIcon && (
          <StyledIconWrapper position="left">
            <IconSearch />
          </StyledIconWrapper>
        )}

        {validation && (
          <ValidationFeedback validation={validation} fieldId={id} />
        )}
      </Container>
    );
  }
);

assignInputConstants(InputField);

InputField.propTypes = {
  /** A **required** id prop for the Input component  */
  id: PropTypes.string.isRequired,
  /** An **optional** classname prop for the Input component */
  className: PropTypes.string,
  /** An **optional** test-id prop used to target the Input component for testing */
  dataTestid: PropTypes.string,
  /** An **optional** prop flag to control if the Input is rendered as block or inline-block element */
  isBlock: PropTypes.bool,
  /** An **optional** onBlur callback handler */
  onBlur: PropTypes.func,
  /** An **optional** onChange callback handler */
  onChange: PropTypes.func,
  /** An **optional** prop describing the type of the Input, e.g. text or password input */
  type: PropTypes.oneOf([
    InputField.EMAIL,
    InputField.NUMBER,
    InputField.NUMERIC,
    InputField.PASSWORD,
    InputField.TELEPHONE,
    InputField.TEXT,
    InputField.DATE,
  ]),
  /** An **optional** Input validation prop
   *
   * **Required Fields** `type`
   */
  validation: PropTypes.shape({
    /** Validation message */
    message: PropTypes.string,
    /** Validation type */
    type: PropTypes.oneOf([
      InputField.VALID,
      InputField.INVALID,
      InputField.NOTE,
    ]),
  }),
  /** An **optional** prop holding the current input value */
  value: PropTypes.string,
  /** An **optional** Input variant prop - follows the same structure as InputField
   *
   * **Required Fields** `type`
   */
  variant: PropTypes.shape({
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf([InputField.ANIMATED, InputField.STATIC]).isRequired,
  }),
  /** An **optional** Icon prop to display to the right of the textfield, e.g. `<InputField rightIcon={<Icon/>} />` */
  rightIcon: PropTypes.element,
  /** An **optional** prop flag to define if the Input is disabled */
  disabled: PropTypes.bool,
  /** An **optional** prop flag to define if the search icon is shown next to input */
  searchIcon: PropTypes.bool,
  /** An **optional** prop defining the text read by the screen reader to represent the Input; use this if you need different text to be read from label */
  ariaLabel: PropTypes.string,
  /** An **optional** prop defining the list of reference ids (separated by space), recommended when you want to show some error message on your field */
  ariaDescribedBy: PropTypes.string,
  /** An **optional** prop that lets the screen reader know that this input has a popup */
  ariaHaspopup: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** An **optional** prop that lets the screen reader know that this input has a popup which is expanded or not - **optional** */
  ariaExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** An **optional** prop to flag the Input component as required within a form context */
  required: PropTypes.bool,
  /** An **optional** name prop for the Input component */
  name: PropTypes.string,
};

InputField.defaultProps = {
  value: "",
  isBlock: false,
  onBlur: Function.prototype,
  onChange: Function.prototype,
  type: InputField.TEXT,
  variant: {
    type: InputField.STATIC,
  },
  disabled: false,
  searchIcon: false,
};

InputField.displayName = "InputField";

export const StorybookOnlyInputField = InputField;

export default InputField;
