// 9fbef606107a605d69c0edbcd8029e5d

/**
 * This file contains the styles for MultiSelect
 */
import styled, { css, keyframes } from "styled-components";
import { IconCancel, IconChevronBack } from "assets/icons";
import Placeholder from "components/atoms/FormField/Placeholder";
import Label from "components/atoms/FormField/Label";
import { SIZES } from "./constants";

const SPACING28X = "3.5rem";
const SPACING124X = "15.5rem";
const SPACING137X = "17.12rem";

const DISABLED_COLOR = "#545454";
const ACTIVE_ITEM = "#f3f3f3";
const SELECTED_ITEM = "#e8e8e8";

const CHIP_CONTAINER_WIDTH = 52;

const blink = keyframes`
  from, to {
    background: transparent;
  }
  50% {
    background: var(--dui-color-black-500);
  }
`;

export const getChipWidth = (text) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = "0.875rem Delivery";
  return context.measureText(text).width + CHIP_CONTAINER_WIDTH;
};

export const Container = styled.div`
  height: ${({ size }) =>
    size === SIZES.SM ? `var(--dui-size-space-24x)` : SPACING28X};
  position: relative;
  width: ${({ isBlock }) => (isBlock ? "100%" : SPACING137X)};

  &[data-disabled="true"] {
    color: ${DISABLED_COLOR};
    opacity: 0.4;
  }
`;

export const ContentContainer = styled.div`
  align-items: center;
  background: var(--dui-color-white-500);
  border: var(--dui-size-space-1) solid
    ${({ isInvalid, isValid }) => {
      if (isInvalid) {
        return `var(--dui-color-red-500)`;
      }
      if (isValid) {
        return `var(--dui-color-green-400)`;
      }
      return `var(--dui-color-gray-500)`;
    }};
  border-radius: var(--dui-size-radius-md);
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: space-between;
  outline: none;
  padding: 0 var(--dui-size-space-8x);
  position: relative;
  width: 100%;

  :focus:not([data-open="true"]) {
    border: var(--dui-size-space-1x) solid
      ${css`rgba(0, 0, 0, ${(props) => (props["data-disabled"] ? 0.4 : 1)})`};
    padding: 0 calc(var(--dui-size-space-8x) - var(--dui-size-space-1));
  }

  &[data-open="true"] {
    border: var(--dui-size-space-1x) solid
      ${css`rgba(0, 0, 0, ${(props) => (props["data-disabled"] ? 0.4 : 1)})`};
    border-bottom-color: transparent;
    border-end-end-radius: 0;
    border-end-start-radius: 0;
    padding: 0 calc(var(--dui-size-space-8x) - var(--dui-size-space-1));
  }
`;

export const ChipContainer = styled.div`
  align-items: center;
  border: var(--dui-size-space-1) solid var(--dui-color-gray-500);
  border-radius: var(--dui-size-radius-md);
  display: flex;
  font-size: var(--dui-size-font-sm);
  line-height: var(--dui-size-space-8x);
  padding: var(--dui-size-space-4x) var(--dui-size-space-5x);

  &.hidden {
    visibility: hidden;
  }
`;

export const ChipLabel = styled.span`
  margin-right: var(--dui-size-space-5x);
  max-width: ${SPACING124X};
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
`;

export const ChipIcon = styled(IconCancel).attrs({
  "aria-hidden": true,
  role: "presentation",
})`
  height: var(--dui-size-space-6x);
  width: var(--dui-size-space-6x);
  cursor: pointer;
  &[data-disabled="true"] {
    pointer-events: none;
    cursor: default;
  }
`;

export const ChipCount = styled.div`
  align-items: center;
  display: flex;
  font-size: var(--dui-size-font-md);
  padding: 0 var(--dui-size-space-4x);
`;

export const ChipsContainerStyled = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: space-between;
  overflow: hidden;
`;

export const ChipsContainerWrapper = styled.div`
  overflow: hidden;
  & > div {
    margin-right: var(--dui-size-space-4x);
  }

  &.flex {
    display: flex;
    flex: 1 1 0;
  }
`;

export const ArrowContainer = styled.div`
  align-items: center;
  background: var(--dui-color-white-500);
  border-color: transparent;
  box-sizing: border-box;
  display: flex;
  height: var(--dui-size-space-12x);
  pointer-events: none;
  width: var(--dui-size-space-12x);
`;

export const ArrowIcon = styled(IconChevronBack).attrs({
  "aria-hidden": true,
  role: "presentation",
})`
  height: var(--dui-size-space-10x);
  transform: ${({ open }) => (open ? css`rotate(90deg)` : css`rotate(-90deg)`)};
  width: var(--dui-size-space-10x);
`;

export const StyledLabel = styled(Label)`
  left: 0;
  padding: 0;
  position: relative;
  top: 0;
`;

export const StyledPlaceholder = styled(Placeholder)`
  ${({ itemSelected }) =>
    itemSelected
      ? css`
          color: var(--dui-color-black-500);
        `
      : null}
  left: 0;
  line-height: 1.2;
  padding: 0;
  position: relative;
  top: 0;
`;

export const HiddenInput = styled.input.attrs({
  noValidate: true,
  tabIndex: "-1",
  "aria-hidden": true,
})`
  appearance: none;
  height: 0;
  width: 0;
  border: 0;
  opacity: 0;
  position: absolute;
`;

export const LabelPlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ListItemBackground = styled.div`
  background: var(--dui-color-black-400);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
`;

export const OptionListItem = styled.li`
  appearance: none;
  box-sizing: border-box;
  height: var(--dui-size-space-24x);
  line-height: 1;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  width: 100%;

  &.active {
    background: ${ACTIVE_ITEM} !important;
  }

  &[aria-selected="true"] {
    background: ${SELECTED_ITEM}
      ${(props) => (props["aria-disabled"] ? "!important" : null)};
  }
`;

export const Options = styled.ul.attrs({
  tabIndex: "-1",
  role: "listbox",
})`
  list-style-type: none;
  margin: 0;
  max-height: 13.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
`;

export const FilterInput = styled.input.attrs({
  type: "text",
})`
  appearance: none;
  border: none;
  caret-color: transparent;
  height: var(--dui-size-space-24x);
  flex: 1 1 0;
  padding: 0 var(--dui-size-space-8x);
  &:focus-visible {
    outline: none;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const Blink = styled.div`
  animation: 1s ${blink} step-end infinite;
  height: var(--dui-size-space-8x);
  left: var(--dui-size-space-7x);
  position: absolute;
  top: var(--dui-size-space-8x);
  width: var(--dui-size-space-1);
`;

export const ValidationMessageContainer = styled.div`
  margin-top: var(--dui-size-space-4x);
`;
