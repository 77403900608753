// 9fbef606107a605d69c0edbcd8029e5d

import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import styled from "styled-components";
import { chunk, range } from "utils/arrayUtils";
import { getToday, getWeekDays } from "utils/dateUtils";
import { WEEK } from "../constants";
import { DayCell } from "./DayCell";
import {
  CalendarBody,
  CalendarCell,
  CalendarRow,
  CalendarTable,
} from "./Styles";
import { Title } from "./Title";
import { Week } from "./Week";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--dui-size-space-6x);
`;

/**
 * Calendar component is used to render only single Calendar
 * for a given month
 */
export const Calendar = forwardRef(
  (
    {
      id,
      ariaLabelForNextMonth,
      ariaLabelForNextYear,
      ariaLabelForPrevMonth,
      ariaLabelForPrevYear,
      dataTestid,
      firstDayOfTheWeek,
      showNext,
      showPrev,
      calDate,
      hoveredDate,
      selectedDate,
      selectedDates,
      onSelect,
      onHover,
      onChangeMonth,
      onChangeYear,
      className,
      locale,
      minDate,
      maxDate,
    },
    ref
  ) => {
    const weekLabels = getWeekDays(locale, firstDayOfTheWeek);
    // to create unique keys
    const namespace = `${calDate.year()}-${calDate.month()}`;
    // to highlight the today's date
    const todayDate = getToday();

    /**
     * to generate the gap at the beginning of the each month
     * Eg: If 1st starts on Wed and your week is starting from Mon
     * then you should leave a gap of 2 cells.
     * Following will calculate and introduce that gap
     * The rows will have array of "array of days"
     * the less than 1 represents an empty cell in the cal table
     *
     * Eg: for Dec 2020 month when week starts with Monday
     * [[0,1,2,3,4,5,6],[7,8,9,10,11,12,13],[14,15,16,17,18,19,20],[21,22,23,24,25,26,27],[28,29,30,31]]
     *
     * Eg for Nov 2020 month when week starts with Monday
     * [[-5,-4,-3,-2,-1,0,1],[2,3,4,5,6,7,8],[9,10,11,12,13,14,15],[16,17,18,19,20,21,22],[23,24,25,26,27,28,29],[30]]
     */
    //
    const firstDayOfTheMonth = calDate.clone().date(1);
    const diff = firstDayOfTheMonth.day() - firstDayOfTheWeek;
    const emptyDayCount = diff < 0 ? 7 + diff : diff;
    const rows = chunk(range(-emptyDayCount + 1, calDate.daysInMonth()), 7);

    return (
      <Container
        id={id}
        className={className}
        ref={ref}
        data-testid={dataTestid}
      >
        <Title
          dataTestid={`${dataTestid}-title`}
          ariaLabelForNextMonth={ariaLabelForNextMonth}
          ariaLabelForNextYear={ariaLabelForNextYear}
          ariaLabelForPrevMonth={ariaLabelForPrevMonth}
          ariaLabelForPrevYear={ariaLabelForPrevYear}
          locale={locale}
          showNext={showNext}
          showPrev={showPrev}
          calDate={calDate}
          minDate={minDate}
          maxDate={maxDate}
          changeMonth={onChangeMonth}
          changeYear={onChangeYear}
        />
        <CalendarTable
          data-testid={`${dataTestid}-table`}
          role="grid"
          aria-labelledby={`${dataTestid}-title-text`}
        >
          <Week labels={weekLabels} namespace={calDate.month()} />
          <CalendarBody>
            {rows.map((aRow) => (
              <CalendarRow key={`${namespace}-row-${aRow.toString()}`}>
                {aRow.map((day) =>
                  day > 0 ? (
                    <DayCell
                      key={`${namespace}-${day}`}
                      locale={locale}
                      dayObj={calDate.clone().date(day)}
                      todayDate={todayDate}
                      hoveredDate={hoveredDate}
                      selectedDate={selectedDate}
                      selectedDates={selectedDates}
                      maxDate={maxDate}
                      minDate={minDate}
                      onHover={onHover}
                      onSelect={onSelect}
                    />
                  ) : (
                    <CalendarCell key={`${namespace}-${day}`} />
                  )
                )}
              </CalendarRow>
            ))}
          </CalendarBody>
        </CalendarTable>
      </Container>
    );
  }
);
Calendar.displayName = "Calendar";
Calendar.defaultProps = {
  firstDayOfTheWeek: WEEK.MO,
  calDate: dayjs(),
  selectedDate: undefined,
  selectedDates: [],
  showPrev: true,
  showNext: true,
  onSelect: () => {},
  locale: "en",
  ariaLabelForNextMonth: "Next Month",
  ariaLabelForNextYear: "Next Year",
  ariaLabelForPrevMonth: "Previous Month",
  ariaLabelForPrevYear: "Previous Year",
  id: "pl-cal",
  dataTestid: "cal",
};

Calendar.propTypes = {
  id: PropTypes.string,
  dataTestid: PropTypes.string,
  className: PropTypes.string,
  /** Which day would you like to start the week? based on WEEK Prop */
  firstDayOfTheWeek: PropTypes.oneOf(Object.values(WEEK)),
  /** Should show next navigation button? */
  showNext: PropTypes.bool,
  /** Should show prev navigation button? */
  showPrev: PropTypes.bool,
  /** Which calendar month you want to start with */
  calDate: PropTypes.instanceOf(dayjs),
  /** What is the selected date you want to highlight */
  selectedDate: PropTypes.instanceOf(dayjs),
  /** When it is a range to be selected */
  selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
  /** What is the current hovered date by the user */
  hoveredDate: PropTypes.instanceOf(dayjs),
  /** on hovering a date callback */
  onHover: PropTypes.func,
  /** on selecting a date callback */
  onSelect: PropTypes.func,
  /** on clicking next or prev, you need to change the month */
  onChangeMonth: PropTypes.func,
  /** on clicking next or prev, you need to change the year */
  onChangeYear: PropTypes.func,
  /** locale for calendar. This will changes the week and month labels */
  locale: PropTypes.string,
  /** use this prop to disable the dates after minDate */
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(dayjs), PropTypes.string]),
  /** use this prop to disable the dates before maxDate */
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(dayjs), PropTypes.string]),
  /** aria label for next month button */
  ariaLabelForNextMonth: PropTypes.string,
  /** aria label for next year button */
  ariaLabelForNextYear: PropTypes.string,
  /** aria label for prev month button */
  ariaLabelForPrevMonth: PropTypes.string,
  /** aria label for prev year button */
  ariaLabelForPrevYear: PropTypes.string,
};
