// 9fbef606107a605d69c0edbcd8029e5d

// Never change this string, since native date picker
// always needs the date in this format
// check the displayFormat prop for users
import { SM, MD, LG, PRIMARY, SECONDARY } from "_commons/constants";

export const DATE_FORMAT = "YYYY-MM-DD";

export const WEEK = {
  SU: 0,
  MO: 1,
  TU: 2,
  WE: 3,
  TH: 4,
  FR: 5,
  SA: 6,
};

export const SIZES = {
  SM,
  MD,
  LG,
};

export const VARIANTS = {
  PRIMARY,
  SECONDARY,
};

export const TYPES = {
  NAV: {
    NEXT: "next",
    PREV: "prev",
  },
};
