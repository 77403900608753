// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { assignTextConstants, SIZES as fontSizes } from "./constants";

// #region Component Styles
const Container = styled.span`
  font-family: var(--dui-font-family);
  /* since the size is coming from theme context, we cannot move this to css variable */

  font-size: ${({ size }) => size};
  font-stretch: var(--stretch);
  font-style: var(--style);
  font-weight: var(--weight);
`;

// #endregion

// eslint-disable-next-line react/display-name
const Text = React.forwardRef(
  (
    {
      ariaAtomic,
      ariaHidden,
      ariaLabel,
      ariaLabelledby,
      ariaLive,
      children,
      className,
      dataTestid,
      fontStyle,
      id,
      isParagraph,
      role,
      size,
      stretch,
      title,
      weight,
      lang,
    },
    ref
  ) => {
    const ariaAttrs = ariaHidden
      ? { "aria-hidden": ariaHidden }
      : {
          role,
          "aria-atomic": ariaAtomic,
          "aria-label": ariaLabel,
          "aria-labelledby": ariaLabelledby,
          "aria-live": ariaLive,
          lang,
        };

    return (
      <Container
        as={isParagraph ? "p" : undefined}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...ariaAttrs}
        id={id}
        ref={ref}
        className={className}
        data-testid={dataTestid}
        size={size}
        style={{
          "--weight": weight,
          "--style": fontStyle,
          "--stretch": stretch,
        }}
        title={title}
      >
        {children}
      </Container>
    );
  }
);

assignTextConstants(Text);

Text.propTypes = {
  /** This is a **required** prop. Child content to render */
  children: PropTypes.node.isRequired,
  /** This is an **optional** prop. Class to the root element */
  className: PropTypes.string,
  /** This is an **optional** prop. The test id attached to the Text component as a data-testid attribute */
  dataTestid: PropTypes.string,
  /** This is an **optional** prop. If set to true, renders the Text component as a `<p>` tag. Otherwise, a `<span>` is used */
  isParagraph: PropTypes.bool,
  /** This is an **optional** prop setting the font size of the Text children */
  size: PropTypes.oneOf(Object.values(fontSizes)),
  /** This is an **optional** prop setting the font weight of the Text children */
  weight: PropTypes.oneOf([200, 400, 700, 800]),
  /** This is an **optional** prop setting the font style of the Text children */
  fontStyle: PropTypes.oneOf([Text.NORMAL, Text.ITALIC]),
  /** This is an **optional** prop setting the font stretch of the Text children */
  stretch: PropTypes.oneOf([Text.NORMAL, Text.CONDENSED]),
  /** This is an **optional** prop setting the title of the Text children */
  title: PropTypes.string,
  /** This is an **optional** prop. Defines the id of the root element */
  id: PropTypes.string,
  /** This is an **optional** prop. Defines the role of the root element */
  role: PropTypes.string,
  /** This is an **optional** prop used for accessible behavior. Setting aria hidden to true will hide the component from a screen reader */
  ariaHidden: PropTypes.bool,
  /** An **optional** prop used for accessible behavior, defining the text read by the screen reader to represent the Text; use this if you need different text to be read from label */
  ariaLabel: PropTypes.string,
  /** An **optional** prop used for accessible behavior. It is used to indicate that the component will be updated, and defines the type of update */
  ariaLive: PropTypes.string,
  /** An **optional** prop used for accessible behavior. Indicates whether assistive technologies will present all, or only parts of, the changed region based on the change notifications defined by the aria-relevant attribute */
  ariaAtomic: PropTypes.string,
  /**
   * An **optional** ariaLabelledby prop that establishes the relationships between objects and their label(s), and
   * its value should be one or more element IDs, which refer to elements that have the text needed for labeling.
   * List multiple element IDs in a space delimited fashion
   *
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-labelledby_attribute
   */
  ariaLabelledby: PropTypes.string,
  /** An **optional** prop used for accessible behavior. Used to set the language used in the Text component */
  lang: PropTypes.string,
};

Text.defaultProps = {
  isParagraph: false,
  size: Text.MD,
  weight: 400,
  fontStyle: Text.NORMAL,
  stretch: Text.NORMAL,
};

export default Text;
