// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import { ChipContainer, ChipLabel, ChipIcon } from "./styles";

const Chip = ({ children, disabled, onClick }) => (
  <ChipContainer onClick={(e) => e.stopPropagation()}>
    <ChipLabel>{children}</ChipLabel>
    <ChipIcon data-disabled={disabled} onClick={onClick} />
  </ChipContainer>
);

Chip.propTypes = {
  /** A **required**  prop holding content to be rendered in chip */
  children: PropTypes.node.isRequired,
  /** An **optional** prop flag to define if the Chip is disabled */
  disabled: PropTypes.bool,
  /** An **optional** onClick callback handler */
  onClick: PropTypes.func,
};

Chip.defaultProps = {
  disabled: false,
  onClick: Function.prototype,
};

export default Chip;
