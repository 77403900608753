// 9fbef606107a605d69c0edbcd8029e5d
import React from "react";
import { Flex } from "./Flex";
import { onlyGapAndDirectionRelated } from "./shared";
/**
 * React component used to horizontally and vertically center its child.
 * It uses the popular `display: flex` centering technique.
 *
 */
export const Center = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Flex {...props} align="center" justify="center" wrap="nowrap" />
);
Center.displayName = "Center";
Center.propTypes = onlyGapAndDirectionRelated.propTypes;
Center.defaultProps = onlyGapAndDirectionRelated.defaultProps;
