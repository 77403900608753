// 9fbef606107a605d69c0edbcd8029e5d
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import animations from "styles/animations";
import { getAnimationSpeed } from "styles/theme";
import {
  SIZES,
  VARIANTS,
  SPEED,
  assignIconWrapperConstants,
} from "./constants";

export * from "./constants";

const sizeStyles = {
  [SIZES.SM]: css`
    height: var(--dui-size-space-9x);
    width: var(--dui-size-space-9x);
  `,
  [SIZES.MD]: css`
    height: var(--dui-size-space-12x);
    width: var(--dui-size-space-12x);
  `,
  [SIZES.LG]: css`
    height: var(--dui-size-space-16x);
    width: var(--dui-size-space-16x);
  `,
  [SIZES.XL]: css`
    height: var(--dui-size-space-24x);
    width: var(--dui-size-space-24x);
  `,
};

const variantStyles = {
  [VARIANTS.ERROR]: css`
    color: var(--dui-color-red-400);
  `,
  [VARIANTS.INVERTED]: css`
    color: var(--dui-color-white-500);
  `,
  [VARIANTS.PRIMARY]: css`
    color: var(--dui-color-red-500);
  `,
  [VARIANTS.SECONDARY]: css`
    color: var(--dui-color-yellow-500);
  `,
  [VARIANTS.SUCCESS]: css`
    color: var(--dui-color-green-400);
  `,
  [VARIANTS.WARN]: css`
    color: var(--dui-color-yellow-500);
  `,
};

export const IconWrapper = styled.i.attrs(
  ({ ariaHidden = true, dataTestid }) => ({
    "aria-hidden": ariaHidden,
    role: "presentation",
    "data-testid": dataTestid,
  })
)`
  display: ${({ block }) => (block ? "block" : "inline-flex")};
  box-sizing: content-box;
  justify-content: center;
  align-items: center;
  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(${rotate});
    `}

  ${({ size }) => size && sizeStyles[size]}

  ${({ variant }) => variant && variantStyles[variant]}

  ${({ animate, speed }) =>
    animate &&
    css`
      animation: ${animations[animate]} ${getAnimationSpeed(speed)};
    `}

    & svg {
    flex: 1;
  }

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

assignIconWrapperConstants(IconWrapper);

IconWrapper.propTypes = {
  /** What is the display type? */
  block: PropTypes.bool,
  /** What's the size of the component based on `SIZE` constant */
  size: PropTypes.oneOf(Object.values(SIZES)),
  /** What's the variant of the component based on `VARIANTS` constant */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  /** What's the animation? */
  animate: PropTypes.oneOf(Object.keys(animations)),
  /** What's the animation speed? */
  speed: PropTypes.oneOf(Object.values(SPEED)),
  /** For screen readers when we want to skip this element make aria-hidden true */
  ariaHidden: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** What's the rotation value of the icon child */
  rotate: PropTypes.string,
  /** An **optional** test-id prop used to target the IconWrapper component for testing */
  dataTestid: PropTypes.string,
  /** An **optional** prop to change the color */
  color: PropTypes.string,
};

IconWrapper.defaultProps = {
  block: false,
  size: SIZES.SM,
  animate: undefined,
  speed: SPEED.REGULAR,
};

IconWrapper.displayName = "IconWrapper";

export default IconWrapper;
