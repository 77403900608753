// 9fbef606107a605d69c0edbcd8029e5d

/**
 * Returns the first key whose value is true
 * @param {Object} jsonObj
 * Eg: getKeyOfTruth({a: false, b: true, c: true}) // returns "b"
 */
export const getKeyOfTruth = (jsonObj) => {
  const item = Object.entries(jsonObj).find((o) => o[1]);
  return item && item[0];
};
