// 9fbef606107a605d69c0edbcd8029e5d
/* eslint-disable react/no-array-index-key */

import React, { useRef, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { getViewport, getMediaQueries } from "styles/theme";
import {
  SIZES as TEXT_SIZES,
  WEIGHTS as TEXT_WEIGHTS,
} from "components/atoms/Text/constants";
import { LogoDHL } from "assets/logos";
import useCollapse from "./shared/useCollapse";
import Popper from "../../atoms/Popper";
import {
  NavigationList,
  NavigationLink,
  NavigationDropdown,
  NavigationListContainer,
  NavActions,
  ListItem,
} from "./shared";
import { Search } from "./Search";
import MobileNavigation from "./MobileNavigation";
import LanguageSelector from "./LanguageSelector";
import CountrySelector from "./CountrySelector";

const Logo = styled(LogoDHL)`
  height: var(--dui-size-space-9x);
  max-width: 100%;
  width: auto;
  @media screen and (min-width: ${getViewport("md")}) {
    height: auto;
    width: 201px;
  }
`;

const PrimaryNavMetaContainer = styled.div`
  display: flex;
  height: 100%;
  @media screen and (min-width: ${getViewport("md")}) {
    width: 100%;
    padding: 0 calc(2 * var(--dui-size-space-10x)) var(--dui-size-font-sm)
      calc(2 * var(--dui-size-space-10x));
  }
`;

const BrandLink = styled.a`
  display: block;
  padding: var(--dui-size-space-10x);
  @media screen and (min-width: ${getViewport("md")}) {
    cursor: pointer;
    padding: 0;
    margin-right: var(--dui-size-space-10x);
  }
`;

const MetaContainer = styled.div`
  display: none;
  margin-left: auto;
  transform: translateY(-8px);
  @media screen and (min-width: ${getViewport("md")}) {
    display: flex;
    z-index: 1;
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
`;

const PrimaryNavigationListContainer = styled(NavigationListContainer)`
  padding: 0 calc(2 * var(--dui-size-space-10x)) 0 var(--dui-size-space-10x);
`;

const MetaNavigationList = styled(NavigationList)`
  justify-content: flex-end;
  & ${ListItem} {
    margin-bottom: var(--dui-size-space-3x);
    margin-top: 0;
  }
`;

const PrimaryNavigationList = styled(NavigationList)`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`;

const Container = styled.nav`
  background: linear-gradient(
    90deg,
    var(--dui-color-yellow-500),
    var(--dui-color-yellow-500) 48%,
    var(--dui-color-yellow-400) 70%,
    var(--dui-color-yellow-300)
  );
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
  height: calc(8 * var(--dui-size-space-3x));
  width: 100%;

  @media screen and (min-width: ${getViewport("md")}) {
    height: 100%;
    padding-top: calc(2 * var(--dui-size-space-7x));
    padding-bottom: 14px;
    position: relative;
  }
`;

const AppNameContainer = styled.div`
  color: var(--dui-color-red-500);
  flex-shrink: 0;
  font-size: 21px;
  transform: translateY(5px);
  ${getMediaQueries({
    display: ["none", "flex"],
  })}
`;

const PrimaryNavigation = ({
  primaryMetaItems,
  primaryNavigationItems,
  mobileNavigationItems,
  countryData,
  searchData,
  languageData,
  logo,
  navActions,
  appName,
}) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const onSearchClick = (e, open = true) => {
    setSearchOpen(open);
  };
  const navListRef = useRef(null);
  const [showMore, showLess, setShowMore, setShowLess] = useCollapse(
    navListRef
  );
  const moreBtnHandler = () => {
    setShowMore(false);
    setShowLess(true);
  };

  const lessBtnHandler = () => {
    setShowMore(true);
    setShowLess(false);
  };

  const keyPressMoreHandler = (e) => {
    if (e.key === "Enter") {
      moreBtnHandler();
    }
  };

  const keyPressLessHandler = (e) => {
    if (e.key === "Enter") {
      lessBtnHandler();
    }
  };

  return (
    <Container>
      <PrimaryNavMetaContainer>
        {logo && Object.keys(logo).length > 0 && (
          <BrandLink
            title={logo.title}
            href={logo.href}
            target={logo.external ? "_blank" : "_self"}
            alt={logo.alt}
          >
            <Logo role="presentation" aria-hidden="true" />
          </BrandLink>
        )}
        {appName && <AppNameContainer>{appName}</AppNameContainer>}
        <MetaContainer>
          <MetaNavigationList>
            {!searchOpen &&
              countryData &&
              Object.keys(countryData).length > 0 && (
                <CountrySelector countryData={countryData} />
              )}
            {!searchOpen && languageData && (
              <LanguageSelector languages={languageData} />
            )}
            {searchData && Object.keys(searchData).length > 0 && (
              <ListItem>
                <Search
                  open={searchOpen}
                  onSearchClick={onSearchClick}
                  searchData={searchData}
                />
              </ListItem>
            )}
          </MetaNavigationList>
          {primaryMetaItems?.length > 0 && (
            <MetaNavigationList>
              {primaryMetaItems.map(({ copy, href, external }) => {
                return (
                  !searchOpen && (
                    <NavigationLink
                      key={copy}
                      size={TEXT_SIZES.SM}
                      weight={TEXT_WEIGHTS[400]}
                      label={copy}
                      href={href}
                      external={external}
                    />
                  )
                );
              })}
            </MetaNavigationList>
          )}
        </MetaContainer>
      </PrimaryNavMetaContainer>
      {mobileNavigationItems && mobileNavigationItems.items?.length > 0 && (
        <MobileNavigation mobileNavigationItems={mobileNavigationItems} />
      )}
      {primaryNavigationItems?.length > 0 && (
        <PrimaryNavigationListContainer>
          <PrimaryNavigationList ref={navListRef}>
            {primaryNavigationItems.map(
              ({ headline, popover, href, external }, index) => {
                if (popover) {
                  return (
                    <Popper
                      key={`${headline}-${index}`}
                      content={popover}
                      modifiers={{
                        name: "offset",
                        options: {
                          offset: [0, 21],
                        },
                      }}
                    >
                      <NavigationDropdown
                        weight={TEXT_WEIGHTS[700]}
                        index={index}
                        label={headline}
                        href={href}
                        size={TEXT_SIZES.SM}
                      />
                    </Popper>
                  );
                }
                return (
                  <NavigationLink
                    key={`${headline}-${index}`}
                    index={index}
                    label={headline}
                    href={href}
                    external={external}
                    weight={TEXT_WEIGHTS[700]}
                    size={TEXT_SIZES.SM}
                  />
                );
              }
            )}
            <NavActions
              text={navActions.lessText}
              show={showLess}
              onClick={lessBtnHandler}
              onKeyPress={keyPressLessHandler}
            />
          </PrimaryNavigationList>
          <NavActions
            show={showMore}
            text={navActions.moreText}
            onClick={moreBtnHandler}
            onKeyPress={keyPressMoreHandler}
          />
        </PrimaryNavigationListContainer>
      )}
    </Container>
  );
};

export default PrimaryNavigation;

PrimaryNavigation.propTypes = {
  /** If present shows the appname next to the DHL logo.  */
  appName: PropTypes.string,
  /** This prop controls the Copy Text for the More and the Less buttons when navigation items grows beyond the container width.  */
  navActions: PropTypes.shape({
    moreText: PropTypes.string,
    lessText: PropTypes.string,
  }),
  /** This prop controls the behaviour of the DHL Logo i.e. the title, alternate text, where to redirect etc. */
  logo: PropTypes.shape({
    /** where to redirect  */
    href: PropTypes.string,
    /** logo title */
    title: PropTypes.string,
    /** redirection external or internal */
    external: PropTypes.bool,
    /** alternative text */
    alt: PropTypes.string,
  }).isRequired,
  /** This prop if passed will display and controls the behaviour of the Search functionality */
  searchData: PropTypes.shape({
    /** where to redirect for search query */
    action: PropTypes.string,
    /** placeholder for search input */
    placeholder: PropTypes.string,
    /** search label */
    label: PropTypes.string,
  }),
  /** This prop if passed will display and manage the configuration of each primary meta item regarding the copy, title, redirection link, and if it should open in a new window */
  primaryMetaItems: PropTypes.arrayOf(
    PropTypes.shape({
      /** redirection link */
      href: PropTypes.string,
      /** if link should open in current window or new window */
      external: PropTypes.bool,
      /** Title of the link */
      title: PropTypes.string,
      /** link text */
      copy: PropTypes.string,
    })
  ),
  /** This prop if passed will display and manage the configuration of each primary navigation item regarding the headline, title, redirection link, the popover component etc. It accepts an array of object of navigationItems */
  primaryNavigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      /** redirection link */
      href: PropTypes.string,
      /** if link should open in current window or new window */
      external: PropTypes.bool,
      /** Title of the link */
      title: PropTypes.string,
      /** Link popover content */
      popover: PropTypes.node,
    })
  ),
  /** This prop if passed will display and manage the mobile navigation drawer. It accepts an object of menuItems, search redirect url and language redirect url */
  mobileNavigationItems: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        /** redirection link */
        href: PropTypes.string,
        /** if link should open in current window or new window */
        external: PropTypes.bool,
        /** Title of the link */
        title: PropTypes.string,
        /** menu item text */
        copy: PropTypes.string,
        /** menu item type i.e. list, back, linklist */
        type: PropTypes.string,
      })
    ),
    /** mobile search redirection url */
    searchUrl: PropTypes.string,
    /** mobile language redirection url */
    languageUrl: PropTypes.string,
  }),
  /** This prop if passed will display and manage the configuration of country data regarding the copy, title, redirection link, and if the link should open in the new window */
  countryData: PropTypes.shape({
    /** Country Redirection url */
    href: PropTypes.string,
    /** if link should open externally */
    external: PropTypes.bool,
    /** Country Title */
    title: PropTypes.string,
    /** Country Text */
    copy: PropTypes.string,
  }),
  /** This prop if passed controls the configuration of the languages on the navbar. It accepts the array of languages object */
  languageData: PropTypes.arrayOf(
    PropTypes.shape({
      /** selected language bool */
      isSelected: PropTypes.bool,
      /** language redirection link */
      href: PropTypes.string,
      /** if the window should open externally or internally */
      external: PropTypes.bool,
      /** language title */
      title: PropTypes.string,
      /** language text */
      copy: PropTypes.string,
    })
  ),
};

PrimaryNavigation.defaultProps = {
  appName: "",
  navActions: {
    moreText: "More",
    lessText: "Less",
  },
};
