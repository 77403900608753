// 9fbef606107a605d69c0edbcd8029e5d

/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes, { number } from "prop-types";
import styled from "styled-components";
import { usePopperTooltip } from "react-popper-tooltip";

const PopperContainer = styled.div`
  background-color: var(--dui-color-white-500);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  padding: 0 var(--dui-size-space-10x) calc(2 * var(--dui-size-space-7x));
  z-index: 1;
  & .tooltip-arrow {
    height: var(--dui-size-space-7x);
    left: 0;
    margin-top: -6px;
    position: absolute;
    top: 0;
    width: var(--dui-size-space-7x);
    &::after {
      border-style: solid;
      content: "";
      display: block;
      height: 0;
      margin: auto;
      position: absolute;
      width: 0;
      border-color: transparent transparent var(--dui-color-white-500)
        transparent;
      border-width: 0 var(--dui-size-space-3x) var(--dui-size-space-3x)
        var(--dui-size-space-3x);
    }
    &::before {
      border-color: transparent transparent var(--dui-color-white-500)
        transparent;
      border-style: solid;
      border-width: 0 var(--dui-size-space-3x) var(--dui-size-space-3x)
        var(--dui-size-space-3x);
      content: "";
      display: block;
      height: 0;
      margin: auto;
      position: absolute;
      top: -1px;
      width: 0;
    }
  }
`;

const Popper = ({ children, content, modifiers }) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(
    {
      trigger: "click",
    },
    {
      modifiers: [
        {
          name: "preventOverflow",
          options: {
            padding: 10,
          },
        },
        { ...modifiers },
      ],
    }
  );
  return (
    <React.Fragment>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          ref: setTriggerRef,
          visible,
        });
      })}
      {visible && (
        <PopperContainer
          ref={setTooltipRef}
          {...getTooltipProps({ className: "tooltip-container" })}
        >
          {content}
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
        </PopperContainer>
      )}
    </React.Fragment>
  );
};

export default Popper;

Popper.propTypes = {
  /* content to be put in the popper */
  content: PropTypes.node.isRequired,
  /* element triggering the popper */
  children: PropTypes.node.isRequired,
  /* modifiers for popperjs based on https://popper.js.org/docs/v2/modifiers/ */
  modifiers: PropTypes.shape({
    name: PropTypes.string,
    options: PropTypes.shape({
      offset: PropTypes.arrayOf(number),
    }),
  }),
};

Popper.defaultProps = {
  modifiers: {
    name: "offset",
    options: {
      offset: [0, 7],
    },
  },
};
