// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import styled from "styled-components";
import { IconPlay } from "assets/icons";
import PropTypes from "prop-types";
import {
  VARIANTS,
  SIZES as BUTTON_SIZES,
} from "../../../atoms/Button/constants";
import { Button } from "../../../atoms";

const StyledButton = styled(Button)`
  padding: 0;

  & > i {
    height: 10.5px;
    width: 10.5px;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  /** For Less Button that is visible on click of more */
  margin-left: var(--dui-size-space-10x);
  &.is-nextline {
    opacity: 0;
    visibility: hidden;
  }
`;

const IconMoreCaret = styled(IconPlay)`
  color: var(--dui-color-red-500);
  position: relative;
  rotate: 90deg;
  top: 2px;
`;

const IconLessCaret = styled(IconMoreCaret)`
  transform: rotate(180deg);
`;

const NavActions = ({ text, onClick, onKeyPress, show }) => {
  const icon =
    text.toUpperCase() === "LESS" ? <IconLessCaret /> : <IconMoreCaret />;
  if (show) {
    return (
      <LinkContainer>
        <StyledButton
          size={BUTTON_SIZES.MD}
          variant={VARIANTS.TEXT}
          type="button"
          iconSize="sm"
          icon={icon}
          iconOrientation="right"
          role="button"
          onClick={onClick}
          onKeyPress={onKeyPress}
        >
          {text}
        </StyledButton>
      </LinkContainer>
    );
  }

  return null;
};

export default NavActions;

NavActions.propTypes = {
  /** NavAction Text */
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  /** NavAction is visible */
  show: PropTypes.bool,
};

NavActions.defaultProps = {
  onClick: () => {},
  onKeyPress: () => {},
  show: false,
};
