// 9fbef606107a605d69c0edbcd8029e5d

import React, { Fragment, useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import PropTypes from "prop-types";
import useOutsideClick from "hooks/useOutsideClick";
import styled from "styled-components";
import { useKeysWithMenu } from "hooks/useKeysWithMenu";
import { concatIfSet } from "utils/arrayUtils";
import { TextSrOnly } from "../../Text/TextSROnly";
import { SIZES, VALIDATION_TYPES } from "./constants";
import {
  ContentContainer,
  StyledPlaceholder,
  StyledLabel,
  LabelPlaceholderContainer,
  Options,
  Container,
  HiddenInput,
  FilterInput,
  FilterContainer,
  Blink,
  getChipWidth,
  ChipsContainerStyled,
  ChipsContainerWrapper,
  ChipCount,
  ArrowContainer,
  ArrowIcon,
  OptionListItem,
} from "./styles";
import Chip from "./chip";
import ValidationFeedback from "../../ValidationFeedback";

const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
  window.HTMLInputElement.prototype,
  "value"
).set;

const SPACING13X = "26px";
const SPACING28X = "56px";

// eslint-disable-next-line react/prop-types
const ChipsContainer = ({ children }) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef();
  const countRef = useRef();

  useEffect(() => {
    setContainerWidth(containerRef.current?.offsetWidth || 0);
  }, [containerRef.current]);

  const childrenArr = React.Children.toArray(children);
  let chipsToShow = 1;
  let chipsWidth = getChipWidth(childrenArr[0].props.children);
  childrenArr.forEach(({ props: { children: text } }, i) => {
    if (!i) return;

    chipsWidth += getChipWidth(text);
    if (chipsWidth < containerWidth) chipsToShow += 1;
  });

  const overflowCount = childrenArr.length - chipsToShow;
  return (
    <ChipsContainerStyled ref={containerRef}>
      <ChipsContainerWrapper className={chipsToShow > 1 ? "flex" : null}>
        {/* eslint-disable-next-line react/prop-types */}
        {children.map((child, i) => (i < chipsToShow ? child : null))}
      </ChipsContainerWrapper>

      {!!overflowCount && (
        <ChipCount ref={countRef}>+{overflowCount}</ChipCount>
      )}
    </ChipsContainerStyled>
  );
};

// eslint-disable-next-line react/prop-types
const Arrow = ({ open, icon }) => (
  <ArrowContainer>{icon || <ArrowIcon open={open} />}</ArrowContainer>
);

export const OptionGroup = styled(OptionListItem).attrs({
  role: "group",
})`
  border-top: 1px solid var(--dui-color-gray-300);
  color: var(--dui-color-gray-600);
  font-size: var(--dui-size-font-xs);
  padding: ${SPACING13X} var(--dui-size-space-8x) var(--dui-size-space-4x);
`;

export const Option = styled(OptionListItem).attrs({
  role: "option",
})`
  color: var(--dui-color-black-500);
  cursor: pointer;
  font-size: var(--dui-size-font-md);
  padding: var(--dui-size-space-7x) var(--dui-size-space-8x);

  &[aria-disabled="true"] {
    cursor: default;
  }
`;

export const OptionsContainer = styled.div`
  background: var(--dui-color-white-500);
  border: var(--dui-size-space-1x) solid
    rgba(0, 0, 0, ${({ disabled }) => (disabled ? 0.4 : 1)});
  border-radius: var(--dui-size-radius-sm);
  border-start-end-radius: 0;
  border-start-start-radius: 0;
  border-top: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  z-index: 1;

  &[data-popper-placement="top"] {
    border: var(--dui-size-space-1x) solid
      rgba(0, 0, 0, ${({ disabled }) => (disabled ? 0.4 : 1)});
    border-bottom: none;
    border-end-end-radius: 0;
    border-end-start-radius: 0;
    border-radius: var(--dui-size-radius-md);

    transform: translateY(
      calc(
        -${({ size }) =>
            size === SIZES.SM ? `var(--dui-size-space-24x)` : SPACING28X} + 2px
      )
    ) !important;

    & + ${ContentContainer} {
      &[data-open="true"] {
        border: var(--dui-size-space-1x) solid var(--dui-color-black-500);
        padding: 0 calc(var(--dui-size-space-8x) - 1px);
        border-radius: var(--dui-size-radius-md);
        border-start-end-radius: 0;
        border-start-start-radius: 0;
        border-top-color: transparent;
      }
    }
  }

  &[data-popper-placement="bottom"] {
    transform: translateY(
      calc(
        ${({ size }) =>
            size === SIZES.SM ? `var(--dui-size-space-24x)` : SPACING28X} - 1px
      )
    ) !important;
  }
`;

const Dropdown = ({
  children,
  className,
  optionsContainerClassName,
  dataTestid,
  dataTracking,
  disabled,
  id,
  label,
  ariaDescribedBy,
  ariaLabel,
  ariaDescribe,
  name,
  placeholder,
  rightIcon,
  value: propValue,
  multiple,
  required,
  isBlock,
  showFilter,
  filterPlaceholder,
  validation,
  onChange,
  onClick,
  onOptionClick,
  onFilterChange,
  onOpen,
  onClose,
}) => {
  const value = propValue || (multiple ? "" : []);
  const size = SIZES.SM;
  const containerRef = useRef();
  const filterInputRef = useRef();
  const hiddenInputRef = useRef();
  const [filterValue, setFilterValue] = useState("");
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  const isInvalid = validation?.type === VALIDATION_TYPES.INVALID;
  const isValid = validation?.type === VALIDATION_TYPES.VALID;
  const validationMessageId = `${id}_validationMessage`;
  const ariaDescribeId = `${id}-instructions`;
  const combinedAriaDescribedBy = concatIfSet([
    ariaDescribe ? ariaDescribeId : ariaDescribedBy,
    validation && validationMessageId,
  ]);

  // When user clicks outside the multiselect
  useOutsideClick(containerRef, () => setOpen(false));

  useEffect(() => {
    if (!open) {
      filterInputRef.current?.blur();
      referenceElement?.blur();
    }

    if (showFilter) {
      setFilterValue("");
      filterInputRef.current?.focus();
    } else {
      referenceElement?.focus();
    }
  }, [open, showFilter]);

  const triggerOnChange = (newValue) => {
    nativeInputValueSetter.call(hiddenInputRef.current, newValue);
    hiddenInputRef.current.dispatchEvent(
      new Event("change", { bubbles: true })
    );
  };

  const handleValueChange = (eventValue) => {
    if (!multiple) {
      triggerOnChange(eventValue);
      return;
    }

    const newValue = [...value];
    const itemIndex = newValue.indexOf(eventValue);
    if (itemIndex === -1) {
      newValue.push(eventValue);
    } else {
      newValue.splice(itemIndex, 1);
    }

    triggerOnChange(newValue.join(","));
  };

  const handleOnContainerClick = (close) => {
    if (disabled && !open) return;

    const isOpen = close ? false : !open;
    setOpen(isOpen);

    if (isOpen) {
      onOpen();
    } else {
      onClose();
    }
  };

  const handleItemClick = ({ props: { value: clickedValue } }) => (e) => {
    e?.preventDefault();
    e?.stopPropagation();

    onOptionClick?.(clickedValue);
    handleValueChange(clickedValue);
    if (!multiple) handleOnContainerClick(true);
  };

  const {
    activeIndex,
    setActiveIndex,
    handleKeyDown: menuKeyEventHandler,
    setMenuRef,
  } = useKeysWithMenu(
    (selectedValue) => {
      handleItemClick({
        props: {
          value: selectedValue,
        },
      })();
    },
    () => handleOnContainerClick(true),
    "active",
    false,
    open
  );

  const handleKeyDown = (e) => {
    if (!open) handleOnContainerClick();

    menuKeyEventHandler(e);
  };

  const selectedOptions = [];
  const optionIds = [];
  const items = React.Children.toArray(children)
    .filter((c) => React.isValidElement(c))
    .map((child) => {
      if (![OptionGroup, "optgroup"].includes(child.type)) {
        return child;
      }

      const {
        label: groupLabel,
        children: groupChildren,
        ...rest
      } = child.props;
      return [
        // eslint-disable-next-line
        <OptionGroup {...rest} aria-label={groupLabel}>
          {groupLabel}
        </OptionGroup>,
        ...React.Children.toArray(groupChildren).filter((c) =>
          React.isValidElement(c)
        ),
      ];
    })
    .flat()
    .map((child, index) => {
      if (child.type === OptionGroup) {
        const optionId = `${name}-group-${index}`;
        optionIds.push(optionId);
        return React.cloneElement(child, {
          "aria-posinset": index + 1,
          "aria-disabled": "true",
          id: optionId,
        });
      }

      const {
        props: { value: childValue, children: itemText, ...restElemProps },
      } = child;
      const item = {
        label: itemText,
        value: childValue,
      };
      const selected = multiple
        ? value.some((val) => val === childValue)
        : value === childValue;
      if (selected) {
        selectedOptions.push(item);
      }

      if (typeof itemText !== "string") return null;

      if (
        !filterValue || // filter is not applied
        !!onFilterChange || // consumer is responsible for filtering
        itemText.toLowerCase().includes(filterValue.toLowerCase()) // apply internal filer
      ) {
        const optionId = `${name}-option-${index}`;
        optionIds.push(optionId);

        const props = {
          "aria-posinset": index + 1,
          "aria-selected": selected,
          "aria-disabled": disabled,
          id: optionId,
          onClick: disabled ? null : handleItemClick(child),
          onFocus: disabled ? null : () => setActiveIndex(index),
          onMouseOver: disabled ? null : () => setActiveIndex(index),
          value: undefined,
          "data-value": childValue,
        };
        if (child.type === "option") {
          return React.createElement(
            Option,
            {
              ...restElemProps,
              ...props,
            },
            itemText
          );
        }
        return React.cloneElement(child, props);
      }

      return null;
    })
    .filter((o) => !!o)
    .map((c, i) =>
      React.cloneElement(c, {
        // eslint-disable-next-line
        key: i,
        "aria-setsize": optionIds.length,
      })
    );

  const optionsId = `${name}-options`;
  const labelId = `${name}-label`;
  return (
    <Fragment>
      <Container
        isBlock={isBlock}
        size={size}
        ref={containerRef}
        data-disabled={disabled}
      >
        {open && (
          <OptionsContainer
            className={optionsContainerClassName}
            disabled={disabled}
            size={size}
            style={styles.popper}
            ref={setPopperElement}
            {...attributes.popper} //eslint-disable-line
          >
            {showFilter && !disabled && (
              <FilterContainer>
                {!filterValue && <Blink />}
                <FilterInput
                  aria-haspopup="listbox"
                  aria-expanded={open}
                  aria-owns={optionsId}
                  aria-activedescendant={
                    open && activeIndex > -1
                      ? optionIds[activeIndex]
                      : undefined
                  }
                  role="combobox"
                  ref={filterInputRef}
                  placeholder={filterPlaceholder}
                  value={filterValue}
                  onChange={({ target: { value: filter } }) => {
                    setFilterValue(filter);
                    onFilterChange?.(filter);
                  }}
                  onKeyDown={handleKeyDown}
                />
              </FilterContainer>
            )}
            <Options
              aria-multiselectable={multiple}
              id={optionsId}
              ref={setMenuRef}
              size={size}
            >
              {items}
            </Options>
          </OptionsContainer>
        )}
        <ContentContainer
          aria-describedby={combinedAriaDescribedBy}
          aria-label={ariaLabel || label}
          aria-haspopup="listbox"
          aria-expanded={open}
          aria-owns={optionsId}
          aria-activedescendant={
            open && !showFilter && activeIndex > -1
              ? optionIds[activeIndex]
              : undefined
          }
          role="button"
          tabIndex="0"
          className={className}
          ref={setReferenceElement}
          isValid={isValid}
          isInvalid={isInvalid}
          data-open={open}
          data-disabled={disabled}
          onClick={(e) => {
            handleOnContainerClick();
            onClick(e);
          }}
          onKeyDown={handleKeyDown}
        >
          <TextSrOnly id={ariaDescribeId}>{ariaDescribe}</TextSrOnly>
          <HiddenInput
            ref={hiddenInputRef}
            id={id}
            name={name}
            defaultValue={multiple ? value.join(",") : value}
            data-testid={dataTestid}
            data-tracking={dataTracking}
            onChange={onChange}
          />

          {multiple && selectedOptions.length ? (
            <ChipsContainer>
              {selectedOptions.map(({ label: chipLbl, value: chipValue }) => (
                <Chip
                  key={chipValue}
                  disabled={disabled}
                  onClick={() => handleValueChange(chipValue)}
                >
                  {chipLbl}
                </Chip>
              ))}
            </ChipsContainer>
          ) : (
            <LabelPlaceholderContainer aria-hidden="true">
              {label && (
                <StyledLabel
                  id={labelId}
                  htmlFor={id}
                  isInvalid={isInvalid}
                  isRequired={required}
                >
                  {label}
                </StyledLabel>
              )}
              <StyledPlaceholder
                hasLabel={Boolean(label)}
                isRequired={required && !label}
                itemSelected={value && selectedOptions.length && !multiple}
              >
                {
                  value && selectedOptions.length && !multiple
                    ? selectedOptions[0].label
                    : placeholder || "\u00A0" /** &nbsp */
                }
              </StyledPlaceholder>
            </LabelPlaceholderContainer>
          )}
          <Arrow icon={rightIcon} open={open} />
        </ContentContainer>
      </Container>
      {validation && (
        <ValidationFeedback hideIcon validation={validation} fieldId={id} />
      )}
    </Fragment>
  );
};

export { StyledPlaceholder, StyledLabel, ContentContainer };

Dropdown.VALID = VALIDATION_TYPES.VALID;
Dropdown.INVALID = VALIDATION_TYPES.INVALID;
Dropdown.NOTE = VALIDATION_TYPES.NOTE;

Dropdown.propTypes = {
  /** An **optional** prop defining the list of reference ids (separated by space), recommended when you want to show some errormessage on your field */
  ariaDescribedBy: PropTypes.string,
  /** An **optional** Aria describe prop read by screen readers, used for accessibility to pass instructions to user */
  ariaDescribe: PropTypes.string,
  /** An **optional** Aria label prop read by screen readers, used for accessibility by the search variant */
  ariaLabel: PropTypes.string,
  /** An **optional** test-id prop used to target the Dropdown component for testing */
  dataTestid: PropTypes.string,
  /** An **optional** prop holding the tracking value attached to the Dropdown as a data-tracking attribute. */
  dataTracking: PropTypes.string,
  /** A **required** id prop for the Dropdown component  */
  id: PropTypes.string.isRequired,
  /** An **optional** name prop for the Dropdown component */
  name: PropTypes.string,
  /** An **optional** classname prop for the Dropdown component */
  className: PropTypes.string,
  /** An **optional** classname prop for the Dropdown Options Container */
  optionsContainerClassName: PropTypes.string,
  /** An **optional** label prop for the Dropdown component */
  label: PropTypes.string,
  /** An **optional** placeholder prop used to pass the placeholder text for the Dropdown component */
  placeholder: PropTypes.string,
  /** An **optional** Icon prop to display to the right of the multiselect, e.g. `<Dropdown rightIcon={<Icon/>} />` */
  rightIcon: PropTypes.element,
  /** A **required**  prop holding children of type `<Option>` and `<OptionGroup>`. */
  children: PropTypes.node.isRequired,
  /** An **optional** multiple prop used to allow suer to select multiple options, please not when using multiple, the value should be an array */
  multiple: PropTypes.bool,
  /** An **optional** prop holding the array of selected values in case of multiselect and string value in case of single select */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /** An **optional** prop flag to define if the Dropdown is disabled */
  disabled: PropTypes.bool,
  /** An **optional** prop to flag the Dropdown component as required within a form context */
  required: PropTypes.bool,
  /** An **optional** prop flag to control if the Dropdown has a 100% width or set width */
  isBlock: PropTypes.bool,
  /** An **optional** prop flag to control if the input to filter the options should be shown */
  showFilter: PropTypes.bool,
  /** An **optional** props for the placeholder for filter input  */
  filterPlaceholder: PropTypes.string,
  /** An **optional** Dropdown validation prop
   *
   * **Required Fields** `type, message`
   */
  validation: PropTypes.shape({
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf([Dropdown.VALID, Dropdown.INVALID, Dropdown.NOTE])
      .isRequired,
  }),
  /** An **optional** onChange callback handler */
  onChange: PropTypes.func,
  /** An **optional** onClick callback handler */
  onClick: PropTypes.func,
  /** An **optional** onClick callback for an option, value of the clicked item is passed as callback parameter */
  onOptionClick: PropTypes.func,
  /** An **optional** onFilterChange callback handler, triggered when filter value is changed. When handled, it overrides internal filtering */
  onFilterChange: PropTypes.func,
  /** An **optional** onOpen callback handler */
  onOpen: PropTypes.func,
  /** An **optional** onClose callback handler */
  onClose: PropTypes.func,
};

Dropdown.defaultProps = {
  required: false,
  isBlock: false,
  multiple: false,
  showFilter: false,
  filterPlaceholder: "Filter options",
  value: "",
  onChange: Function.prototype,
  onClick: Function.prototype,
  onOptionClick: Function.prototype,
  onOpen: Function.prototype,
  onClose: Function.prototype,
};

export default Dropdown;
