// 9fbef606107a605d69c0edbcd8029e5d
import {
  useLayoutEffect as useReactLayoutEffect,
  useEffect,
  useState,
} from "react";
import { isBrowser } from "utils/deviceUtils";

/**
 * In order to avoid the following error in SSR, we need to use the useLayoutEffect conditionally
 *
 * Warning: useLayoutEffect does nothing on the server, because its effect cannot be encoded into the server renderer’s output format.
 * This will lead to a mismatch between the initial, non-hydrated UI and the intended UI.
 * To avoid this, useLayoutEffect should only be used in components that render exclusively on the client.
 * See https://fb.me/react-uselayouteffect-ssr for common fixes.
 */
const useLayoutEffect = isBrowser ? useReactLayoutEffect : useEffect;

const useCollapse = (navListRef) => {
  const [showMore, setShowMore] = useState(false);
  const [showLess, setShowLess] = useState(false);
  const navigationListRef = navListRef;

  const checkMoreVisibility = () => {
    if (navListRef && navListRef.current) {
      let iTotalWidth = 0;
      const navList = navListRef.current;
      const navItems = navListRef.current.children;
      const navWidth =
        navList.offsetWidth -
        parseInt(window.getComputedStyle(navList)["padding-right"], 10);
      for (let i = 0; i < navItems.length; i += 1) {
        iTotalWidth =
          iTotalWidth +
          parseInt(navItems[i].offsetWidth, 10) +
          parseInt(window.getComputedStyle(navItems[i]).marginLeft, 10);
      }
      if (navWidth < iTotalWidth) {
        setShowMore(true);
        setShowLess(false);
      } else {
        setShowMore(false);
        setShowLess(false);
      }
      navList.style.height = `${
        parseInt(window.getComputedStyle(navItems[0]).height, 10) +
        parseInt(window.getComputedStyle(navItems[0])["margin-bottom"], 10) +
        parseInt(window.getComputedStyle(navItems[0])["margin-top"], 10)
      }px`;
    }
  };

  const checkItemNextLine = (isOpen) => {
    let offsetTop;
    if (
      navListRef &&
      navListRef.current &&
      navListRef.current.children.length > 0
    ) {
      offsetTop = navListRef.current.children[0].offsetTop;
      [...navListRef.current.children].forEach((_node) => {
        if (offsetTop !== _node.offsetTop && isOpen) {
          _node.classList.add("is-nextline");
        } else {
          _node.classList.remove("is-nextline");
        }
      });
    }
  };

  // called on first render
  useLayoutEffect(() => {
    document.fonts.ready.then(() => checkMoreVisibility());
  }, []);

  // called when showMore changes
  useLayoutEffect(() => {
    checkItemNextLine(showMore);
    if (!showMore && navigationListRef && navigationListRef.current) {
      navigationListRef.current.style.height = "auto";
    } else if (navigationListRef && navigationListRef.current) {
      navigationListRef.current.style.height = `${
        parseInt(
          window.getComputedStyle(navListRef.current.children[0]).height,
          10
        ) +
        parseInt(
          window.getComputedStyle(navListRef.current.children[0])[
            "margin-bottom"
          ],
          10
        ) +
        parseInt(
          window.getComputedStyle(navListRef.current.children[0])["margin-top"],
          10
        )
      }px`;
    }
  }, [showMore]);

  useLayoutEffect(() => {
    checkItemNextLine(!showLess);
  }, [showLess]);

  // called on resize
  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      if (window.matchMedia("(min-width: 768px)").matches) {
        checkMoreVisibility();
        checkItemNextLine(true);
      }
    });
    return () =>
      window.removeEventListener("resize", () => {
        if (window.matchMedia("(min-width: 768px)").matches) {
          checkMoreVisibility();
          checkItemNextLine(true);
        }
      });
  });

  return [showMore, showLess, setShowMore, setShowLess];
};

export default useCollapse;
