// 9fbef606107a605d69c0edbcd8029e5d

import styled from "styled-components";

const NavigationList = styled.ul`
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  line-height: calc(2 * var(--dui-size-space-7x));
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
`;

export default NavigationList;
