// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { getMediaQuery } from "styles/theme";
import { transformToArray } from "utils/arrayUtils";

const StyledGridCell = styled.div`
  box-sizing: border-box;
  grid-column-end: ${({ spanColumns }) =>
    spanColumns ? css`span ${spanColumns[0]}` : undefined};
  grid-column-start: ${({ startColumn }) =>
    startColumn ? startColumn[0] : undefined};
  overflow: ${({ isOverflowHidden }) =>
    isOverflowHidden ? "hidden" : undefined};
  width: 100%;

  ${getMediaQuery("md")`
    grid-column-end: ${({ spanColumns }) =>
      spanColumns ? css`span ${spanColumns[1] || spanColumns[0]}` : undefined};
    grid-column-start: ${({ startColumn }) =>
      startColumn ? startColumn[1] || startColumn[0] : undefined};
  `}

  ${getMediaQuery("lg")`
    grid-column-end: ${({ spanColumns }) =>
      spanColumns
        ? css`span ${spanColumns[2] || spanColumns[1] || spanColumns[0]}`
        : undefined};
    grid-column-start: ${({ startColumn }) =>
      startColumn
        ? startColumn[2] || startColumn[1] || startColumn[0]
        : undefined};
  `}

  ${getMediaQuery("xl")`
    grid-column-end: ${({ spanColumns }) =>
      spanColumns
        ? css`span ${
            spanColumns[3] || spanColumns[2] || spanColumns[1] || spanColumns[0]
          }`
        : undefined};
    grid-column-start: ${({ startColumn }) =>
      startColumn
        ? startColumn[3] || startColumn[2] || startColumn[1] || startColumn[0]
        : undefined};
  `}
`;

const GridCell = ({
  children,
  className,
  dataTestid,
  id,
  isOverflowHidden,
  startColumn,
  spanColumns,
}) => {
  const spanColumsArray = !!spanColumns && transformToArray(spanColumns);
  const startColumnArray = !!startColumn && transformToArray(startColumn);

  return (
    <StyledGridCell
      className={className}
      data-testid={dataTestid}
      id={id}
      isOverflowHidden={isOverflowHidden}
      startColumn={startColumnArray}
      spanColumns={spanColumsArray}
    >
      {children}
    </StyledGridCell>
  );
};

GridCell.propTypes = {
  /** An **optional** prop that holds the children of GridCell components */
  children: PropTypes.node,
  /** An **optional** classname prop for the GridCell component */
  className: PropTypes.string,
  /** An **optional** test-id prop used to target the GridCell component for testing */
  dataTestid: PropTypes.string,
  /** An **optional** id prop for the GridCell component */
  id: PropTypes.string,
  /** An **optional** flag to configure overflow display */
  isOverflowHidden: PropTypes.bool,
  /** An **optional** prop that sets the width of the cell by specifying
   * over how many grid columns the cell should span
   * Please note: Value can be of type number or array
   * If specified as number, the value is used for all viewports
   * If specified as array, the values are used for the viewports in the following order:
   * 0 = small. 1 = medium, 2 = large viewport, 3 = xlarge viewport
   * Use "unset" in larger viewports to ignore settings from smaller viewports */
  spanColumns: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
    PropTypes.number,
    PropTypes.string,
  ]),
  /**  An **optional** prop that sets the position of the cell by specifying
   * at what grid column the cell should be displayed
   * Please note: Value can be of type number or array
   * If specified as number, the value is used for all viewports
   * If specified as array, the values are used for the viewports in the following order:
   * 0 = small. 1 = medium, 2 = large viewport, 3 = xlarge viewport
   * Use "unset" in larger viewports to ignore settings from smaller viewports */
  startColumn: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
    PropTypes.number,
    PropTypes.string,
  ]),
};

GridCell.defaultProps = {
  isOverflowHidden: true,
};

export default GridCell;
