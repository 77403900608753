// 9fbef606107a605d69c0edbcd8029e5d

import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import { isDisabledDate } from "utils/dateUtils";
import { Day } from "./Day";
import { CalendarCell } from "./Styles";
import { getDayVariant } from "./getDayVariant";

export const DayCell = ({
  locale,
  dayObj,
  todayDate,
  hoveredDate,
  selectedDate,
  selectedDates,
  onSelect,
  onHover,
  minDate,
  maxDate,
}) => {
  const isDisabled = isDisabledDate(dayObj, minDate, maxDate);

  const variant = getDayVariant({
    dayObj,
    hoveredDate,
    todayDate,
    selectedDate,
    selectedDates,
  });

  return (
    <CalendarCell>
      <Day
        dataTestid={dayObj.format("YYYY-MM-DD")}
        ariaLabel={dayObj.locale(locale).format("dddd, MMMM D, YYYY")}
        day={dayObj.format("D")}
        variant={variant}
        onClick={() => onSelect(dayObj)}
        onMouseEnter={() => onHover(dayObj)}
        onFocus={() => onHover(dayObj)}
        disabled={isDisabled}
      />
    </CalendarCell>
  );
};

DayCell.defaultProps = {
  selectedDate: dayjs(""),
  selectedDates: [],
  onHover: () => {},
  onSelect: () => {},
};

DayCell.propTypes = {
  /** locale for calendar. This will changes the week and month labels */
  locale: PropTypes.string,
  /** Instance of dayjs for a day */
  dayObj: PropTypes.instanceOf(dayjs).isRequired,
  /** Instance of dayjs for a hovered date */
  hoveredDate: PropTypes.instanceOf(dayjs),
  /** use this prop to disable the dates before maxDate */
  maxDate: PropTypes.instanceOf(dayjs),
  /** use this prop to disable the dates after minDate */
  minDate: PropTypes.instanceOf(dayjs),
  /** Callback for on select a date */
  onSelect: PropTypes.func,
  /** Callback for on hover a date */
  onHover: PropTypes.func,
  /** Current selected date */
  selectedDate: PropTypes.instanceOf(dayjs),
  /** When it is a range to be selected */
  selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
  /** Instance of dayjs for a today's date */
  todayDate: PropTypes.instanceOf(dayjs),
};
