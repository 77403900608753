// 9fbef606107a605d69c0edbcd8029e5d

import {
  ANIMATED,
  STATIC,
  VALID,
  INVALID,
  NOTE,
  EMAIL,
  NUMBER,
  NUMERIC,
  PASSWORD,
  TELEPHONE,
  TEXT,
  DATE,
} from "_commons/constants";

export const LABEL_TYPES = {
  ANIMATED,
  STATIC,
};

export const VALIDATION_TYPES = {
  VALID,
  INVALID,
  NOTE,
};

// TODO: TYPES is not descriptive enough - FIELD_TYPES could be better.
export const TYPES = {
  EMAIL,
  NUMBER,
  NUMERIC,
  PASSWORD,
  TELEPHONE,
  TEXT,
  DATE,
};

/* eslint-disable no-param-reassign */
export function assignInputConstants(Component) {
  Component.prototype = Object.assign(Component, {
    ...LABEL_TYPES,
    ...VALIDATION_TYPES,
    ...TYPES,
  });
}
