// 9fbef606107a605d69c0edbcd8029e5d

import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import animations from "styles/animations";
import { shouldUseNativeDatePicker } from "utils/deviceUtils";

export const StyledCalendarWrapper = styled.div`
  animation: ${animations.appearFromTop} 0.2s ease-in-out;
  background-color: var(--dui-color-white-500);
  border: var(--dui-size-space-1x) solid var(--dui-color-black-500);
  border-radius: var(--dui-size-radius-md);
  display: flex;
  left: 0;
  margin-top: var(--dui-size-space-4x);
  position: absolute;
  transform-origin: top left;
  z-index: 1;

  &.removing {
    animation: ${animations.disappearFromBottom} 0.2s ease-in-out;
  }
`;

export const CalendarWrapper = ({
  id,
  ariaLabel,
  className,
  isOpen: isOpenProp,
  children,
  onKeyDownHandler,
}) => {
  // to control the animation
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState();

  const openCalendar = () => setIsOpen(true);

  const closeCalendar = () => {
    if (ref && ref.current) {
      ref.current.classList.add("removing");
    }
  };

  const onAnimationEndHandler = () => {
    if (ref.current.classList.contains("removing")) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpenProp) openCalendar();
    else closeCalendar();
  }, [isOpenProp]);

  return !shouldUseNativeDatePicker && isOpen ? (
    <StyledCalendarWrapper
      id={id}
      role="dialog"
      aria-modal="true"
      title={ariaLabel}
      className={className}
      ref={ref}
      onAnimationEnd={onAnimationEndHandler}
      onKeyDown={onKeyDownHandler}
    >
      {children}
    </StyledCalendarWrapper>
  ) : null;
};

CalendarWrapper.defaultProps = {
  isOpen: false,
  className: "",
  id: "pl-cal-wrapper",
  ariaLabel: "Calendar",
  onKeyDownHandler: () => {},
};

CalendarWrapper.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  ariaLabel: PropTypes.string,
  onKeyDownHandler: PropTypes.func,
};
