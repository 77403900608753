// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { concatIfSet } from "utils/arrayUtils";
import { VARIANTS } from "components/atoms/ValidationFeedback/constants";
import { SIZES, assignRadioFieldConstants } from "./constants";

import ValidationFeedback from "../../ValidationFeedback";

/** Font Family Design Token */
const FONT_FAMILY = "Delivery, Verdana, sans-serif";

const BORDER_BOX_SHADOW = "0 0 0 1px #000000";

const StyledValidationFeedback = styled(ValidationFeedback)`
  ${({ size }) => {
    return css`
      margin-left: ${size === SIZES.MD
        ? `var(--dui-size-space-20x)`
        : `var(--dui-size-space-16x)`};
      margin-top: var(--dui-size-space-1x);
    `;
  }}
`;

const getRadioDimensionsForSize = (size) => {
  switch (size) {
    case SIZES.MD:
      return `var(--dui-size-space-16x)`;

    case SIZES.SM:
    default:
      return `var(--dui-size-space-12x)`;
  }
};

const Container = styled.div`
  display: inline-block;
  position: relative;
  user-select: none;
`;

const getRadioContainerColor = (validation) => {
  switch (validation?.type) {
    case VARIANTS.VALID:
      return `var(--dui-color-green-400)`;

    case VARIANTS.INVALID:
      return `var(--dui-color-red-500)`;

    default:
      return `var(--dui-color-gray-500)`;
  }
};

const RadioIconContainer = styled.div`
  ${({ size }) => {
    const radioSize = getRadioDimensionsForSize(size);

    return css`
      align-items: center;
      border: solid 1px
        ${({ validation }) => getRadioContainerColor(validation)};
      border-radius: var(--dui-size-radius-full);
      box-sizing: border-box;
      color: var(--dui-color-black-500);
      display: flex;
      height: ${radioSize};
      justify-content: center;
      left: 0;
      padding: ${size === SIZES.MD
        ? `var(--dui-size-space-5x)`
        : `var(--dui-size-space-4x)`};
      pointer-events: none;
      position: absolute;
      transition: border-color var(--dui-duration-3x);
      width: ${radioSize};
    `;
  }}
`;

const Radio = styled.div`
  background-color: var(--dui-color-black-500);
  border-radius: var(--dui-size-radius-full);
  height: 100%;
  transition: transform var(--dui-duration-3x), opacity var(--dui-duration-3x);
  width: 100%;
`;

const ChildrenContainer = styled.span`
  display: inline-flex;
  margin-left: var(--dui-size-space-5x);
  position: relative;
`;

const Label = styled.label`
  ${({ size }) => {
    const radioSize = getRadioDimensionsForSize(size);

    return css`
      align-items: center;
      cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
      display: flex;
      font-family: ${FONT_FAMILY};
      font-size: var(--dui-size-font-md);
      height: ${radioSize};
      opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
      padding-left: ${radioSize};
    `;
  }}
`;

const Check = styled.input.attrs({ type: "radio", noValidate: true })`
  left: -9999px;
  position: absolute;

  &:focus {
    ~ ${ChildrenContainer} {
      border-color: var(--dui-color-black-500);
    }
  }

  &:hover,
  &:active {
    ~ ${RadioIconContainer} {
      border-color: ${({ disabled }) =>
        disabled ? `var(--dui-color-gray-300)` : `var(--dui-color-black-500)`};
    }
  }

  &:focus {
    ~ ${RadioIconContainer} {
      border-color: ${({ disabled }) =>
        disabled ? `var(--dui-color-gray-300)` : `var(--dui-color-black-500)`};
      box-shadow: ${BORDER_BOX_SHADOW};
    }
  }

  &:not(:checked) {
    ~ ${RadioIconContainer} > ${Radio} {
      opacity: 0;
      transform: scale(0);
    }
  }

  &:checked {
    ~ ${RadioIconContainer} > ${Radio} {
      opacity: 1;
      transform: scale(1);
    }
  }
`;
// #endregion

const RadioField = ({
  ariaDescribedBy,
  ariaLabel,
  children,
  className,
  id,
  isChecked,
  disabled,
  onChange,
  size,
  validation,
  value,
  name,
  dataTestid,
  required,
}) => {
  // TODO: read this from component. Do not hard code
  const validationMessageId = id ? `${id}_validationMessage` : undefined;
  const combinedAriaDescribedBy = concatIfSet([
    ariaDescribedBy,
    validation && validationMessageId,
  ]);

  return (
    <Container className={className}>
      <Label
        size={size}
        disabled={disabled}
        data-teststatus={disabled ? "disabled" : "enabled"}
      >
        <Check
          aria-describedby={combinedAriaDescribedBy}
          aria-label={ariaLabel}
          id={id}
          name={name}
          disabled={disabled}
          checked={isChecked}
          onChange={onChange}
          value={value}
          data-testid={dataTestid}
          required={required}
        />

        <RadioIconContainer size={size} validation={validation}>
          <Radio />
        </RadioIconContainer>

        <ChildrenContainer size={size}>{children}</ChildrenContainer>
      </Label>

      {validation && (
        <StyledValidationFeedback
          validation={validation}
          id={validationMessageId}
          size={size}
          hideIcon
        />
      )}
    </Container>
  );
};

assignRadioFieldConstants(RadioField);

RadioField.propTypes = {
  /** An **optional** prop defining the list of reference ids (separated by space) that describe the component */
  ariaDescribedBy: PropTypes.string,
  /** An **optional** prop defining the text read by the screen reader to represent the RadioField; use this if you need different text to be read from label by a screenreader */
  ariaLabel: PropTypes.string,
  /** An **optional** prop flag to define if the CheckboxField is disabled */
  disabled: PropTypes.bool,
  /** An **optional** children prop  */
  children: PropTypes.node,
  /** An **optional** classname prop for the RadioField component */
  className: PropTypes.string,
  /** An **optional** id prop for the RadioField component */
  id: PropTypes.string,
  /** A **optional** prop used to describe either a checked or unchecked state of the RadioField  */
  isChecked: PropTypes.bool,
  /** An **optional** onChange callback handler - see the Example use-case section for implementation example.  */
  onChange: PropTypes.func,
  /** An **optional** prop defining the size variant of the RadioField  */
  size: PropTypes.oneOf([RadioField.MD, RadioField.SM]),
  /** An **optional** RadioField validation prop
   *
   * **Required Fields** `message` `type`
   *
   */
  validation: PropTypes.shape({
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      RadioField.VALID,
      RadioField.INVALID,
      RadioField.NOTE,
    ]).isRequired,
  }),
  /** An **optional** prop defining the value of the RadioField which is taken when a form is submitted */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** An **optional** name prop for the RadioField component used to reference form data within a form element */
  name: PropTypes.string,
  /** An **optional** test-id prop used to target the RadioField component for testing */
  dataTestid: PropTypes.string,
  /** An **optional** prop to flag the RadioField component as required within a form context */
  required: PropTypes.bool,
};

RadioField.defaultProps = {
  isChecked: false,
  onChange: Function.prototype,
  size: RadioField.MD,
  required: false,
};

export default RadioField;
