// 9fbef606107a605d69c0edbcd8029e5d

export const buttonStyles = {
  md: {
    PADDING: "var(--dui-size-space-7x) var(--dui-size-space-16x)",
    PADDING_ICON_ONLY: "var(--dui-size-space-6x) var(--dui-size-space-10x)",
    PADDING_ROUND: "var(--dui-size-space-8x)",
    FONT_SIZE: "var(--dui-size-font-md)",
    RADIUS: "var(--dui-size-radius-md)",
    HEIGHT: "var(--dui-size-space-24x)",
    ROUND_WIDTH: "var(--dui-size-space-24x)",
    LINE_HEIGHT: "var(--dui-size-line-height-lg)",
  },
  sm: {
    PADDING: "var(--dui-size-space-3x) var(--dui-size-space-6x)",
    PADDING_ICON_ONLY: "var(--dui-size-space-2x) var(--dui-size-space-6x)",
    PADDING_ROUND: "var(--dui-size-space-3x)",
    FONT_SIZE: "var(--dui-size-font-md)",
    RADIUS: "var(--dui-size-radius-md)",
    HEIGHT: "var(--dui-size-space-16x)",
    ROUND_WIDTH: "var(--dui-size-space-16x)",
    LINE_HEIGHT: "var(--dui-size-line-height-lg)",
  },
  xs: {
    PADDING: "var(--dui-size-space-2x) var(--dui-size-space-4x)",
    PADDING_ICON_ONLY: "var(--dui-size-space-1x) var(--dui-size-space-4x)",
    PADDING_ROUND: "var(--dui-size-space-2x)",
    FONT_SIZE: "var(--dui-size-font-sm)",
    RADIUS: "var(--dui-size-radius-sm)",
    HEIGHT: "var(--dui-size-space-12x)",
    ROUND_WIDTH: "var(--dui-size-space-12x)",
    LINE_HEIGHT: "var(--dui-size-line-height-xs)",
  },
  TEXT_PADDING: "0px",
  ICON_MARGIN: "6px",
};
