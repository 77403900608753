// 9fbef606107a605d69c0edbcd8029e5d
import { VALID, INVALID, NOTE, MD, SM } from "_commons/constants";

export const VALIDATION_TYPES = {
  VALID,
  INVALID,
  NOTE,
};

export const SIZES = {
  SM,
  MD,
};

/* eslint-disable no-param-reassign */
export function assignRadioFieldConstants(Component) {
  Component.prototype = Object.assign(Component, {
    ...VALIDATION_TYPES,
    ...SIZES,
  });
}
