// 9fbef606107a605d69c0edbcd8029e5d

import React, { memo, createRef, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { useKeysWithMenu } from "hooks/useKeysWithMenu";
import { usePopper } from "react-popper";
import {
  TYPES as INPUTFIELD_TYPES,
  VALIDATION_TYPES,
  LABEL_TYPES,
} from "../Input/constants";
import { TextSrOnly } from "../../Text/TextSROnly";
import InputField from "../Input";

// #region Component Styles
const Container = styled.div`
  display: ${({ isBlock }) => (isBlock ? "block" : "inline-block")};
  position: relative;
  width: ${({ isBlock }) => (isBlock ? "100%" : "200px")};
`;

const Menu = styled.ul.attrs({ role: "listbox" })`
  background-color: var(--dui-color-white-500);
  box-sizing: border-box;
  border-bottom-left-radius: var(--dui-size-radius-md);
  border-bottom-right-radius: var(--dui-size-radius-md);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: solid 2px var(--dui-color-black-400);
  border-top: solid 1px var(--dui-color-gray-400);
  list-style: none;
  padding: 0;
  margin-top: -4px;
  margin-bottom: 0;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 1;

  &[data-popper-placement="top"] {
    margin-bottom: -4px;

    border-top-left-radius: var(--dui-size-radius-md);
    border-top-right-radius: var(--dui-size-radius-md);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: solid 2px var(--dui-color-black-400);
    border-bottom: solid 1px var(--dui-color-gray-400);
  }
`;

const MenuItem = styled.li.attrs({ role: "option" })`
  &.active > div {
    background-color: var(--dui-color-gray-200);
  }
`;

const Option = styled.div`
  background-color: ${({ isHightlighted }) =>
    isHightlighted ? `var(--dui-color-gray-200)` : "transparent"};
  border: 0;
  box-sizing: border-box;
  display: block;
  outline: 0;
  padding: var(--dui-size-space-3x) var(--dui-size-space-7x);
  text-align: left;
  width: 100%;
`;

const StyledInputField = styled(InputField)`
  ${(props) =>
    props.searchIcon
      ? css`
          & > input,
          & > span {
            padding: 17px 35px 18px 35px;
          }
        `
      : undefined}
`;

// #endregion

const AutoCompleteField = ({
  ariaDescribe,
  ariaLabel,
  id,
  className,
  dataTestid,
  required,
  name,
  searchIcon,
  isBlock,
  numberOfOptionsCopy,
  onBlur,
  onChange,
  onOptionSelected,
  options,
  renderOption,
  customFilter,
  validation,
  value,
  variant,
}) => {
  const autoCompleteId = `${name}-auto-complete`;
  const inputFieldRef = createRef();
  const numberOfOptions = options.length;
  const [shouldShowSuggestions, setShouldShowSuggestions] = useState(false);
  const [ignoreInputBlur, setIgnoreInputBlur] = useState(false);
  const isShowingSuggestions = options.length > 0 && shouldShowSuggestions;
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  useEffect(() => {
    if (!shouldShowSuggestions) {
      setIgnoreInputBlur(false);
    }
  }, [shouldShowSuggestions]);

  const selectOption = (o) => {
    onOptionSelected(o);
    setShouldShowSuggestions(false);
    setIgnoreInputBlur(false);
    inputFieldRef.current.focus();
  };

  const onInputBlur = () => {
    setShouldShowSuggestions(false);
    onBlur();
  };

  const onInputFocus = () => {
    setShouldShowSuggestions(false);
  };

  const getDefaultFilteredItems = () =>
    options.filter((option) => {
      // default filter on "label if an object
      const candidate = option.label ?? option;
      return candidate.toLowerCase().indexOf(value.toLowerCase()) > -1;
    });

  const getFilteredItems = () => {
    if (customFilter) {
      return customFilter(options, value);
    }
    return getDefaultFilteredItems();
  };

  const onItemSelectedWithKey = (i) => {
    selectOption(getFilteredItems()[i]);
  };

  const {
    activeIndex: index,
    setActiveIndex: setIndex,
    handleKeyDown: menuKeyEventHandler,
    setMenuRef,
  } = useKeysWithMenu(
    onItemSelectedWithKey,
    () => setShouldShowSuggestions(false),
    "active",
    true,
    isShowingSuggestions
  );

  const handleKeyDown = (e) => {
    setShouldShowSuggestions(true);
    menuKeyEventHandler(e);
  };

  return (
    <Container
      className={className}
      isBlock={isBlock}
      ref={setReferenceElement}
    >
      <TextSrOnly id={`${id}-instructions`}>{ariaDescribe}</TextSrOnly>
      <StyledInputField
        aria-activedescendant={
          index !== -1 && isShowingSuggestions
            ? `${name}-suggestions-${index}`
            : undefined
        }
        id={id}
        ariaAutocomplete="list"
        ariaDescribedBy={!value ? `${id}-instructions` : undefined}
        ariaExpanded={isShowingSuggestions ? "true" : "false"}
        ariaLabel={ariaLabel}
        aria-owns={autoCompleteId}
        autoComplete="off"
        isBlock={isBlock}
        searchIcon={searchIcon}
        dataTestid={dataTestid}
        required={required}
        name={name}
        onBlur={!ignoreInputBlur ? onInputBlur : undefined}
        onChange={onChange}
        onFocus={!ignoreInputBlur ? onInputFocus : undefined}
        onKeyDown={handleKeyDown}
        ref={inputFieldRef}
        role="combobox"
        type={INPUTFIELD_TYPES.TEXT}
        validation={
          isShowingSuggestions && validation
            ? { type: validation.type }
            : validation
        }
        value={value}
        variant={variant}
      />

      {shouldShowSuggestions && value && (
        <TextSrOnly aria-atomic="true" aria-live="assertive" role="status">
          {`${getFilteredItems().length} ${numberOfOptionsCopy}`}
        </TextSrOnly>
      )}

      {isShowingSuggestions && (
        <Menu
          id={autoCompleteId}
          onMouseEnter={() => setIgnoreInputBlur(true)}
          onMouseLeave={() => setIgnoreInputBlur(false)}
          onTouchStart={() => setIgnoreInputBlur(true)}
          ref={(ref) => {
            setMenuRef(ref);
            setPopperElement(ref);
          }}
          style={styles.popper}
          {...attributes.popper} //eslint-disable-line
        >
          {getFilteredItems().map((o, optionIndex) => (
            <MenuItem
              id={`${name}-suggestions-${optionIndex}`}
              aria-posinset={optionIndex + 1}
              aria-selected={index === optionIndex}
              aria-setsize={numberOfOptions}
              key={o.id}
              data-value={optionIndex}
            >
              <Option
                isHightlighted={index === optionIndex}
                onClick={() => selectOption(o)}
                onFocus={() => setIndex(optionIndex)}
                onMouseOver={() => setIndex(optionIndex)}
              >
                {renderOption(o)}
              </Option>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Container>
  );
};

AutoCompleteField.propTypes = {
  /** A **required** id prop for the Autocomplete component  */
  id: PropTypes.string.isRequired,
  /** A **required** Autocomplete options prop array which holds the available options to select within the Autocomplete component */
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  /** An **optional** Aria describe prop which indicates the id of the object that describes the Autocomplete  */
  ariaDescribe: PropTypes.string,
  /** An **optional** Aria label prop read by screen readers, used for accessibility by the search variant */
  ariaLabel: PropTypes.string,
  /** An **optional** classname prop for the Autocomplete component */
  className: PropTypes.string,
  /** An **optional** test-id prop used to target the Autocomplete component for testing */
  dataTestid: PropTypes.string,
  /** An **optional** prop to flag the Autocomplete component as required within a form context */
  required: PropTypes.bool,
  /** An **optional** name prop for the Autocomplete component */
  name: PropTypes.string,
  /** An **optional** prop holding the number of options in Autocomplete to be read by screen readers */
  numberOfOptionsCopy: PropTypes.string,
  /** An **optional** onBlur callback handler */
  onBlur: PropTypes.func,
  /** An **optional** onChange callback handler, used to hold the logic for filtering Autocomplete options */
  onChange: PropTypes.func,
  /** An **optional** callback handler, used to handle "an option selected" event */
  onOptionSelected: PropTypes.func,
  /** An **optional** handler for custom rendering of Autocomplete options */
  renderOption: PropTypes.func,
  /** An **optional** Autocomplete validation prop - follows the same structure as InputField
   *
   * **Required Fields** `type`
   */
  validation: PropTypes.shape({
    /** Validation message */
    message: PropTypes.string,
    /** Validation type */
    type: PropTypes.oneOf(Object.values(VALIDATION_TYPES)).isRequired,
  }),
  /** An **optional** prop holding the current input value */
  value: InputField.propTypes.value,
  /** An **optional** Autocomplete variant prop - follows the same structure as InputField
   *
   * **Required Fields** `type`
   */
  variant: PropTypes.shape({
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(Object.values(LABEL_TYPES)).isRequired,
  }),
  /** An **optional** prop flag to control if the Autocomplete is rendered as block or inline-block element */
  isBlock: PropTypes.bool,
  /** An **optional** callback handler that accepts the options and input data, used for custom filtering.
   * If this function is not passed, by default, filtering will happen on "label" key of the item if its an object or the actual value if the item is a primitive data type */
  customFilter: PropTypes.func,
  /** An **optional** prop boolean depicting if Autocomplete should render with a search icon */
  searchIcon: PropTypes.bool,
};

AutoCompleteField.defaultProps = {
  required: false,
  onOptionSelected: Function.prototype,
  renderOption: Function.prototype,
  searchIcon: false,
  isBlock: false,
  onBlur: () => {},
  numberOfOptionsCopy: "results available below",
  ariaDescribe:
    "When autocomplete results are available use up and down arrows to review and enter to select. Touch device users, explore by touch or with swipe gestures.",
};

export default memo(AutoCompleteField);
