// 9fbef606107a605d69c0edbcd8029e5d
import styled, { css } from "styled-components";
import { IconWrapper } from "../IconWrapper";

export const StyledIconWrapper = styled(IconWrapper)`
  height: 21px;
  position: absolute;
  top: var(--dui-size-space-8x);
  width: 21px;
  ${({ position }) =>
    position === "left"
      ? css`
          left: var(--dui-size-space-6x);
        `
      : css`
          right: var(--dui-size-space-2x);
          top: calc(50% - 21px / 2);
        `}
`;

export const ValidationMessageContainer = styled.div`
  ${({ hasValidationMsg }) => css`
    align-items: center;
    display: ${hasValidationMsg ? "flex" : "none"};
    margin-top: var(--dui-size-space-4x);
  `}
`;

export const MessageIconWrapper = styled(IconWrapper)`
  margin-right: var(--dui-size-space-2x);
`;
