// 9fbef606107a605d69c0edbcd8029e5d

import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  text-decoration: none;
  width: 100%;
`;

const getTabList = (variant) => css`
  &::before {
    background: var(--dui-color-gray-400);
    ${variant === "filled" ? "top: 0" : "bottom: 0"};
    content: "";
    height: var(--dui-size-space-1x);
    left: 0;
    position: absolute;
    width: 100%;
  }
`;

export const TabList = styled.ul`
  background: ${({ variant }) =>
    variant === "filled"
      ? `var(--dui-color-gray-100)`
      : `var(--dui-color-white-500)`};
  box-sizing: border-box;
  display: flex;
  height: 100%;
  list-style: none;
  margin: 0;
  outline: 0;
  padding: 0;

  ${({ variant }) => getTabList(variant)}

  position: relative;
`;

const getTabBackground = (variant) => css`
  background-image: linear-gradient(
    ${variant === "filled" ? "to top" : "to bottom"},
    var(--dui-color-white-500) 0%,
    var(--dui-color-white-500) 94%,
    var(--dui-color-red-500) 94%,
    var(--dui-color-red-500) 100%
  );
`;

const getActiveBars = () => css`
  background: var(--dui-color-white-500);
  bottom: 0;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: var(--dui-size-space-2x);
`;

const getActiveTab = () => css`
  position: relative;

  ${({ variant }) => getTabBackground(variant)}

  ::before {
    ${getActiveBars()}
    left: 0;
  }
  ::after {
    ${getActiveBars()}
    right: 0;
  }
`;

export const TabLink = styled.div`
  background: none;
  border: none;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-family: var(--dui-font-family);
  font-size: var(--dui-size-font-md);
  font-weight: var(--dui-font-weight-bold);
  margin: 0;
  outline: none;
  padding: var(--dui-size-space-11x) var(--dui-size-space-12x)
    var(--dui-size-space-11x) var(--dui-size-space-12x);
  text-align: center;
  width: 100%; // eslint-disable-line

  outline-offset: 1px;
  :focus-visible {
    outline: black dotted 1px;
  }

  &[aria-disabled="true"] {
    background-color: var(--dui-color-gray-300);
    color: var(--dui-color-white-500);
    pointer-events: none;
    cursor: none;
  }
`;

export const TextContainer = styled.span`
  display: inline-block;
`;

export const Tab = styled.li`
  display: block;
  width: ${({ isBlock }) => (isBlock ? "100%" : null)};
  ${({ isActive }) => (isActive ? getActiveTab() : null)};
`;

export const TabPanel = styled.div`
  outline: none;
`;
