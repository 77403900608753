// 9fbef606107a605d69c0edbcd8029e5d
import {
  HORIZONTAL,
  VERTICAL,
  PRIMARY,
  OUTLINE,
  TEXT,
  XS,
  SM,
  MD,
} from "_commons/constants";

export const SIZES = {
  XS,
  SM,
  MD,
};

export const ORIENTATION = {
  HORIZONTAL,
  VERTICAL,
};

export const VARIANTS = {
  PRIMARY,
  OUTLINE,
  TEXT,
};

/* eslint-disable no-param-reassign */
export function assignButtonGroupConstants(Component) {
  Component.prototype = Object.assign(Component, {
    ...SIZES,
    ...ORIENTATION,
    ...VARIANTS,
  });
}
